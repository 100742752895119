import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteTwoTone } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import AddTender from "../Tenders/AddTender";
import ShowMoreText from "react-show-more-text";
import TenderCard from "../../__TenderCardAdmin";
import { changeDateFormat } from '../../../utils/common';

const Tenders = () => {
  const intl=useIntl();
  const stateUser = useSelector(state => get(state, `entities.users`));
  const stateSupplier = useSelector(state => get(state, `entities.suppliers`));
  const [stateSupplierObj, setStateSupplierObj] = useState(stateSupplier || null)
  const history = useHistory();
  useEffect(()=>{
    if(stateSupplier != undefined){
      setStateSupplierObj(stateSupplier)
    }
  },[stateSupplier])
  return (
    <>
      <AddTender />
      <div style={{ marginTop: "10px" }}>
        <TenderCard
          status="published"
          titleSlot={
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
               <Col md={6} lg={6} xl={6}><FormattedMessage id={'name'} /></Col>
              {/* <li>Status</Col> */}
              <Col md={11} lg={8} xl={11}><FormattedMessage id={'company'} /></Col>
              <Col md={5} lg={6} xl={5} style={{textWrap:'nowrap'}}><FormattedMessage id={'closing_date'} /></Col>
              <Col md={2} lg={4} xl={2} style={{textWrap:'nowrap'}}><FormattedMessage id={'bids'} /></Col>
              {/* <li>Last Updated</Col> */}
            </Row>
          }
        >
          {(tender) => (
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={6} xl={6}>{tender.name}</Col>
              {/* <div style={{ color: "green", fontWeight: "bold" }}>
                {tender?.stat}
              </div> */}
              <Col md={11} lg={8} xl={11}>{tender?.company_name?.split(',').join(', ')}</Col>
              <Col md={5} lg={6} xl={5}>{changeDateFormat(tender?.closing_date,intl?.locale)}</Col>
              <Col md={2} lg={4} xl={2}>{tender?.bids}{tender?.no_of_suppliers && '/'+tender?.no_of_suppliers}</Col>
              {/* <div>{tender?.bids}</div> */}
            </Row>
          )}
        </TenderCard>
        <TenderCard status="pre" titleSlot={
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={6} xl={6}><FormattedMessage id={'name'} /></Col>
              {/* <Col><FormattedMessage id={'status'} /></Col> */}
              <Col md={8} lg={8} xl={9}><FormattedMessage id={'company'} /></Col> 
              <Col md={6} lg={5} xl={5}><FormattedMessage id={'created_by'} /></Col>
              <Col md={4} lg={5} xl={4}><FormattedMessage id={'date_submitted'} /></Col> 
            </Row>
          }
        >
          {(tender) => (
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={6} xl={6}>{tender.name}</Col>
              {/* <div style={{ color: "red", fontWeight: "bold" }}>
                <FormattedMessage id={tender?.stat} />
              </div> */}
              <Col md={8} lg={8} xl={9}>
                {<ShowMoreText
                        lines={1}
                        more={intl.formatMessage({id:'show_more'})}
                        less={`...${intl.formatMessage({id:'show_less'})}`}
                        className="ca-content-css"
                        expanded={false}
                        width={280}
                      >
                          {tender?.company_name?.split(',').join(', ') || " "} 
                          </ShowMoreText>}
              </Col>
              <Col md={6} lg={5} xl={5}>{stateUser[tender?.created_by]?.name}</Col>
              <Col md={4} lg={5} xl={4}>{tender?.submitted_date && changeDateFormat(tender?.submitted_date,intl?.locale)}</Col>
            </Row>
          )}
        </TenderCard>
        <TenderCard
          status="draft"
          titleSlot={
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={7} xl={6}><FormattedMessage id={'name'} /></Col>
              {/* <li><FormattedMessage id={'status'} /></Col> */}
              <Col md={12} lg={10} xl={12}><FormattedMessage id={'company'} /></Col>
              {/* <li><FormattedMessage id={'created_by'} /></Col> */}
              <Col md={5} lg={6} xl={5}><FormattedMessage id={'last_updated'} /></Col>
              <Col xs={1}></Col>
            </Row>
          }
        >
          {(tender, deleteDraftTender, index) => (
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={7} xl={6}>{tender.name}</Col>
              {/* <div style={{ color: "red", fontWeight: "bold" }}>
                {tender?.stat}
              </div> */}
                <Col md={12} lg={10} xl={12}>{tender?.company_name?.split(',').join(', ')}</Col>
              {/* <div>{stateUser[tender?.created_by]?.name}</div> */}
              <Col md={5} lg={6} xl={5}>{changeDateFormat(tender?.updated_at,intl?.locale)}</Col>
              <Col xs={1}><DeleteTwoTone twoToneColor={'red'} onClick={() => deleteDraftTender(tender.id)}/></Col>
            </Row>
          )}
        </TenderCard>
        <TenderCard
          status="completed"
          titleSlot={
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={6} xl={6}><FormattedMessage id={'name'} /></Col>
              {/* <Col>Status</Col> */}
              <Col md={6} lg={6} xl={6}><FormattedMessage id={'company'} /></Col>
              <Col md={5} lg={5} xl={5}><FormattedMessage id={'completion_date'} /></Col>
              <Col md={5} lg={5} xl={5}><FormattedMessage id={'rating'} /></Col>
              
              {/* <Col>Last Updated</Col> */}
            </Row>
          }
        >
          {(tender) => (
            <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
              <Col md={6} lg={6} xl={6}>{tender.name}</Col>
              {/* <div style={{ color: "green", fontWeight: "bold" }}>
                {tender?.stat}
              </div> */}
              <Col md={6} lg={6} xl={6}>{tender?.company_name?.split(',').join(', ')}</Col>
              {/* <Col md={2} lg={4} xl={2} style={{paddingLeft: 15}}>{tender?.bids}{tender?.no_of_suppliers && '/'+tender?.no_of_suppliers}</Col> */}
              <Col md={5} lg={5} xl={5}>{tender?.completion_date ? changeDateFormat(tender?.completion_date,intl?.locale) : '-'}</Col>
              <Col md={5} onClick={() => history.push(`/addrating/${tender.id}`)}><a><FormattedMessage id={'give_feedback'} /></a></Col>
              {/* <div>{tender?.bids}</div> */}
            </Row>
          )}
        </TenderCard>

      </div>
      <TenderCard
        status="awarded"
        titleSlot={
          <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
            <Col md={6} lg={6} xl={6}><FormattedMessage id={'name'} /></Col>
            <Col md={8} lg={8} xl={9}><FormattedMessage id={'company'} /></Col> 
            <Col md={6} lg={5} xl={5}><FormattedMessage id={'awarded_to'} /></Col>
            <Col md={4} lg={5} xl={4}><FormattedMessage id={'awarded_date'} /></Col>
          </Row>
        }
      >
        {(tender) => (
          <Row className="card-row" justify="space-between" align="middle" gutter={[15, 15]}>
            <Col md={6} lg={6} xl={6}>{tender.name}</Col>
            <Col md={8} lg={8} xl={9}>{tender?.company_name?.split(',').join(', ')}</Col>
            {/* <Col md={6} lg={5} xl={5}>{stateSupplier[tender?.winner_id]?.supplier_name}</Col> */}
            <Col md={6} lg={5} xl={5}>{stateSupplierObj && stateSupplierObj[tender?.winner_id]?.supplier_name}</Col>
            <Col md={4} lg={5} xl={4}>{changeDateFormat(tender?.award_datetime,intl?.locale)}</Col>
          </Row>
        )}
      </TenderCard>
    </>
  );
};

export default Tenders;
