export default {
  name: "Title",
  closing_date: "Closing Date",
  bids: "Bids",
  status: "Status",
  created_by: "Created By",
  awarded_to: "Awarded To",
  award: "Award",
  comparison: "Comparison",
  award_bid:"Award Bid",
  tenders: "Tenders",
  add_new_tender: "Add New Tender",
  view_all_tender: "View All Tenders",
  published_tenders: "Published Tenders",
  published: "Published",
  draft_tenders: "Draft Tenders",
  draft: "Draft",
  pre_tenders: "Pre Tenders",
  awarded_tenders: "Awarded Tenders",
  show_more: "Show More",
  show_less: "Show Less",
  new: "New",
  tenders_submitted_bids: "Tenders - Submitted Bids",
  new_tenders: "New Tenders",
  add_new_pre_tender: "Add New Pre Tender",
  submitted: "Submitted",
  awarded: "Awarded",
  progress: "Progress",
  progress_bids: "Tenders - Draft Bids",
  compare_bids: "Compare Bids",

  //to add
  supplier_registration_form: "Supplier Registration Form",
  organisation_registration_form:"Organisation Registration Form",
  company_name: "Company Name",
  business_id: "Business ID",
  main_contact_person: "Main Contact Person",
  email: "Email",
  mobile_number: "Mobile Number",
  alternate_mobile_number: "Alternate Mobile Number",
  full_address: "Full Address",
  pin_code: "Postal Code",
  cities_operating: "Cities Operating",
  choose_industry_type: "Choose Service Type",
  "error.name": "Please input your Name",
  "error.business_id": "Please input your Business ID",
  "error.email": "Please input your Email",
  "error.invalid_email": "The input is not valid E-mail",
  "error.mobile_number": "Please input your Mobile Number",
  "error.invalid_mobile_number": "The input is not valid Phone number",
  "error.address": "Please input your address",
  "error.pin_code": "Please input your Postal Code",
  "error.invalid_pin_code": "The input is not valid Postal Code",
  "error.city": "Please Select the City",
  "error.service_type": "Please Select your Service Type",
  "error.supplier_name": "Please Enter Supplier Name",
  "error.msg": "Please enter your question!",
  "error.organization": "Please enter organization",
  "error.company_name": "Please enter company name",
  city: "City",
  submit: "Submit",
  submit_request: "Thank You For Submitting Your Request",
  response_back: "We will evaluate your request and get back to you shortly.",
  ok: "Ok",
  invalid_code: "The supplied code is Invalid",
  organization: "Organization",
  company: "Company",
  last_updated: "Last Updated",
  awarded_date: "Awarded Date",
  completion_date: "Completion Date",
  date_submitted: "Date Submitted",
  published_date: "Published Date",
  delete_tender_msg: "Are you sure you want to delete this tender?",
  supplier_details: "Supplier Details",
  label_name: "Name",
  subadmin:"Sub-Admin",
  address: "Address",
  country: "Country",
  industry_type: "Service Type",
  supplier_rating: "Supplier Rating",
  mark_favourite: "Mark as favourite",
  favourite: "favourite",
  deactivate: "Deactivate",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  send_question: "Send Question To Admin",
  send_question2: "Send Question",
  ask_question: "Ask Questions",
  send: "Send",
  message: "Message",
  edit_company_details: "Edit Company Details",
  add_company_details: "Enter Company Details",
  company_details: "Company Details",
  select_city: "Select City",
  select_operating_city: "Select Operating Cities",
  select_organization: "Select Organization",
  default_emails: "Default Emails",
  default_email: "Default Email",
  profile: "Profile",
  profil_update: "Profile details updated successfull",
  profile_details: "profile details",
  password: "Password",
  current_pwd: "current password",
  new_pwd: "new password",
  confirm_new_pwd: "confirm new password",
  "error.current_pwd": "Please enter you current password",
  "error.new_pwd": "Please enter your new password",
  "error.confirm_new_pwd": "Please confirm your password!",
  match_pwd: "The two passwords that you entered do not match!",
  change_pwd: "change password",
  pwd_change: "password changed successfully",
  reset_email: "Email to reset password sent",
  forgot_pwd: "Forgot Password",
  repair: "repair",
  others: "others",
  page:"page",
  website: "website",
  document_deleted_successfully	:"Document deleted successfully",
  are_you_sure_you_want_to_delete_this_tender:"Are you sure you want to delete this tender?",
  unmark_as_favourite:"Unmark as favourite",
  mark_as_favourite:"Mark as favourite",
  reset_pwd: "Reset Password",
  reset_email_failure: "User does not exist",
  "tender.choose_tender_type.info":
  "In this step you can choose what you want to tender. By selecting the “Repair” option, you can tender for various repairs, such as the repair of spare parts. By selecting the “Other” option, you can tender for e.g. cleaning, contracts, inspections and the like.",
  "tender.choose_company.info":
    "Select here to whom you are creating the tender. Use the search functions, if needed. With this you can also create a tender for your own purchases.",
  "tender.tender_details.info":
    "Add the title of the tender here. You can also provide additional information on the tender, if you wish.",
  "tender.choose_suppliers.info":
    "Select the suppliers to whom you want to send this invitation to tender. If the supplier you want is not listed, you can send an invitation to your suppliers by adding an email address one at a time in the text box at the bottom of the page. After the supplier logs in, you will find them in the supplier list in the future.",
  "tender.asset_details.info":
    "Create a tender by assets and items. For example, the asset may be an apartment 123 and the item may be a task or the object of renovation, e.g. the replacement of a toilet bowl. This entire step can be skipped, if the request to tender added in the next step. Contains all the necessary information.",
  "tender.file.info": "Add possible attachments, such as offer request.",
  "tender.closing_date.info":
    "Choose until when do you want to receive offers, as well as how many reminders do you want to send about the closing tender to those suppliers who have not yet responded to the tender with their own offer.",
  "tender.additional_info.info":
    "Add any additional information related to the tender here. For example, you can mention where the keys can be picked up or what the door code is.",
  "tender.summary.info":
    "From the summary, check that all the information and attachments are correct. You can navigate to any step and edit the information, if needed.",

  from: "From",
  date: "Date",
  time: "Time",
  tender: "Tender",
  last_msg: "Last Messsaged By",
  of: "of",
  items: "items",
  msg_error: "Unable to send messages",
  msg_send: "Answer send successfully",
  tender_type: "Choose Tender Type",
  choose_company: "Choose Company",
  tender_details: "Tender Details",
  choose_suppliers: "Choose Suppliers",
  asset_details: "Asset Details",
  attach_file: "Attach File",
  add_info: "Additional Info",
  summary: "Summary",
  preTender_type: "Choose Pre Tender Type",
  preTender_details: "Enter Pre Tender Details",
  select_asset: "Select Assets",
  next: "Next",
  bakc: "Back",
  back: "Back",
  publish: "Publish",
  filters: "Filters",
  company_type: "Company Type",
  choose_org: "Choose an Organization",
  recommended_supplier:"Recommended Supplier",
  please_select_a_supplier: "Please select a supplier",
  recommended_supplier_to_property_manager: "Recommended supplier to property manager",
  property_manager:"Property Manager",
  add:"Add",
  select: "Select",
  select_all: "Select All",
  unselect_all: "Unselect All",
  enter_tender_details: "Enter Tender Details",
  "error.tender_title": "Please enter a title for the tender.",
  title: "Title",
  desc: "Description",
  size: "Size",
  service_type: "Service Type",
  by_country: "By Country",
  by_city: "By City",
  by_rating: "By Rating",
  supplier_for_project: "Choose suppliers for this project",
  rating: "Rating",
  invite_supplier: "Invite more Supplier",
  all: "All",
  start_bidding: "Start bidding",
  cancel_bidding: "Cancel bidding",
  do_not_bid: "Do not bid",
  bid_summary:"Bid summary",
  no_files_attached:"No files attached",
  documents_attached:"Documents attached",
  submit_bid:"Submit Bid",
  want_to_submit_this_bid:"Do you really want to submit this bid?",
  select_fav: "Select Favourites",
  unselect_fav: "Unselect Favourites",
  add_item: "Add Item",
  enter_asset: "Enter Asset",
  enter_item: "Enter Item",
  add_asset: "Add Asset",
  upload_msg:
    "Please click on upload button after selecting the files to start upload",
  upload_files: "Upload Files",
  input_file: "Input Files",
  file_desc: "File Description",
  drop_files: "Drop Files Here",
  no_files_uploaded: "No Files Uploaded",
  files_uploaded: "Files Uploaded",
  closing_date_req:
    "Both Closing Date & Reminder Interval are mandatory.Please fill both.",
  server_error: "Unable to reach server",
  check_closing_date: 'Please check closing date.',
  send_recommendation: "Send recommendation.",
  choose_closing_date: "Choose Closing Date",
  send_reminders_before: "Send Reminders Before",
  days: "Days",
  additional_info: "Additional Information/Comment",
  publish_tender_conf: "Do you really want to publish this tender?",
  yes: "Yes",
  no: "No",
  bid_received:"Bid received",
  tenders_published: "Tenders Published",
  today: "Today",
  this_week: "This Week",
  this_month: "This Month",
  tenders_marked_as_complete: "Tenders Marked as Complete",
  tenders_due_for_completion: "Tenders Due For Completion",
  tenders_failed_completion: "Tenders Failed Completion",
  new_suppliers_registered: "New Suppliers Registered",
  active_tenders: "Active Tenders",
  email_info: "Send email with all Tender info",
  publishing_organization: "Publishing Organizations This Month",
  tender_name: "Tender Name",
  tender_id: "Tender ID",
  housing_company_name: "Housing Company Name",
  bids_counts_received: "Bids Received Counts",
  preview: "Preview",
  tenderType: "Tender Type",
  chose_suppliers: "Choosen Suppliers",
  choose_receiver: "Choose Receiver",
  add_email: "Add Email",
  comments: "Comments",
  include_attach: "Include File Attachments",
  evaluate_bids: "Evaluate Bids",
  modify_tender: "Modify Tender",
  delete_tender: "Delete Tender",
  organisation: "Organization",
  view: "View",
  modify: "Modify",
  delete: "Delete",
  delete_pre_tender : "Delete Pre Tender",
  modify_pre_tender:	"Modify Pre Tender",
  pre_tender_details:	"Pre Tender Details",
  are_you_sure_you_want_to_delete_this_pre_tender:"Are you sure you want to delete this pre tender?",
  are_you_sure_you_want_to_modify_this_pre_tender:"Are you sure you want to modify this pre tender?",
  please_confirm_to_convert_this_pre_tender_to_tender:'Please confirm to convert this Pre-Tender to Tender.',
  please_confirm_to_reject_the_tender:"Please confirm to reject the tender.",
  please_confirm_to_award_the_tender:	"Please confirm to award the tender.",
  addnew: "Add New",
  companies: "Companies",
  asset: "Asset",
  assets: "Assets",
  inspection_report_vault:"Inspection Report Vault",
  recommended_supplier_to_stakeholders:"Recommended Supplier To Stakeholders",
  recommended_supplier_to_admin:"Recommended Supplier To Admin",
  supplier_name: "Supplier Name",
  country_name: "CountryName",
  Date_recieved: "Date Recieved",
  accept: "Accept",
  reject: "Reject",
  invitation_send: "Invitation send successfully",
  sendinvitation: "Send Invitation",
  invite_suppliers: "Invite new suppliers to join",
  invited_suppliers: "Invited suppliers",
  Enter_emailid: "Enter Email Id",
  Unable_invitation: "Unable to send invitation to supplier",
  supplier: "Supplier",
  sms_settings: "SMS Settings",
  Provided_url: "Provided URl",
  User_id: "USER ID",
  email_settings: "Email Setting",
  server: "Server",
  user: "User",
  users:"Users",
  SSL: "SSL",
  payment_gateway:'Payment Gateway',
  provided_url:'Provided URL',
  Client_id:'Client ID',
  sekret_key:"Secret_Key",
  consultancy_price:"Consultancy Price",
  plan_name:'Plan Name',
  price:'Price',
  select_company_name:'Select Company Name',
  inspection_report_name:'Inspection Report Name',
  Enter_reportname:'Enter Report Name',
  Company_Adress:'Company Address',
  Enter_CompanyAdress:'Enter Company Address',
  Company_Business_Id:'Company Business ID',
  Select_Business_ID:'Select Business ID',
  Organisation_Name:'Organization Name', 
  organization_name:'Organization Name', 
  Organisation_Select:'Select Organization',
  registration_date: 'Registration Date',
  delete_supplier: 'Are you sure you want to delete this supplier?',
  reject_supplier: 'Are you sure you want to reject this supplier?',
  approve_supplier: 'Are you sure you want to approve this supplier?',
  add_supplier: 'Are you sure you want to add this supplier?',
  edit_supplier: 'Are you sure you want to edit this supplier?',
  supplier_already_registered:"Invalid request supplier already registered!",
  unable_to_send_invitation_to_supplier: "Unable to send invitation to supplier",
  delete_company_type: 'Are you sure you want to delete this company type?',
  delete_service_type: 'Are you sure you want to delete this service type?',
  // add_asset: 'Are you sure you want to add this asset?',
  edit_asset: 'Are you sure you want to edit this asset?',
  delete_asset: 'Are you sure you want to delete this asset?',
  you_have_choosen_to_award_the_bid_to : 'You have choosen to award the bid to ',
  do_you_want_to_proceed:'. Do you want to proceed ?',
  are_you_sure: 'Are you sure you want to ',
  bid_placed_successfully:'Bid Placed Successfully',
  go_to_dashboard:'Go to Dashboard',
  dashboard:'Summary of tenders',
  the_organization: ' the organization?',
  Asset_Name:'Asset Name',
  Enter_Asset:'Enter Asset',
  Search_inspiration_inspect:' Search Inspection Report',
  Awarded_tender_progress:'Awarded Tender In Progress',
  nameu:'Name',
  tender123:'Tender#123',
  supplier1:'Supplier 1',
 current_tender:'Current Tender',
 Created_by:'Created By',
 Bids_Receieved:'Bids Receieved',
 Tender_Details:'Tender Details',
 item:'Item',
 select_company_type:'Select Company Type',
 tender_title:'Tender Title',
 search:'Search',
 reset:'Reset',
 filter:'Filter',
 bids_submitted:'Bids Submitted',
 created_at:'Created At',
 Updated_date:'Updated Date',
 Action:'Action',
 asset_category:'Asset Category',
 Reason_for_modifying:'Reason for modifying tender?',
 continue:"Continue",
 Add_New_Inspection:'Add New Inspection',
 postal_code:'Postal Code',
 Default_Emails:'Default Emails',
 Default_Emails_Add:'Add Default Emails',
 add:'Add',
full_name:"Full Name",
long_description_en:"Long Description_en",
long_description_fi:"Long Description_fi",
short_description_en:"Short Description_en",
short_description_fi:"Short Description_fi",
social_youtube_link:"Youtube Link",
social_ig_link:"Instagram Link",
social_fb_link:"Facebook LInk",
org_details: 'Organisation Details',
 rating_error: 'Rating not submitted',
 rating_submitted: 'Rating submitted successfully',
 completed_tenders: 'Completed Tenders',
 completed: 'Completed',
 cnfrmPassword: "Confirm Password",
 User_success: "User added successfully",
 contact:"Contact",
 userdetails:"User Details",
 propertymanagerdetails:"Property Managers",
 adminusers: "Admin Users",
 Document:"Document",
 AddDocument:"Add Document",
 companydetails:"Company Details",
 Contactsupport:"support",
 manage:"Manage",
 welcome:"Welcome",
 logout:"Logout",
 UploadLogo:"Upload Logo",
 clickupload:"Click to upload",
 Upload:"Upload",
 header:"Header",
 footer:"Footer",
 "Additional Information/Comment":"Additional Information/Comment",
 'Please enter inspection report name.':'Please enter inspection report name.',
 "Inspection Report Name":"Inspection Report Name",
 property:"Property",
 "Property To Be Inspected":"Property To Be Inspected",
 customer:"Customer",
 Buyer:"Buyer",
 Buyer_name:"Buyer Name",
 Buyer_address:"Buyer Address",
 Evaluator:"Evaluator",
 Creator:"Creator",
 "FUTURE CORRECTIONS":"FUTURE CORRECTIONS",
 "Fitness rating":"Fitness rating",
 "FAULT FREQUENCY ACCORDING TO SERVICE JOURNAL":"FAILURE FREQUENCY ACCORDING TO THE SERVICE MANUAL",
 "Commendable, 0-1 bug last year":"Commendable, 0-1 failure in the last year",
 "Good, 2-3 failures in the last year":"Good, 2-3 failures in the last year",
 "Satisfactory, 4-5 failures in the last year":"Satisfactory, 4-5 failures in the last year",
 "SPARE PARTS AVAILABILITY":"AVAILABILITY OF SPARE PARTS",
 "Spare parts are well available.":"Spare parts are readily available.",
 "The availability of spare parts may decline in 5 years.":"The availability of spare parts may decrease after 5 years.",
 "All parts are no longer available as new, some possible repairs are based on demolition parts.":"Not all parts are available new, some of the possible repairs rely on disassembled parts.",
 "There are hardly any new spare parts available.":"There are hardly any new spare parts available.",
 "Select an Asset to create Inspection Report":"Select an Asset to create Inspection Report",
 "Reported By": "Reported By",
 "Report Date":"Report Date",
 "Enter Name":"Enter Name",
 close:"Close",
 "Awarded Tenders-In Progress":"Awarded Tenders-In Progress",
 "Add New Tender":"Add New Tender",
Showmore:"Show More",
submittedby:"Submitted By",
"Lift repair":"Lift repair",
"Please set a title for the tender.":"Please set a title for the tender.",
"Only visible to me":"Only visible to me",
"Enter Tender Details":"Enter Tender Details",
"File Description":"File Description",
"Chosen Suppliers":"Chosen Suppliers",
"Do you really want to publish this tender?":"Do you really want to publish this tender?",
submitanonymously:"Submit Anonymously",
"Add Rating":"Add Rating",
"Add Comment":"Add Comment",
"Pre Tender Details":"Pre Tender Details",
search_tender_name: "Search Tender Name",
no_data: "No Data",
you_have_added_files_but_not_uploaded_proceed_without_uploading:"You have added files but not uploaded. Proceed without uploading?",
invalid_email:"Invalid Email",
asset_item_missing:"Asset Item Missing",
please_enter_the_email_ids:"Please enter the Email id's",
please_add_assets_or_upload_files_to_publish_a_tender:"Please add assets or upload files to publish a tender",
"Received from ":"Received from ",
"Convert to Tender":"Convert to Tender",
"Send email with all Pre Tender info":"Send email with all Pre Tender info",
no_files_attached_either_add_a_file:"No files attached.Either add a file and retry again, or press next to continue.",
"Reason for rejecting tender?":"Reason for rejecting tender?",
files:"Files",
cost:"Cost",
"Unable to publish tender.":"Unable to publish tender.",
"Tender has been published successfully.":"Tender has been published successfully.",
"Goto Tender.":"Go to Tender",
Pretenders:"Pre Tenders",
total:"Total",
 "Please enter tender type.":"Please enter tender type.",
"Please select atleast one company.": "Please select atleast one company.",
"Enter Email":"Enter Email",
AddUser:"Add User",
username:"User Name",
SaveDate:"Save Date",
ChangeDate:"Change Date",
"Save New Date":"Save New Date",
"Mark As Complete":"Mark As Complete",
"Date and progress":"Date and progress",
"proofs":"proofs",
"Enter completion date":"Enter completion date",
Name:"Name",
adress:"Address",
select_country:"Select Country",
home:"Home",
settings:"Settings",
sub_total:"Sub Total",
inspection_report:"Inspection Report",
inspection_date:"Inspection Date",
"Company type added successfully":"Company type added successfully",
"Asset Added Successfully":"Asset Added Successfully",
"Company Description":"Company Description",
'Are you sure you want to delete this tender?':'Are you sure you want to delete this tender?',
'CompanyDescription':'CompanyDescription',
org_users:"Organization Users",
login:"Login",
success: "Submitted successfully",
completed_date: 'Completion Date',
"Please Enter Name":"Please Enter Name",
"Please Enter Price":"Please Enter Price",
"Please Enter Description":"Please Enter Description",
enter: "Enter",
"error.Total":"Total has to be more than 0",
selected_company:"Selected Company",
add_company_assets:"Please add a company to add assets",
selected_housing_company:"Selected Housing Company",
"error.password":"Please input password",
org_dup_err: "Organisation with email address exists",
full_bid: "Full Bid",
partial_bid: "Partial Bid",
total_bid: "Total Bid",
give_feedback: "Give feedback",
Messages: "Messages",
sure_to_modify_tender: "Are you sure you want to modify this tender?",
email_sent_success: "Email sent successfully",
select_tender_type: "Please select a tender type.",
go_to_home_page: "Go to Home page",
new_request: "New requests",
"Are you sure you want to rejected the organization?": "Are you sure you want to rejected the organization?",
"Are you sure you want to approved the organization?":"Are you sure you want to approved the organization?",
sure_to_delete_organization: "Are you sure you want to delete this organization?",
company_updated_success_msg: "Company updated successfully",
company_added_success_msg: "Company added successfully",
sure_to_delete_company: "Are you sure you want to delete this company?",
continue_bidding: "Continue Bidding",
place_bids: "Place Bids",
supplier_comments: "Supplier Comments",
supplier_updated: "Supplier updated successfully",
not_authorize_manger: "You are logged in as a property manager and not authorized to view this page.",
question_sent: "Question send successfully",
files_uploaded_success: "File uploaded successfully",
supplier_deactivate_msg: "Supplier deactivate successfully.",
supplier_add_msg: "supplier added successfully",
year_of_manufacture: "Year of manufacture",
control_system: "Control System",
elevator_speed: "Elevator Speed",
elevator_type: "Elevator Type",
// Inspection Report
Elevator_and_block_information: "Elevator and block information",
 Block_and_elevator_number: "Block and elevator number",
 Elevator_cars_doors: "Elevator car's doors",
 Engine_rooms_location: "Engine room's location",
 Manufacture_renovation_year: "Manufacture/renovation year",
 Door_dimensions_width_height:"Door dimensions (width x height)",
 Shaft_dimensions_width_height:"Shaft dimensions (width x height)",
 Elevator_type:"Elevator type",
 Nominal_load_kg_person:"Nominal load (kg / person)",
 Elevator_car_dimensions:"Elevator car\'s dimensions (width x depth x height)",
 Manufacturer_model:"Manufacturer / model",
 Elevator_speed: "Elevator speed",
 'Control_system/Operating_system': "Control system/Operating system",
 Responsible_for_elevator_maintenance:"Responsible for elevator maintenance",
 Number_of_floors_and_floor_markings:"Number of floors and floor markings (the bolded one is ground floor)",
//  Operating_system:"Operating system",
 Date_of_periodic_elevator_inspection_next_inspection:"Date of periodic elevator inspection / next inspection",
 "To be repaired immediately":"To be repaired immediately",
 "To be repaired within 1-2 years":"To be repaired within 1-2 years",						
 "To be repaired within 2-3 years":"To be repaired within 2-3 years",							
 "To be repaired within 3-4 years":"To be repaired within 3-4 years",
  "To be repaired within 4-5 years":"To be repaired within 4-5 years",			
  "All OK, No cost within 5 years":"All OK, No cost within 5 years",
  "FUTURE COSTS":"FUTURE COSTS",	
  "Condition rating":"Condition rating",
  "Hissitaito Oy on puolueeton hissikonsultointiin erikoistunut yritys, joka tuottaa asiakkailleen valvontapalveluja ja erilaisia yksilöllisiä palveluja asiakkaiden tarpeiden mukaan. Tämä raportti liitteineen on Hissitaito Oy:n näkemys taloyhtiön hissien kunnosta ja arvio seuraavien vuosien kunnostustarpeista. Raporttia ei saa käyttää tarjouspyyntönä. Raportti on tarkoitettu ohjeelliseksi ja avuksi laadittaessa vuosittaisia kunnossapitobudjetteja. Hissien kuntokartoitus olisi hyvä päivittää n. viiden vuoden välein, jotta se pysyy ajan tasalla.":"Hissitaito Oy is an impartial company specializing in elevator consulting, which provides its customers with monitoring services and various individual services according to the customers' needs. This report with its attachments is Hissitaito Oy's view of the condition of the building company's elevators and an estimate of the renovation needs in the following years. The report may not be used as a request for quotation. The report is intended to be indicative and helpful when preparing annual maintenance budgets. It would be good to update the condition survey of the lifts every five years to keep it up-to-date.",
  'datepickerLocale':{
    "lang": {
      "locale": "en_US",
      "placeholder": "Select date",
      "rangePlaceholder": ["Start date", "End date"],
      "today": "Today",
      "now": "Now",
      "backToToday": "Back to today",
      "ok": "OK",
      "clear": "Clear",
      "month": "Month",
      "year": "Year",
      "timeSelect": "Select time",
      "dateSelect": "Select date",
      "monthSelect": "Choose a month",
      "yearSelect": "Choose a year",
      "decadeSelect": "Choose a decade",
      "yearFormat": "YYYY",
      "dateFormat": "D/M/YYYY",
      "dayFormat": "D",
      "dateTimeFormat": "D/M/YYYY HH:mm:ss",
      // "monthFormat": "MMMM",
      "monthBeforeYear": true,
      "previousMonth": "Previous month (PageUp)",
      "nextMonth": "Next month (PageDown)",
      "previousYear": "Last year (Control + left)",
      "nextYear": "Next year (Control + right)",
      "previousDecade": "Last decade",
      "nextDecade": "Next decade",
      "previousCentury": "Last century",
      "nextCentury": "Next century",
      "shortWeekDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      "shortMonths": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    },
    "timePickerLocale": {
      "placeholder": "Select time"
    },
    "dateFormat": "DD-MM-YYYY",
    "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
    "weekFormat": "wo-YYYY",
    "monthFormat": "MM-YYYY"
  },
  sorry_no_matching_records_found: "Sorry, no matching records found",
  sort: "Sort",
  sort_for: 'Sort for',
  next_page: "Next Page",
  previous_page: "Previous Page",
  rows_per_page: "Rows per page:",
  of2: "of",
  search: "Search",
  download_csv: "Download CSV",
  print: "Print",
  view_columns: "View Columns",
  filter_table: "Filter Table",
  all: "All",
  filters: "FILTERS",
  reset: "RESET",
  show_columns: "Show Columns",
  show_hide_table_columns: "Show/Hide Table Columns",
  rows_selected: "row(s) selected",
  delete: "Delete",
  delete_selected_rows: "Delete Selected Rows",
};
