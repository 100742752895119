import React, { useState } from "react";
import "./AddModal.css";
import { FormattedMessage, useIntl } from "react-intl";

const AddModal = (props) => {
  const [serviceType, setServiceType] = React.useState(props.data);
  const [errorMessage, setErrorMessage] = React.useState("");
  function handleInputChange(event) {
    const data = {
      ...serviceType,
      [event.target.name]: event.target.value,
    };
    setServiceType(data);
    setErrorMessage("");
  }
  function handleSubmit() {
    if (serviceType && serviceType.service_type_name !== "") {
      props.onSubmit(serviceType);
    } else {
      setErrorMessage(<FormattedMessage id="Please Enter Name"/>);
    }
  }

  return (
    <div className="pageOverlay">
      <div className="modalOuter">
        <div className="closeBtn" onClick={() => props.onClose()}>
          close
        </div>
        <div className="inputFieldGroup">
        <label><FormattedMessage id= {'Enter Name'}/>
                </label>

          <input
            className="inputFiled"
            type="text"
            name="service_type_name"
            placeholder=""
            value={serviceType ? serviceType.service_type_name : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage}</div>
        </div>
        <div className="buttonBlock">
          <div className="btn cancelBtn" onClick={() => props.onClose()}>
          <label><FormattedMessage id="cancel"/>
                </label>
          </div>
          <div className="btn" onClick={() => handleSubmit()}>
          <label><FormattedMessage id="save"/>
                </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
