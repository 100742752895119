import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import { FormattedMessage, useIntl } from "react-intl";
const EmailSetting = (props) => {
  const intl = useIntl();
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="EmailSetting" />
        <div className="tabContainer">
          <h2> <FormattedMessage id={'email_settings'} /></h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="formSettings">
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'server'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'user'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'password'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'SSL'} /></label>
                  <input className="form-control" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EmailSetting.propTypes = {};

export default EmailSetting;
