import "./bid.less";
import React, { useEffect, useRef, useState } from "react";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import Header from "../../components/Header";
import BidSummary from "../bid/Summary";
import CompareBids from "./Compare";
import { Modal, Button, Card, message, Table, Spin, Input } from "antd";
import { EyeFilled, VerifiedOutlined } from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router";
import { domainUrl } from "../../helper";
import { genHeaders } from "../../components/Tender/Tender";
import ConfirmationBox from '../../components/ConfirmationBox';
import { sendEmailCommunication } from "../../actions/communication";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { changeDateFormat } from '../../utils/common';

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  // var strTime = hours + ":" + minutes + " " + ampm;
  var strTime = hours + ":" + minutes;
  return strTime;
}

export default function Bid(props) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [viewId, setViewId] = useState();
  const [awardedSupplier, setAwardedSupplier] = useState();
  const [awardedSupplierName, setAwardedSupplierName] = useState();
  const [compareBids, setCompareBids] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [bidComments, setBidComments] = useState('');
  const [selectedBidKeys, setBidSelectedKeys] = useState([]);

  const userId = sessionStorage.getItem('user_id');
  const user = useSelector(state => get(state, `entities.users.${userId}`));
console.log(user,"====useruseruser")
  const history = useHistory();
  const { tenderId } = props.match.params;

  useEffect(() => {
    const headers = {
      headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
      },
    };

    axios
      .get(`/bid/v1/tender/${tenderId}/bid?is_submitted=1`, headers)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setData(response.data.data.result);
          setAwardedSupplier(response?.data?.data?.result[0]?.winner_id);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        message.error("Could not receive bid information");
        history.goBack();
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const placeAward = async() => {
    axios
      .put(
        `${domainUrl}/tender/v1/tender/${tenderId}`,
        {
          winner_id: awardedSupplier,
          award_comment: (!bidComments || bidComments === "") ? 'N/A' : bidComments
        },
        genHeaders()
      )
      .then(async() => {
        await dispatch(sendEmailCommunication('AWARD_TENDER', {tender_id: parseInt(tenderId), company_name: user.roles.role_id === 1 ? 'Hissitaito OY' : ''}));
        await dispatch(sendEmailCommunication('NOT_AWARD_TENDER', {tender_id: parseInt(tenderId), company_name: user.roles.role_id === 1 ? 'Hissitaito OY' : ''}));
        history.push("/");
      })
      .catch((e) => {
        message.error("Unable to place award for supplier.");
      });
  };

  const rowSelection = {
    selectedRowKeys: selectedBidKeys,
    hideSelectAll:true,
    onSelectAll : function(selected, selectedRows, changeRows){
      setBidSelectedKeys([])
    },
    onSelect : function(record, selected, selectedRows, nativeEvent,selectedRowKeys){
      if(!selected){
        setBidSelectedKeys([])
      }else{
        setBidSelectedKeys([record?.bid_id || ''])
      }
      
    },
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedSupplier = selectedRows.map(bid => { return bid});
      console.log(selectedSupplier,"=====selectedSupplier")
      setAwardedSupplier(selectedSupplier[0]?.supplier_id);
      setAwardedSupplierName(selectedSupplier[0]?.supplier_name);
    }
  };
  var MOSCOW_OFF = 4; // hours
  var MONTHS = ["January", "February", "March", "April", "May", "June", "July", 
                "August", "September", "October", "November", "December"];

  // desired format => November 1, 2011 21:31:00
  function getLocalTime(offset, time) {
      var d = new Date(time);
      d.setTime((new Date(time).getTime()) + 
                (d.getTimezoneOffset() * 60 * 1000) + // local offset
                (1000 * 60 * 60 * offset)); // target offset
      return MONTHS[d.getMonth()] + " " + d.getDate() + ", " + 
              d.getFullYear() + " " + d.toTimeString().split(" ")[0];
  }
  
  const columns = [
    {
      title: <FormattedMessage id={'supplier_name'}/>,
      dataIndex: "supplier_name",
      key: "supplier_id",
      render: (name) => <div>{name}</div>,
    },
    {
      title: <><FormattedMessage id={'supplier'}/> <FormattedMessage id={'company'}/></>,
      dataIndex: "company_name",
      key: "supplier_id",
      render: (name) => <div>{name}</div>,
    },
    {
      title: <FormattedMessage id={'documents_attached'}/>,
      dataIndex: "documents",
      key: "supplier_id",
      render: (documents) => <div>{documents && documents?.length > 0 ? <FormattedMessage id={'yes'}/> : <FormattedMessage id={'no'}/>}</div>,
    },
    {
      title: <FormattedMessage id={'date'}/>,
      dataIndex: "created_at",
      key: "bid_id",
      render: (datetime) => {
        return <div>{changeDateFormat(datetime?.split("T")[0],intl?.locale)}</div>;
      },
    },
    {
      title: <FormattedMessage id={'time'}/>,
      dataIndex: "created_at",
      key: "bid_id",
      render: (time) => <div>{formatAMPM(new Date(time))}</div>,
      // render: (time) => <div>{getLocalTime(MOSCOW_OFF,new Date(time).getTime())}</div>,
      // render: (time) => <div>{new Date(time).toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' })}</div>,
    },
    {
      title: <FormattedMessage id={'total'}/>,
      key: "bid_id",
      dataIndex: "sub_total",
      render: (price) => <div>{price}&nbsp;&euro;</div>,
      sorter: {
        compare: (a, b) => a.sub_total - b.sub_total,
      },
    },
    {
      title: <FormattedMessage id={'view'}/>,
      render: (_, record) => {
        return (
          <Button
            type="link"
            style={{ marginTop: 0 }}
            shape="circle"
            icon={
              <EyeFilled
                onClick={() => setViewId(record?.bid_id)}
                style={{ fontSize: "30px" }}
              />
            }
          />
        );
      },
    },
  ];

  const awardClasses = ["btn"];
  if (isNaN(awardedSupplier)) {
    awardClasses.push("disabled");
  }

  const declineSubmit = () =>{
    setShowConfirmationBox(false);
  }
  
  const handleRecommendedSupplier = () => {
    axios
    .put(
      `${domainUrl}/tender/v1/tender/${tenderId}`,
      {
        //comments,
        recommended_supplier: awardedSupplier,
        comments: bidComments,
      },
      genHeaders()
    )
    .then(async() => {
      await dispatch(sendEmailCommunication(user.roles.role_id === 1 ? 'ADMIN_SUPPLIER_RECOMMENDATION' : 'PM_SUPPLIER_RECOMMENDATION', {tender_id: +tenderId,recommended_by: user?.name, comments: (!bidComments || bidComments === "") ? 'N/A' : bidComments}));
      message.success(intl.formatMessage({ id:"Sent recommendation."}));
      setBidComments('');
      setBidSelectedKeys([])
    })
    .catch(() => {
      message.error("Was not able to send recommendation.");
    });
  }

  return (
    <>
      <Header />
      <div className="bid-receive-root container">
        {loading ? (
          <div style={{ height: 500 }} className="d-flex j-center">
            <Spin style={{ margin: "auto" }}></Spin>
          </div>
        ) : (
          <Card style={{ width: "100%" }}>
            <div className="t-center">
              <h1 style={{ textTransform: "capitalize" }}>
              <FormattedMessage id={'title'}/> : {data[0]?.title}
              </h1>
            </div>
            <hr />
            <div>
              <h2><FormattedMessage id={'bid_received'}/></h2>
            </div>
            <Table rowSelection={{
              type: 'checkbox',
              ...rowSelection,
             }} 
             columns={columns} 
             dataSource={data} 
             pagination={false} 
             rowKey='bid_id' 
             locale={{emptyText: intl.formatMessage({ id:'no_data'})}} 
            />
            <Card style={{ width: '100%' }} size="small">
              <Input.TextArea rows={2} allowClear value={bidComments} onChange={(e) => setBidComments(e.target.value)} />
            </Card>
            <div className="d-flex j-space">
              <button
                onClick={() => setShowConfirmationBox(true)}
                className={awardClasses.join(" ")}
                style={(!selectedBidKeys?.length ? { background: "#fabf0157",border:0 } : { background: "#fabf01",border:0 })}
                disabled={!selectedBidKeys?.length}
              >
                <VerifiedOutlined />
                &nbsp; <FormattedMessage id={'award_bid'}/>
              </button>
              {/* <form ref={formRef}>

                <div className="formGroup">
                  <label className="leftCol"><FormattedMessage id={'comments'}/></label>
                <textarea className="comments" id="comments"></textarea>
                </div>
              </form>   */}
              {/* <div onClick={() => setCompareBids(true)} className="btn">
                <FormattedMessage id={'compare_bids'}/>
                {+user.roles.role_id === 1 ? <FormattedMessage id={'recommended_supplier_to_property_manager'}/> : <FormattedMessage id={'recommended_supplier_to_admin'}/>}
              </div> */}
              <Button className="btn" style={{borderRadius:'6px',fontSize: '16px',fontWeight: 500,}} disabled={!selectedBidKeys?.length} onClick={handleRecommendedSupplier}>
                {user.roles.role_id === 1 ? <FormattedMessage id={'recommended_supplier_to_property_manager'}/> : <FormattedMessage id={'recommended_supplier_to_admin'}/>}
              </Button>
            </div>
          </Card>
        )}
      </div>

      <Modal
        destroyOnClose
        centered
        footer={null}
        onCancel={() => setViewId(null)}
        visible={viewId}
      >
        <BidSummary showEdit={false} bidId={viewId} />
      </Modal>

      <Modal
        destroyOnClose
        className="compareModal"
        centered
        footer={null}
        onCancel={() => setCompareBids(false)}
        visible={compareBids}
        width={'100%'}
      >
        <CompareBids />
      </Modal>

      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={intl.formatMessage({id: 'you_have_choosen_to_award_the_bid_to'})+ awardedSupplierName + intl.formatMessage({id: 'do_you_want_to_proceed'}) } confirmSubmit={placeAward} declineSubmit={declineSubmit} />
    </>
  );
}
