import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import Tenders from "./Tenders/Tenders";
import "./Dashboard.css";
import MessagesCard from "../MessagesCard";

const Dashboard = (props) => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <Row /*gutter={[20, 3]} style={{width: '100%'}}*/ style={{marginTop: '10px'}} justify="center">
          <Col md={24} xl={14} className="leftSidePanel">
            <Tenders />
          </Col>
          <Col  md={24} xl={10} className="rightSidePanel">
            <MessagesCard />
          </Col>
        </Row>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
