import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Table, Rate, Input, Space, Button, Spin } from 'antd';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationBox from '../ConfirmationBox';

import {
    fetchSuppliers,
    updateSupplier,
    deleteSupplier,
    deactiveSupplier
} from "../../actions/suppliers";
import { compNames, getFilterValues } from '../../utils';
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const useStyles = makeStyles((theme) => ({
    favIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    fav: {
        marginRight: 5,
    }
}));

const SupplierList = forwardRef(({ value, showFeedBack, viewSupplierDetails, loading, setLoading }, ref) => {
    const intl = useIntl();
        // const suppliers = useSelector(state => Object.values(get(state, `entities.suppliers`) || {}).sort(function(a,b){
        //     return new Date(b.created_at) - new Date(a.created_at);
        //   }));

        const classes = useStyles();
        const [openAssetFormModel, setOpenAssetFormModel] = useState({
            isOpen: false,
            isReadOnly: false,
        });
        const [openCompanySelectModel, setOpenCompanySelectModel] = useState({
            isOpen: false,
        });
       const [suppliers, setSuppliers] = useState([]);
        const [companies, setCompanies] = useState([]);
        const [asset, setAsset] = useState({});

        const [pageSize, setPageSize] = useState(10);
        const [currentPage, setCurrentPage] = useState(1);
        const [totalCount, setTotalCount] = useState(1);
        const [showConfirmationBox, setShowConfirmationBox] = useState(false);
        const [supplierId, setSupplierId] = useState();
        const [supplierName, setsupplierName] = useState('');
        useEffect(()=>{
          // if(supplierName){
            getSuppliers(1, pageSize);
          // }
        },[supplierName])
        const dispatch = useDispatch();

        useEffect(() => {
            if(value === 1)
                getSuppliers(1, pageSize);
        }, [value]);

        // const mapCompanyIdNames = (assetData, companyData) => {
        // 	assetData.map((asset) => {
        // 		const assetCompany = companyData.find(
        // 			(company) => company.company_id === asset.company_id
        // 		);
        // 		asset.company_name = assetCompany.company_name;
        // 		asset.organization_name = assetCompany.organization_name;
        // 	});
        // 	setAssets(assetData);
        // };
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
          confirm();
         setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
          });
        };
        
        const handleReset = clearFilters => {
          clearFilters();
          setState({ searchText: '' });
        };
        const getColumnSearchProps = dataIndex => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                // ref={node => {
                //   searchInput = node;
                // }}
                placeholder={intl.formatMessage({ id:"search"})+` ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setsupplierName(e.target.value ? e.target.value : '')}
                onPressEnter={() => setsupplierName(selectedKeys.length ? selectedKeys[0] : '')}
                // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              {/* <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  <FormattedMessage id="search" />
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  <FormattedMessage id="reset" />
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({ closeDropdown: false });
                    setState({
                      searchText: selectedKeys[0],
                      searchedColumn: dataIndex,
                    });
                  }}
                >
                  <FormattedMessage id="filter" />
                </Button>
              </Space> */}
            </div>
          ),
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value, record) =>
            record[dataIndex]
              ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
              : '',
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              // setTimeout(() => searchInput.select(), 100);
            }
          },
          render: text =>
            state.searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            ),
        });
        const [state, setState] =  useState({
          searchText: '',
          searchedColumn: '',
        });

        const columns = [{
                dataIndex: "supplier_name",
                title: intl.formatMessage({ id:'supplier_name'}),
                key: "supplier_name",
                filters: getFilterValues("supplier_name", suppliers),
                ...getColumnSearchProps('supplier_name'),
                onFilter: (value, record) => record.supplier_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.supplier_name, b.supplier_name);
                    },
                },
            },
            {
                dataIndex:"country_name",
                title: intl.formatMessage({ id:'country'}),
                key: "country_name",
                filters: getFilterValues("country_name", suppliers),
                onFilter: (value, record) => record?.country_name?.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.country_name, b.country_name);
                    },
                },
            },
            {
                dataIndex:"rating",
                title: intl.formatMessage({ id:'rating'}),
                key: "rating",
                sorter: {
                    compare: (a, b) => {
                    return a.rating - b.rating;
                    },
                },
                render: (value, tableMeta, updateValue) => (
                    <Rate style={{fontSize: '14px'}} allowHalf value={value} disabled/>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: intl.formatMessage({ id:'view'}),
                key: "supplier_id",
                width: 75,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="viewIcon"
                    onClick={() => openSupplierForm(value, { readOnly: true, allowRating: true })}
                >
                  <FormattedMessage id={'view'} />
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: intl.formatMessage({ id:'modify'}),
                key: "supplier_id",
                width: 95,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="btn-primary"
                    onClick={() =>
                        openSupplierForm(value, { readOnly: false, allowRating: false })
                    }
                >
                <FormattedMessage id={'modify'} />
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title:intl.formatMessage({ id:'delete'}),
                key: "supplier_id",
                width: 80,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="deleteIcon"
                    onClick={() => deleteData(value)}
                >
                  <FormattedMessage id={'delete'} />
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title:intl.formatMessage({ id:'favourite'}),
                key: "supplier_id",
                render: (value, tableMeta, updateValue) => (
                    <div
                    onClick={() => updateFav(value)}
                >
                    <p className={classes.favIcon} > <FavoriteIcon style={{ color: getIsFav(value) === 0 ? '#c0c0c0': '#DC143C' }} className={classes.fav} /> {getIsFav(value) === 0 ? <FormattedMessage id={'mark_as_favourite'} /> : <FormattedMessage id={'unmark_as_favourite'} />}</p>
                    
                </div>
                ),
            },
        ];

        const getSuppliers = async (page, pageSize) => {
            const params = {limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC'}
            if(supplierName){
              params.supplier_name = supplierName
            }
            const response = await dispatch(fetchSuppliers(params, true));
            const count = response?.payload?.meta?.count;
            setTotalCount(count);
            setSuppliers(response?.payload?.result);
            setLoading(false);
        }

        const getIsFav = (supplier_id) => {
            const supplier = getSelectedSupplier(supplier_id);
            return supplier?.is_favourite;
        }

        const getSelectedSupplier = (supplier_id) => {
            return suppliers.find(supplier => supplier.supplier_id == supplier_id)
        };

        const updateFav = async (supplier_id) => {
            setLoading(true);
            const supplier = getSelectedSupplier(supplier_id);
            supplier.is_favourite = supplier.is_favourite === 0 ? 1 : 0;
            supplier.pincode = `${supplier.pincode}`;
            delete supplier.supplier_id;
            const updateResponse = await dispatch(updateSupplier(supplier_id, supplier));
            getSuppliers(currentPage, pageSize);
        }

        const openSupplierForm = (supplier_id, opts) => {
            let selected = getSelectedSupplier(supplier_id);
            viewSupplierDetails(selected, opts);
        };

        const deleteData = (id) => {
            setSupplierId(id);
            setShowConfirmationBox(true);
        };
        
        const declineSubmit = () => {
            setShowConfirmationBox(false);
            setSupplierId('');
        }

        const deleteSupplierData = async (supplierid) => {
          setLoading(true);
            const response = await dispatch(deleteSupplier(supplierid));
            showFeedBack({
                open: true,
                success: response?.payload?.result?.success || response?.payload?.body?.success,
                error: !response?.payload?.result?.success || !response?.payload?.body?.success,
                info: false,
                message: response?.payload?.result?.success ? response?.payload?.result.message : response?.payload?.body?.error?.message,
            })
            getSuppliers(1, pageSize);
            setShowConfirmationBox(false);
            setSupplierId('');
        };
        const deactiveSupplierData = async (supplierid) => {
            const response = await dispatch(deactiveSupplier(supplierid,0));
            showFeedBack({
                open: true,
                success: response?.payload?.result?.success || response?.payload?.body?.success,
                error: !response?.payload?.result?.success || !response?.payload?.body?.success,
                info: false,
                message: response?.payload?.result?.success ? response?.payload?.result.message == "Supplier deactivate successfully." ? intl.formatMessage({ id:'supplier_deactivate_msg'}) : response?.payload?.result.message : response?.payload?.body?.error?.message,
            })
            getSuppliers(1, pageSize);
            setShowConfirmationBox(false);
            setSupplierId('');
        };

        useImperativeHandle(ref, () => ({
        	manageSuppliers() {
		        getSuppliers(1, pageSize);
          },
          deleteSupplier(data) {
            deleteSupplierData(data.supplier_id)
          },
          deactiveSupplier(data) {
                  deactiveSupplierData(data.supplier_id,0)
          },

        }));

          const onPaginationChange = (pageDetails) => {
            setLoading(true);
            setPageSize(pageDetails.pageSize);
            setCurrentPage(pageDetails.current);
            getSuppliers(pageDetails.current, pageDetails.pageSize);
        }
	return (
    // !loading ?
    //  <Spin style={{ display: "flex", justifyContent: "center", alignSelf: "center", height:"100vh", alignItems:"center" }} />:
		<>
			<Table
                className="ant-table-root"
                // scroll={{ x: 0, y: '40vh' }}
                columns={columns}
                dataSource={suppliers}
                pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
                onChange={onPaginationChange}
                bordered
                rowKey='supplier_id'
                locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
			<div className="button1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="29.701"
					height="29.701"
					viewBox="0 0 29.701 29.701"
				>
					<path
						fill="#fff"
						d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
						transform="translate(-1 -1)"
					/>
				</svg>
				<span
					onClick={() => {
						viewSupplierDetails({},{ isOpen: true, allowRating: true });
					}}
				>
					{" "}
                    <FormattedMessage id={'addnew'} />
				</span>
			</div>
            <ConfirmationBox showConfirmationBox={showConfirmationBox} message={intl.formatMessage({ id:"delete_supplier"})} confirmSubmit={()=>deleteSupplierData(supplierId)} declineSubmit={declineSubmit} />
		</>
	);
});

export default SupplierList;