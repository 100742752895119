import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
    handleResponse,
    handleError,
    handleQuery,
    handleResponseNew
} from "../utils";
import { domainUrl } from "../helper";

export const FETCH_SUPPLIERS_RATING_REQUEST = "FETCH_SUPPLIERS_RATING_REQUEST";
export const FETCH_SUPPLIERS_RATING_SUCCESS = "FETCH_SUPPLIERS_RATING_SUCCESS";
export const FETCH_SUPPLIERS_RATING_FAILURE = "FETCH_SUPPLIERS_RATING_FAILURE";

export const CREATE_SUPPLIERS_RATING_REQUEST = "CREATE_SUPPLIERS_RATING_REQUEST";
export const CREATE_SUPPLIERS_RATING_SUCCESS = "CREATE_SUPPLIERS_RATING_SUCCESS";
export const CREATE_SUPPLIERS_RATING_FAILURE = "CREATE_SUPPLIERS_RATING_FAILURE";

export const FETCH_NEW_SUPPLIERS_REQUEST = "FETCH_NEW_SUPPLIERS_REQUEST";
export const FETCH_NEW_SUPPLIERS_SUCCESS = "FETCH_NEW_SUPPLIERS_SUCCESS";
export const FETCH_NEW_SUPPLIERS_FAILURE = "FETCH_NEW_SUPPLIERS_FAILURE";

export const CREATE_NEW_SUPPLIERS_REQUEST = "CREATE_NEW_SUPPLIERS_REQUEST";
export const CREATE_NEW_SUPPLIERS_SUCCESS = "CREATE_NEW_SUPPLIERS_SUCCESS";
export const CREATE_NEW_SUPPLIERS_FAILURE = "CREATE_NEW_SUPPLIERS_FAILURE";

export const FETCH_SUPPLIER_DECISION_REQUEST = "FETCH_SUPPLIER_DECISION_REQUEST";
export const FETCH_SUPPLIER_DECISION_SUCCESS = "FETCH_SUPPLIER_DECISION_SUCCESS";
export const FETCH_SUPPLIER_DECISION_FAILURE = "FETCH_SUPPLIER_DECISION_FAILURE";

export const supplierRatingSchema = new schema.Entity("supplier_ratings", [], {
    idAttribute: "supplier_ratings_id",
});

/**
 * Returns a redux api middleware action that makes a request for a collection of Suppliers.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchSupplierRatings = (params = {}, check) =>
    createAction({
        endpoint: `${domainUrl}/tender/v1/supplier-rating?${handleQuery(params)}`,
        method: "GET",
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: FETCH_SUPPLIERS_RATING_REQUEST },
            {
                type: FETCH_SUPPLIERS_RATING_SUCCESS,
                payload: (action, state, res) =>
                check ? handleResponseNew([supplierRatingSchema], action, state, res) : handleResponse([supplierRatingSchema], action, state, res),
            },
            { type: FETCH_SUPPLIERS_RATING_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that makes a request to create a supplier.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const createSuppliersRating = (data, params) => 
    createAction({
        endpoint: `${domainUrl}/tender/v1/supplier-rating?${handleQuery(params)}`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
        },
        types: [
            { type: CREATE_SUPPLIERS_RATING_REQUEST },
            {
                type: CREATE_SUPPLIERS_RATING_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse([supplierRatingSchema], action, state, res),
            },
            { type: CREATE_SUPPLIERS_RATING_FAILURE, payload: handleError },
        ],
    });
