import React from 'react';
import get from 'lodash/get';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { getRole } from '../../utils';
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles((theme) => ({
    message: {
        margin: '0 auto',
    },

}));

const NotAuthorized = () =>  {

	const userId = sessionStorage.getItem('user_id');
	const role_id = useSelector(state => get(state, `entities.users.${userId}.roles.role_id`));

	const classes = useStyles();
	
      return (
      	<>
      	<Header />
        {console.log(67857858,getRole(role_id))}
      <div className="container">
      	<h3 className={classes.message}>{getRole(role_id) == 'property manager' ? <FormattedMessage id='not_authorize_manger' /> : `You are logged in as a ${getRole(role_id)}  and not authorized to view this page.`}</h3>
      </div>
    </>	
      	) 
};
	

export default NotAuthorized;