import React, { useEffect, useState, useContext } from "react";
import get from 'lodash/get';
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Card, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import axios from "axios";
import { domainUrl, domainUrl2 } from "../../helper";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/TILogo_header.png";
import logo_text from "../../assets/logo_text.png";
import trending from "../../assets/trending.png";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { fetchFiles, fetchDocument } from "../../actions/documents";

import { AdminMenu, PropertyManagerMenu, SupplierMenu, Languages } from '../../utils/constants';
import { Context } from "../LanguageWrapper/LanguageWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { company } from "../../Locale/en";
export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});
const Header = (props) => {
  const context = useContext(Context);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [menuItem, setMenuItem] = useState([]);
  const [language, setLanguage] = useState([context.locale]);
  const history = useHistory();
  const userId = sessionStorage.getItem('user_id');
  const name = useSelector(state => get(state, `entities.users.${userId}.name`));
  const role_id = useSelector(state => get(state, `entities.users.${userId}.roles.role_id`));
  const documents = useSelector(state => get(state, `entities.files`));
  const entity_id = sessionStorage.getItem('entity_id');
  const PropertyManagerAdmin_type = sessionStorage.getItem('type_admin');
  const [imageUrl, setImageUrl] = useState("");
  const [searchData, setSearchData] = useState({});
  const [designCheck, setDesignCheck] = useState(false);

  useEffect(() => {
    if (searchData && (searchData?.companyData?.length > 0 || searchData?.tenderData?.length > 0)) {
      setDesignCheck(true)
    } else {
      setDesignCheck(false)
    }
  }, [searchData]);

  useEffect(() => {
    if (role_id) {
      setMenuItem(role_id === 2 ? ((PropertyManagerAdmin_type == 1 && role_id == 2) ? PropertyManagerMenu : SupplierMenu) : (role_id === 3 ? SupplierMenu : AdminMenu));
    }
    getImage();
  }, [role_id]);

  useEffect(() => {
    if (documents?.length > 0)
      getImage();
  }, [documents])

  const handleClick = (e) => {
    context.selectLanguage(e.key);
    setLanguage([e.key])
  }
  const onFilterChange = async ({ target }) => {
    try {
      let searchValue = target.value;

      let companies = await axios.get(
        domainUrl + `/masterservicenew/master/company?company_name=${searchValue}`,
        genHeaders()
      );

      let tenders = await axios.get(
        domainUrl + `/tender/v1/tender?limit=20&sort_by=DESC&title=${searchValue}`,
        genHeaders()
      );
     
      companies = companies?.data?.data?.result;
      tenders = tenders?.data?.data?.result;

      if (searchValue) {
        setSearchData({
          ...searchData,
          companyData: companies,
          tenderData: tenders
        })
      } else {
        setSearchData({
          companyData: [],
          tenderData: []
        })
      }
    } catch (e) {
      message.error(
        e?.message ?? "Unable to retrieve information from server."
      );
    }

  };
  const getImage = async () => {
    const result = await dispatch(fetchFiles({
      entity_type: 'TP',
      entity_id: userId,
    }));
    const files = Object.keys(result?.payload?.entities?.files || {});
    const file_id = files[files?.length - 1];
    if (file_id) {
      const res = await dispatch(fetchDocument(file_id));
      setImageUrl(res?.payload?.entities?.files[file_id]?.signed_link || '');
    }
  }

  const manageMenu = (
    <Menu>
      {
        menuItem.map(item => (
          <Menu.Item key={item.label}>
            <Link to={item.link}>{item.label}</Link>
          </Menu.Item>
        ))
      }
    </Menu>
  )

  const languageMenu = (
    <Menu onClick={handleClick} defaultSelectedKeys={language} selectedKeys={language}>
      {
        Languages.map(lang => (
          <Menu.Item key={lang.key}>
            <span>{lang.label}</span>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  const logoutMenu = (
    <Menu onClick={() => (
      sessionStorage.removeItem("token"), sessionStorage.removeItem("user_id"), window.location = '/login'
    )}>

      <Menu.Item key={'logout'}>
        <span><FormattedMessage id="logout" /> </span>
      </Menu.Item>

    </Menu>
  );
  
  return (
    <header className="htHeader">
      <Link to="/" className="ht-logo-wrapper">
        <img src={logo} className="ht-logo" alt="logo" />
        <img src={logo_text} className="ht-logo_text" alt="logo" />
      </Link>
      <div style={{ marginTop: "200px", height: "300px" }}>
        <Card style={{ display:'none',zIndex: !designCheck ? 0 : 3, border: !designCheck ? "#FFFFFF" : "1px solid #d5d5d5" }}>
          <div className="d-flex" style={{ marginBottom: !designCheck && "-25px" }}>
            <div className="width-80p"><input
              onChange={onFilterChange}
              placeholder={`${intl.formatMessage({id: 'search'})}...`}
              type="text"
              id="search"
              className="ht-search form-control header-search"
            /></div>
            {designCheck && <div className="width-20p position-view"><a onClick={() => {
              setSearchData({})
              document.getElementById("search").value = ''
            }}><FormattedMessage id={'clear'} /></a></div>}
          </div>
          {designCheck &&
            <>
              <div className="container-scroll">
                <h3>Matching Company</h3>
                <hr></hr>
                {searchData?.companyData?.map((val) => {
                  return (
                    <p>
                      <div className="d-flex" style={{cursor: "pointer"}} onClick={() => history.push({ pathname: "/tenders", state: val.company_id })}>
                        <div className="width-20p"><img src={trending} className="trend-logo_text" alt="trend" /></div>
                        <div className="width-80p">{val.company_name}</div>
                      </div>
                    </p>
                  )
                })}
                <h3>Matching Tenders</h3>
                <hr></hr>
                {searchData?.tenderData?.map((val) => {
                  return (
                    <div className="d-flex" style={{cursor: "pointer"}} onClick={() => history.push(`/tender/detail/${val.tender_id}`)}>
                      <div className="width-40p"><h4>{val.title}</h4></div>
                      <div className="width-60p"><h4>{val.published_date ? `Published date: ${val.published_date}` : ""}</h4></div>
                    </div>
                  )
                })}
              </div>
            </>}
        </Card>
      </div>

      <div className="rightSideLogin">
        <ul className="">
          {/* {
            (userId) &&
            <li className="contactSupport">
              <span><FormattedMessage id="Contactsupport" /></span>
            </li>
          } */}
          {
            userId &&
            <li className="manage">
              <Dropdown overlay={manageMenu} trigger={['click']} arrow placement="bottomCenter">
                <span onClick={e => e.preventDefault()}><FormattedMessage id="manage" /></span>
              </Dropdown>
            </li>
          }

          <li className="lang">
            <Dropdown overlay={languageMenu} trigger={['click']} placement="bottomCenter">
              <span onClick={e => e.preventDefault()}>{Languages.find(lang => lang.key === language[0])?.label} <DownOutlined /></span>
            </Dropdown>
          </li>
          {
            userId &&

            <li className="logout" style={{ marginLeft: 0 }}>
              <Dropdown overlay={logoutMenu} trigger={['click']} arrow placement="bottomCenter">
                <span><Avatar
                  src={imageUrl}
                  style={{
                    backgroundColor: '#2B7776', marginRight: 5,
                  }}
                  icon={(!imageUrl || imageUrl === '') && <UserOutlined />}
                />{name} <DownOutlined /></span>
              </Dropdown>
            </li>

          }

        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;