import { useState, useEffect } from "react";
import { Card, Spin, Button, DatePicker, Modal, message } from "antd";
import FileUpload from "../../../FileUpload";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import axios from "axios";
import moment from 'moment';
import { genHeaders } from "../../../Tender/Tender";
import './AwardCompletion.less';
import { sendEmailCommunication } from "../../../../actions/communication";
import { FormattedMessage,useIntl } from "react-intl";
import FileDisplay from "../../../FileDisplay";
import { changeDateFormat } from '../../../../utils/common';

const AwardCompletion = ({ winner }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const [updateDate, setUpdateDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListUpdated, setFileListUpdated] = useState([]);
  const [comment, setComment] = useState();
  const [error, setError] = useState();
  const [info, setInfo] = useState({});
  const { tenderId } = useParams();
  const intl = useIntl();
  const history = useHistory();

  const onMarkAsComplete = async () => {
    try {
      setLoading(true);
      await axios
        .put(
          `/tender/v3/tender/${tenderId}/management`,
          {
            comment,
            is_complete: true,
          },
          genHeaders()
        )
        .then(async() => {
          setLoading(false);
          setInfo((c) => ({ ...c, comment }));
          await dispatch(sendEmailCommunication('SUPPLIER_TENDER_COMPLETION', { tender_id: +tenderId }));
        
          history.push("/");
        });
    } catch (e) {
      message.error("Unable to perform desired action.");
      console.error(e);
    }
  };

  const onSetCompletionData = async () => {
    if(formattedDate)
      await submitAction();
  }

  const submitAction = async () => {
      try {
        let body;
        let methodToUse;
        console.log(info);
        if (info?.tender_management_id) {
          body = {
            reason: "Incorrectly set.",
          };
          methodToUse = "put";
        } else {
          body = {
            supplier_id: +winner,
          };
          methodToUse = "post";
        }
        await axios[methodToUse](
          `/tender/v3/tender/${tenderId}/management`,
          {
            completion_date: moment(formattedDate).format(`y-MM-DD`),
            ...body,
          },
          genHeaders()
        ).then((r) => {
          setInfo((c) => ({
            ...c,
            tender_management_id: !c.tender_management_id ? r.data.data.tender_management_id : c.tender_management_id,
            completion_date: moment(formattedDate).format(`y-MM-DD`),
            is_complete: 0,
          }));
          // setInfo((c) => ({
          //   ...c,
          //   tender_management_id: !c.tender_management_id ? r.data.data.tender_management_id : c.tender_management_id,
          //   completion_date: moment(formattedDate).format(`y-MM-DD`),
          // }));
          setUpdateDate(false);
        });
      } catch (e) {
        message.error("Unable to perform desired action.");
        console.error(e);
      }
  }

  const onDateChange = async (e) => {
    const formatDate = e?.format(`y-MM-DD`);
    setFormattedDate(moment(formatDate))
    //if (formattedDate) {
    //   console.log(e, formattedDate);
    
    // } 
  };

  useEffect(() => {
    const getCompletionInfo = async () => {
      setPageLoading(true);
      setError();
      try {
        const r = await axios
          .get(`/tender/v3/tender/${tenderId}/management`, genHeaders())
          .catch((e) => {
            if (e?.response?.status === 404) {
              console.log("no data found");
              return;
            }
            throw e;
          });

        if (r?.data?.data) {
          setInfo(r.data.data);
          setFormattedDate(moment(r.data.data.completion_date))
        }
      } catch (e) {
        setError("Unable to get progress/completion information");
      } finally {
        setPageLoading(false);
      }
    };

    getCompletionInfo();
  }, []);
  console.log(info,"======info")
  const updateFileList = (files) => {
    setFileListUpdated(files)
  }

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  let content;

  if (pageLoading) {
    content = <Spin style={{ display: "flex", justifyContent: "center" }} />;
  } else if (error) {
    content = <h3 style={{ textAlign: "center" }}>{error}</h3>;
  } else {
    content = (
      <>
        <div className="textBlock">
        <div className="labelBLock">{!info?.tender_management_id ? <FormattedMessage id='Enter completion date'/> : <FormattedMessage id='completion_date'/>}</div>
          <div
            className="contentBLock"
            style={{
              marginLeft: "auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
          {
            (!info?.tender_management_id || updateDate) ?  
              (<DatePicker value={formattedDate} onChange={onDateChange} style={{ borderRadius: 10 }} locale={intl.messages?.datepickerLocale} format={intl.messages?.datepickerLocale?.dateFormat}/>) : 
              (<p className="completion_date">{changeDateFormat(formattedDate,intl?.locale)}</p>)
          }
            {
              !info?.tender_management_id && 
                <Button className="form-btn ant-custom-btn date-btn" type="primary" disabled={!formattedDate} onClick={onSetCompletionData}>
                    <FormattedMessage id="SaveDate"/>
                </Button>
            }
            
          </div>
          {
              (info?.tender_management_id && !updateDate && info?.is_complete != 1) && 
                <Button className="form-btn ant-custom-btn date-btn" style={{borderRadius: "8px"}} type="primary" disabled={!formattedDate} onClick={() => setUpdateDate(true)}>
                   <FormattedMessage id="ChangeDate"/>
                </Button>
            }

            {
              (info?.tender_management_id && updateDate) && 
                <Button className="form-btn ant-custom-btn date-btn" type="primary" disabled={!formattedDate} onClick={onSetCompletionData}>
                  <FormattedMessage id="Save New Date"/>
                </Button>
            }
        </div>
        {info?.is_complete !== 1 &&  (
          <FileUpload
            filePostURL={`/tender/v1/document`}
            entityID={tenderId}
            entityType="TM"
            showTitle={false}
            addFileList={addFileList}
            emptyFilelist={true}
          />
        )}
        <FileDisplay entityType={'TM'} updateFileList={updateFileList} fileList={fileList} entityId={tenderId} hideTitle={false} deleteFiles={true}/>
        {info?.completion_date && info?.is_complete === 0 &&  (
          <Button style={{width: '100%', borderRadius: 6}} disabled={fileListUpdated.length === 0} onClick={() => fileListUpdated.length>0 && setShowModal(true)} className="btn">
               <FormattedMessage id="Mark As Complete"/>
          </Button>
        )}
      </>
    );
  }

  return (
    <div className="__AwardCompletion__">
      <div className="hedding">
      <h2> <FormattedMessage id="Date and progress"/></h2>
      </div>
      <div className="mainContainer">{content}</div>

      <Modal
        onCancel={() => setShowModal(false)}
        visible={showModal}
        footer={[
          <Button loading={loading} onClick={onMarkAsComplete}>
             <FormattedMessage id="Mark as Complete"/>
          </Button>,
        ]}
      >
        <div className="commentblock">
        <h2 className="left"> <FormattedMessage id="comments"/></h2>
          <div className="right">
            <textarea
              id="comments"
              style={{
                padding: "10px",
                border: "none",
                borderRadius: "10px",
                outline: "none",
                background: "#e6e9f4",
                width: '100%'
              }}
              onChange={(e) => setComment(e?.target?.value)}
            ></textarea>
          </div>
        </div>
{/* <h2><FormattedMessage id="proofs"/></h2>
        <FileUpload
          filePostURL={`/tender/v1/document`}
          entityID={tenderId}
          entityType="TM"
          showTitle={false}
        /> */}
      </Modal>
    </div>
  );
};

export default AwardCompletion;
