import React from 'react';

import { Form, Input, Button, Space, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
const { Option } = Select;

//@ATT:this was created to make nested dynamic elements! This is hard!
const AssetItemForm = (props) => {
  const intl = useIntl();
  return (
    <>
      <Form.List name={[props.fieldKey, 'items']}>
        {(items, { add, remove }) => {
          return (
            <div>
              {items.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={`item${key}`}
                  size="large"
                  style={{ display: 'flex', marginBottom: 8 }}
                  align='start'
                >
                  <Form.Item
                    // name={"aar"}
                    {...restField}
                    name={[name, 'item_text']}
                    fieldKey={[`item${fieldKey}`, 'item_text']}
                    label={intl.formatMessage({id: 'enter_item'})}
                    colon={false}
                    // noStyle
                    rules={[
                        {
                          required: true, message: intl.formatMessage({id: 'asset_item_missing'})
                        },
                      ]}
                  >
                    <Input placeholder={intl.formatMessage({id: 'enter_item'})}/>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                    }}
                  />
                </Space>
              ))}
              <Form.Item>
              <div className="formGroup">
                <span onClick={() => {
                    add();
                  }} className="addItems">+<FormattedMessage id="add_item" /></span>
              </div>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default AssetItemForm;