import React, {useState} from 'react';
import { Button, Tooltip } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

import Header from "../../components/Header";

import InspectionSearch from "../../components/InspectionSearch";
import InspectionReportAsset from "../../components/InspectionReportAsset";
import InspectionSearchVault from "../../components/InspectionSearchVault";
import { FormattedMessage } from 'react-intl';

const InspectionReport = () => {

  const [assetSelectionTable, setAssetSelectionTable] = useState(false);
  const [searchVault, setSearchVault] = useState(false);
  const [inspectionReports, setInspectionReports] = useState({});

  const reportsReceived = (reports) => {
    setInspectionReports(reports);
    if(Object.keys(reports).length > 0)
      setSearchVault(true);
  }

	return (
    <>
      <Header />
      <div className="mainContainer">
      	<div className="innerContainer">
              <InspectionSearch reportsReceived={reportsReceived}/>
              <Button className="ant-custom-btn" style={{borderRadius: "8px"}} onClick={() => setAssetSelectionTable(true)} icon={<PlusCircleFilled />}> <FormattedMessage id={'Add_New_Inspection'} /></Button>
        </div>
      </div>
      <InspectionReportAsset isModalVisible={assetSelectionTable} handleCancel={() => setAssetSelectionTable(false)}/>
      {
        Object.keys(inspectionReports).length > 0 && 
          <InspectionSearchVault isModalVisible={searchVault} inspectionReports={inspectionReports} handleCancel={() => {setSearchVault(false);setInspectionReports({})}}/>
      }
    </>
  );
};
 export default InspectionReport;
