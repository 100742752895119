import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { generateTenderPdf } from "../../actions/tender";
import { Modal } from "antd";
import { set } from "lodash";



const PdfDisplay = ({tender_id, closePreview}) => {
    const dispatch = useDispatch();
    const [pdfFile, setPdfFile] = useState([]);
    useEffect(() => {
		const getDocument = async() => {
    const document = await dispatch(generateTenderPdf(tender_id));
    console.log(document?.payload);
    const contentType = "application/pdf";
    // const win = window.open(URL.createObjectURL(document?.payload), "_blank");
    //   win.focus();
    //   closePreview();
    const file = new Blob(
        [document?.payload], 
        {type: 'application/pdf'}
    );
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    setPdfFile(fileURL);
    //Open the URL on new Window
    //window.open(fileURL);
    // setPdfFile("data:" + contentType + ";base64," + document?.payload)
    // setPdfFile(URL.createObjectURL(document?.payload));
    // setTimeout(() => {
    // closePreview();

          // }, 1000);
		}
		getDocument();
		}, [dispatch]);

    const handleCancel = () => {
        closePreview();
    }

	return (
		<>
        <Modal visible={true} closable={false} footer={null} onCancel={handleCancel}>
        <div style={{height: '75vh'}}>
        <embed
                src={pdfFile}
                id="displayFile"
                alt="tender pdf"
                width="97%"
                height="99%"
                style={{ borderStyle: "solid"}}
                type="application/pdf"
              />
              </div>
        </Modal>
		</>
	);
};

PdfDisplay.propTypes = {
	tender_id: PropTypes.number,
};

export default PdfDisplay;