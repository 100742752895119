import React, { useEffect, useState } from "react";
import { Modal, Divider, Spin, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import "./Summary.css";
import { useTender } from "../TenderCreationSteps";
import FileDisplay from '../../FileDisplay';
import PdfDisplay from '../../PDFDisplay';
import EmailFormatter from "../../EmailFormatter";
import { fetchTenderTypes } from "../../../actions/tender";
import ToolTip from '../../ToolTip';
import { fetchSuppliers } from '../../../actions/suppliers';
import { sendEmailCommunication } from "../../../actions/communication";
import { FormattedMessage, useIntl } from "react-intl";
import { changeDateFormat } from '../../../utils/common';

const emailSections = [
  {
    value: "Tender Info",
    selected: false,
  },
];

const Summary = ({updateTenderData}) => {
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();
  const dispatch = useDispatch();

  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const companies = useSelector(state => get(state, `entities.companies`));
  const suppliers = useSelector(state => get(state, `entities.suppliers`));
  const tenderCompanies = useSelector(state => get(state, `entities.tenderCompanies`) || {});
  const tenderSuppliers = useSelector(state => get(state, `entities.tenderSuppliers`) || {});
  
  const userId = sessionStorage.getItem('user_id');
  const user = useSelector(state => get(state, `entities.users.${userId}`));

  const tenderType = useSelector(state => get(state, `entities.tenderTypes.${tenderDetails.tender_type}.tender_type_label`) || tenderDetails.tender_type);
  const tenderTypeModified = useSelector(state => get(state, `entities.tenderTypes.${tenderModifiedDetails.tender_type}.tender_type_label`) || tenderModifiedDetails.tender_type)
  const tenderAssets = useSelector(state => Object.values(get(state, `entities.tenderAssets`) || {}).filter(asset => {
    return asset.tender_id === tenderDetails.tender_id
  }));
  const intl = useIntl();
  const [previewDetails, setPreviewDetails] = useState({});
  const [sentEmailModel, setSentEmailModel] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    dispatch(fetchTenderTypes());
    dispatch(fetchSuppliers({limit:100}));

  },[dispatch]);

  useEffect(() => {
    if(tenderDetails.is_published === 0) {
      setPreviewDetails({
        ...tenderDetails,
        tenderTypeCreated: tenderDetails?.tender_type === 'o' ? tenderDetails?.tender_type_other : tenderType,
        companiesAdded: Object.values(tenderCompanies || []),
        suppliersAdded: tenderSuppliers,
        assetsAdded: tenderAssets,
        selectedAssetDb: [],
      })
    }
    else {
      //For companies
      if(!tenderModifiedDetails.selectedCompanies || (tenderModifiedDetails?.selectedCompanies?.length === 0)) {
        
        tenderModifiedDetails.selectedCompaniesToShow = Object.values(tenderCompanies || []);
      }
      // else if(tenderModifiedDetails?.selectedCompanies[0]?.company_id) {

      // }
      else
        {
          tenderModifiedDetails.selectedCompaniesToShow = tenderModifiedDetails?.selectedCompanies
          // tenderModifiedDetails.selectedCompaniesToShow = tenderModifiedDetails?.selectedCompanies?.map(item => {
          //   return companies[item];
          // })
        }
        //For Suppliers
        if(!tenderModifiedDetails.suppliers || (tenderModifiedDetails?.suppliers?.length === 0)) {
        
          tenderModifiedDetails.selectedSupplierToShow = Object.values(tenderSuppliers || []);
        }
        else
          {
            tenderModifiedDetails.selectedSupplierToShow = tenderModifiedDetails?.suppliers?.map(item => {
              return suppliers[item];
            })
          }
          //For Assets
          if(!tenderModifiedDetails.assetsFormValues || (tenderModifiedDetails?.assetsFormValues?.length === 0)) {
        
            tenderModifiedDetails.selectedAssetsToShow = Object.values(tenderAssets || []);
          }
          else
            {
              tenderModifiedDetails.selectedAssetsToShow = tenderModifiedDetails?.assetsFormValues;
            }

      setPreviewDetails({
        ...tenderModifiedDetails,
        tenderTypeCreated: tenderModifiedDetails?.tender_type === 'o' ? tenderModifiedDetails?.tender_type_other : tenderTypeModified,
        companiesAdded: tenderModifiedDetails.selectedCompaniesToShow,
        suppliersAdded: tenderModifiedDetails.selectedSupplierToShow,
        assetsAdded: tenderModifiedDetails.selectedAssetsToShow,
        selectedAssetDb: tenderModifiedDetails.selectedAssetDb,
      })
    }
  },[tenderDetails,tenderModifiedDetails,suppliers])
  const [showConfirmationModal, setShowConfirmationModal] = useState();

  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#2B7776",
      padding: "10px",
    },
  };

  const publishTender = async() => {
    setShowConfirmationModal(false);
    if(tenderDetails.is_published !== 1){
       setIsLoading(true);
      let status = await updateTenderData({
        is_published: true,
      })
      if(status) {
        await dispatch(sendEmailCommunication('NOTIFY_ALL_SUPPLIER', {tender_id: tenderDetails.tender_id, company_name: user.roles.role_id === 1 ? 'Hissitaito OY' : ''}));
      }
      setIsLoading(false);
      move(1);

    }
    else {
      modifyData();
    }
  }

  const closePreview = () => {
    setShowPreview(false);
  }
  const validatePublish = () => {
    if((!previewDetails?.assetsAdded || previewDetails?.assetsAdded.length === 0) && (!uploadedFiles || uploadedFiles.length === 0)) {
        message.error(`${intl.formatMessage({id: 'please_add_assets_or_upload_files_to_publish_a_tender'})}`);
    }
    else{
      setShowConfirmationModal(true);
    }

  }
  console.log(previewDetails,"======previewDetails");
  return (
    <Spin spinning={isLoading} >
      <div className="summaryOuter">
        <div className="summaryHeadder">
          <h2><FormattedMessage id="summary" /> <ToolTip text_id="tender.summary.info" placement="top" /></h2>
        </div>
          <div className="mainContainer">
          <div className="block01">
            <EditOutlined onClick={() => move(-8)} {...editIconProps} />
          </div>
          <div className="textBlock">
            <div className="labelBLock"><FormattedMessage id="title" /></div>
            <div className="contentBLock">{previewDetails?.title}</div>
          </div>
          <div className="textBlock">
            <div className="labelBLock"><FormattedMessage id="desc" /></div>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className="contentBLock"
              dangerouslySetInnerHTML={{ __html: previewDetails?.description }}
            ></div>
          </div>
          <div className="textBlock">
            <div className="labelBLock"><FormattedMessage id="tenderType" /></div>
            <div className="contentBLock">{previewDetails.tenderTypeCreated}</div>
          </div>
          <div className="textBlock">
            <div className="labelBLock"><FormattedMessage id="company" /></div>
          <div >
            <ul>
              {previewDetails?.companiesAdded?.map((company, index) => (
                <li key={index}>{company?.company_name}</li>
              ))}
            </ul>
          </div>
          </div>
        </div>

        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="items" /></div>
            <EditOutlined onClick={() => move(-4)} {...editIconProps} />
          </div>
          {previewDetails?.assetsAdded?.map((item, index) => (
            <React.Fragment key={index}>
              <div
                style={{ margin: "10px 0", flexDirection: "column" }}
                className="textBlock list01"
              >
                <div className="labelBLock">{Array.isArray(item?.asset_name) && !isNaN(item?.asset_name[0]) ? previewDetails?.selectedAssetDb[index]?.label : (item?.line_text || item?.asset_name)}</div>
                <ul>
                  {item?.items?.map((i, itemIndex) => (
                    <li key={itemIndex}>{i?.item_text}</li>
                  ))}
                </ul>
              </div>
            <Divider />
            </React.Fragment>
          ))}
        </div>

        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="files" /></div>
            <EditOutlined onClick={() => move(-3)} {...editIconProps} />
          </div>

          <FileDisplay entityType={'T'} entityId={tenderDetails.tender_id} hideTitle={true} getFileAvailability={(files) => setUploadedFiles(files)}/>
        </div>
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="additional_info" /></div>
            <EditOutlined onClick={() => move(-1)} {...editIconProps} />
          </div>
          <div
            style={{ whiteSpace: "pre-wrap" }}
            className="contentBLock"
            dangerouslySetInnerHTML={{ __html: previewDetails?.additional_info }}
          ></div>
        </div>
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="chose_suppliers" /></div>
            <EditOutlined onClick={() => move(-5)} {...editIconProps} />
          </div>
          <div className="list01">
            <ul>
              {Object.values(previewDetails?.suppliersAdded || [])?.map((supplier, index) => (
                <li key={index}>{supplier?.supplier_name}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mainContainer">
          <div className="textBlock">
            <div className="labelBLock"><FormattedMessage id="closing_date" /></div>
            <div className="contentBLock">{changeDateFormat(previewDetails?.closing_date,intl?.locale)}</div>
          </div>
        </div>
        <div className="buttonBlock">
          <div className="btn cancelBtn"  onClick={() => setShowPreview(true)}><FormattedMessage id="preview" /></div>
          <div onClick={() => setSentEmailModel(true)} className="btn">
          <FormattedMessage id="email_info" />
          </div>
          <div onClick={validatePublish} className="btn">
          <FormattedMessage id="publish" />
          </div>
        </div>

        <Modal visible={showConfirmationModal} closable={false} footer={null}>
          <h2 style={{ textAlign: "center" }}>
          <FormattedMessage id="publish_tender_conf" />
          </h2>
          <div className="d-flex j-center f-col">
            <div className="btn" onClick={publishTender}>
            <FormattedMessage id="yes" />
            </div>
            <div
              className="btn"
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              <FormattedMessage id="no" />
            </div>
          </div>
        </Modal>
        {
          showPreview && 
          <PdfDisplay tender_id={tenderDetails.tender_id} closePreview={closePreview}/>
        }
        {sentEmailModel && (
          <EmailFormatter
            isOpen={sentEmailModel}
            onClose={() => setSentEmailModel(false)}
            contentList={emailSections}
            tender_id={tenderDetails.tender_id}
          />
        )}
      </div>
    </Spin>
  );
};

export default Summary;
