import React, { useEffect, useState } from "react";
import { DatePicker, message, ConfigProvider } from "antd";
import moment from 'moment';
import { FormattedMessage, useIntl } from "react-intl";

import "../AdditionalInfo/AdditionalInfo.css";
import "./ChooseClosingDate.css";
import { useTender } from "../TenderCreationSteps";
import ToolTip from '../../ToolTip';
import localeFI from "antd/lib/locale/fi_FI";
import localeEN from "antd/lib/locale/en_US";
import 'moment/locale/fi';

const ChooseClosingDate = ({updateTenderData}) => {
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();
  const intl = useIntl();

  const [closingDate, setClosingDate] = useState([]);
  const [reminder, setReminder] = useState(tenderDetails?.days_reminder ?? 1);
  const [errorMessage, setErrorMessage] = useState("");
  const onDateChange = (e) => {
    const formattedDate = e?.format(`y-MM-DD`);
    setClosingDate([e, formattedDate]);
  };

  useEffect(() => {
    if(tenderDetails?.is_published === 0 && tenderDetails?.closing_date !== null) {
      setClosingDate([moment(tenderDetails?.closing_date), tenderDetails?.closing_date])
    }

  },[tenderDetails?.closing_date])

  useEffect(() => {
    if(tenderModifiedDetails?.is_published === 1 && tenderModifiedDetails?.closing_date !== null) {
      setClosingDate([moment(tenderModifiedDetails?.closing_date), tenderModifiedDetails?.closing_date]);
      setReminder(tenderModifiedDetails?.days_reminder ?? 1)
    }

  },[tenderModifiedDetails?.closing_date])

  const onBack = () => {
    move(-1, { closingDate, reminder });
  };

  const onNext = async() => {

    setErrorMessage(null);

    if (!(closingDate && closingDate.length && closingDate[1]) || !reminder) {
      setErrorMessage(
        intl.formatMessage({id: 'closing_date_req'})
      );
      return;
    }
    if(tenderDetails.is_published !== 1) {
      const status = await updateTenderData( {
        closing_date: closingDate[1],
        days_reminder: +reminder,
        state: '7',
      });
      if(status) {
        move(1, { closing_date: closingDate[1], days_reminder: +reminder  });
      }
      else {
        message.error( intl.formatMessage({id: 'check_closing_date'}));
      }
    }
    else {
      move(1, {
        closing_date: closingDate[1], days_reminder: +reminder 
      },true);
    }
  };

  const publishData = () => {
    move(0, {
      closing_date: closingDate[1], days_reminder: +reminder 
    },true);
    modifyData();
  }

  return (
    <div className="__ClosingDate__">
      <div className="hedding">
          <h2><FormattedMessage id='choose_closing_date' /> <ToolTip text_id="tender.closing_date.info" placement="top" /></h2>
        </div>
      <div className="formGroup">
        <label className="leftCol"><FormattedMessage id='closing_date' /></label>
        <div className="selectDate">
        <ConfigProvider locale={intl.locale === 'en'? localeEN : localeFI}>
          <DatePicker
            value={closingDate[0]}
            onChange={onDateChange}
            style={{ padding: "10px", borderRadius: "10px" }}
            locale={intl.messages?.datepickerLocale} 
            format={intl.messages?.datepickerLocale?.dateFormat}
          />
          </ConfigProvider>
        </div>
      </div>
      <div className="formGroup">
        <label className="leftCol"><FormattedMessage id='send_reminders_before' /></label>
        <input
          type="number"
          min={1}
          value={reminder}
          onChange={({ target }) => setReminder(target.value)}
          className="formFiled days"
        />
        <span className="dayslabel"><FormattedMessage id='days' /></span>
      </div>
      {errorMessage && (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          {errorMessage}
        </p>
      )}
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          <FormattedMessage id='bakc' /> </div>
        {
          tenderDetails?.is_published === 1 && 
          <div className="btn" onClick={publishData}>
            <FormattedMessage id='publish' />
          </div>
        }
        <div onClick={onNext} className="btn">
          <FormattedMessage id='next' /> </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default ChooseClosingDate;
