import React, { useState, useEffect } from 'react'
import Header from '../../components/Header';
import { Table, message, Pagination } from 'antd';
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { domainUrl } from "../../helper";
import axios from "axios";
import { changeDateFormat } from '../../utils/common';

export const genHeaders = () => ({
    headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
    },
});

const DashboardDetails = (props) => {
    const intl = useIntl();
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(1);
    const [tenders, setTenders] = useState([]);
    const cardId = props?.props?.location?.state;
    const role = sessionStorage.getItem('role_id')

    const dateFunc = (value) => {
        var date = value;
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        date = yyyy + '-' + mm + '-' + dd;
        return date;
    }

    useEffect(() => {
        getInfo(1, pageSize);
    }, [cardId]);

    const onPaginationChange = (pageDetails) => {
        setPageSize(pageDetails.pageSize);
        getInfo(pageDetails.current, pageDetails.pageSize);
    }

    const getInfo = async (page, pageSize) => {

        if (cardId == 1) {
            try {
                let publishedCallDay = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&order_by=published_date&sort_by=DESC&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = publishedCallDay?.data?.data?.meta?.count;
                setTotalCount(count);
                // publishedCallDay = publishedCallDay?.data?.data?.result;
                // setTenders(publishedCallDay)
                let published = publishedCallDay?.data?.data.result;
                published = [...new Map(published.map(item => [item['tender_id'], item])).values()];
                // const count = published.filter((val) => dateFunc(new Date(val.created_at)) == dateFunc(new Date())).length;
                // setTotalCount(count);
                publishedCallDay = published.filter((val) => dateFunc(new Date(val.created_at)) == dateFunc(new Date()));
                setTenders(publishedCallDay)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 2) {
            try {
                let publishedCallWeek = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&order_by=published_date&sort_by=DESC&fetched_by=week&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = publishedCallWeek?.data?.data?.meta?.count;
                setTotalCount(count);
                publishedCallWeek = publishedCallWeek?.data?.data?.result;
                // publishedCallWeek = [...new Map(publishedCallWeek?.map(item => [item['tender_id'], item])).values()];;
                setTenders(publishedCallWeek)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 3) {
            try {
                let publishedCallMonth = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&order_by=published_date&sort_by=DESC&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = publishedCallMonth?.data?.data?.meta?.count;
                setTotalCount(count);
                publishedCallMonth = publishedCallMonth?.data?.data?.result;
                setTenders(publishedCallMonth)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 4) {
            try {
                let completedCall = await axios
                    // .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_completed=1&order_by=completion_date&sort_by=DESC&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_completed=1&order_by=completion_date&sort_by=DESC&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = completedCall?.data?.data?.meta?.count;
                setTotalCount(count);
                completedCall = completedCall?.data?.data?.result;
                completedCall = [...new Map(completedCall?.map(item => [item['tender_id'], item])).values()];;
                setTenders(completedCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 5) {
            try {
                let dueCompletionCall = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_awarded=1&is_completed=0&sort_by=DESC&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = dueCompletionCall?.data?.data?.meta?.count;
                setTotalCount(count);
                dueCompletionCall = dueCompletionCall?.data?.data?.result;
                setTenders(dueCompletionCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 6) {
            try {
                let failedCompletionCall = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_completed=0&sort_by=DESC&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = failedCompletionCall?.data?.data?.meta?.count;
                setTotalCount(count);
                failedCompletionCall = failedCompletionCall?.data?.data?.result;
                failedCompletionCall = failedCompletionCall.filter((val) => val.completion_date != null && val.completion_date < dateFunc(new Date()))
                setTenders(failedCompletionCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 7) {
            try {
                let suppliersRegisteredCall = await axios
                    .get(`${domainUrl}/users?entity_type=supplier&fetched_by=month&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = suppliersRegisteredCall?.data?.data?.meta?.count;
                setTotalCount(count);
                suppliersRegisteredCall = suppliersRegisteredCall?.data?.data?.result;
                setTenders(suppliersRegisteredCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 8) {
            try {
                let activeTendersCall = await axios
                    .get(`${domainUrl}${role === '3' ? "/tender/v2/tender/supplier?" : "/tender/v1/tender?"}is_published=1&is_awarded=0&sort_by=DESC&limit=${pageSize}&offset=${pageSize * (page - 1)}`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = activeTendersCall?.data?.data?.meta?.count;
                setTotalCount(count);
                activeTendersCall = activeTendersCall?.data?.data?.result;
                setTenders(activeTendersCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }

        if (cardId == 9) {
            try {
                let invitedSuppliersCall = await axios
                    .get(`${domainUrl}/masterservicenew/master/supplier_request/invite?&limit=${pageSize}&offset=${pageSize * (page - 1)}&fetched_by=month`, genHeaders())
                    .catch((e) => {
                        console.error(e);
                        return null;
                    });
                const count = invitedSuppliersCall?.data?.data?.meta?.count;
                setTotalCount(count);
                invitedSuppliersCall = invitedSuppliersCall?.data?.data?.result;;
                setTenders(invitedSuppliersCall)
            } catch (e) {
                message.error(
                    e?.message ?? "Unable to retrieve information from server."
                );
            }
        }
    };

    const columns = role == 3 ? [
        {
            title: <FormattedMessage id={'tender_name'} />,
            dataIndex: 'title',
            key: 'title', //api response
            sorter: (a, b) => a.title?.length - b.title?.length,
            defaultSortOrder: 'descend',
            // ...getColumnSearchProps('title'),
            width: 100,
            render: (text, record) => (
                <Link to={tenders && tenders[0]?.roles?.role_id == "3" ? `/tender/detail/${record.tender_id}` : `/tender/detail/supplier/${record.tender_id}`}>{text}</Link>
            )
        },
        {
            title: <FormattedMessage id={'tender_id'} />,
            dataIndex: 'tender_id',
            key: 'tender_id',
            width: 45,
        },
        {
            title: <FormattedMessage id={'published_date'} />,
            dataIndex: 'published_date',
            key: 'published_date',
            width: 45,
            render: (value) => <span>{changeDateFormat(value,intl?.locale)}</span>,
        },
        {
            title: <FormattedMessage id={'closing_date'} />,
            dataIndex: 'closing_date',
            key: 'closing_date',
            width: 45,
            render: (value) => <span>{changeDateFormat(value,intl?.locale)}</span>,
        },
        {
            title: <FormattedMessage id={'Organisation_Name'} />,
            dataIndex: 'organization_name',
            key: 'organization_name',
            width: 45,
        },
        {
            title: <FormattedMessage id={'housing_company_name'} />,
            dataIndex: 'company_name',
            key: 'company_name',
            width: 45,
        }
    ] : [
        {
            title: <FormattedMessage id={'tender_name'} />,
            dataIndex: 'title',
            key: 'title', //api response
            sorter: (a, b) => a.title?.length - b.title?.length,
            defaultSortOrder: 'descend',
            // ...getColumnSearchProps('title'),
            width: 100,
            render: (text, record) => (
                <Link to={tenders && tenders[0]?.roles?.role_id == "3" ? `/tender/detail/${record.tender_id}` : `/tender/awarded/${record.tender_id}`}>{text}</Link>
            )
        },
        {
            title: <FormattedMessage id={'tender_id'} />,
            dataIndex: 'tender_id',
            key: 'tender_id',
            width: 45,
        },
        {
            title: <FormattedMessage id={'published_date'} />,
            dataIndex: 'published_date',
            key: 'published_date',
            width: 45,
            render: (value) => <span>{changeDateFormat(value,intl?.locale)}</span>,
        },
        {
            title: <FormattedMessage id={'closing_date'} />,
            dataIndex: 'closing_date',
            key: 'closing_date',
            width: 45,
            render: (value) => <span>{changeDateFormat(value,intl?.locale)}</span>,
        },
        {
            title: <FormattedMessage id={'Organisation_Name'} />,
            dataIndex: 'organization_name',
            key: 'organization_name',
            width: 45,
        },
        {
            title: <FormattedMessage id={'housing_company_name'} />,
            dataIndex: 'company_name',
            key: 'company_name',
            width: 45,
        },
        {
            title: <FormattedMessage id={'bids_counts_received'} />,
            dataIndex: 'bid_count',
            key: 'bid_count',
            width: 35,
        }
    ];

    const supplierColumns = [
        {
            title: <FormattedMessage id={'supplier_name'} />,
            dataIndex: 'name',
            key: 'name', //api response
            sorter: (a, b) => a.name?.length - b.name?.length,
            defaultSortOrder: 'descend',
            // ...getColumnSearchProps('name'),
            width: 100
        },
        {
            title: <FormattedMessage id={'email'} />,
            dataIndex: 'email',
            key: 'email',
            width: 45,
        },
        {
            title: <FormattedMessage id={'mobile_number'} />,
            dataIndex: 'contact',
            key: 'contact',
            width: 45,
        }
    ];

    const invitedColumns = [
        {
            title: <FormattedMessage id={'email'} />,
            dataIndex: 'supplier_email',
            key: 'supplier_email',
            width: 60,
        }
    ];

    return (
        <div>
            <Header />
            <div className="mainContainer profileContainer">
                <div className="innerContainer">
                    <h1 className="profile"><FormattedMessage id={tenders && tenders[0]?.roles?.role_id == "3" ? 'suppliers' : tenders && tenders[0]?.supplier_email ? 'invited_suppliers' : 'tenders'} /></h1>
                    <div className="profile-wrapper">
                        <Table
                            columns={tenders && tenders[0]?.roles?.role_id == "3" ? supplierColumns : tenders && tenders[0]?.supplier_email ? invitedColumns : columns}
                            dataSource={tenders}
                            pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
                            onChange={onPaginationChange}
                            locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default DashboardDetails