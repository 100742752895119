import React, { useState, useEffect, useRef } from "react";
import { message, Rate, Empty, Checkbox, Avatar, Popover } from "antd";
import axios from "axios";
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { domainUrl } from "../../../helper";
import "./ChooseSupplier.less";
import { genHeaders, useTender } from "../TenderCreationSteps";
import { MinusCircleOutlined,UserOutlined,InfoCircleOutlined } from "@ant-design/icons";

import { useParams } from "react-router";
import ToolTip from '../../ToolTip';
import { FormattedMessage, useIntl } from "react-intl";

//<----> REFACTOR <----->
const ChooseSupplier = ({modifySuppliers, updateTenderData}) => {

  const dispatch = useDispatch();
  const intl = useIntl();
  const { tenderId } = useParams();
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();

  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [emails, setEmails] = useState(tenderDetails?.invites ?? []);

  const [isUploading, setIsUploading] = useState(false);

  const [favSelected, setFavSelected] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const serviceTypeRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const ratingRef = useRef();
  const emailRef = useRef();
  //tenderDetails.suppliers = useSelector(state => Object.keys(get(state, `entities.tenderSuppliers`) || {}));
  tenderDetails.suppliers = useSelector(state => Object.values(get(state, `entities.tenderSuppliers`) || []).filter(item => {
      return item.tender_id === parseInt(tenderId)
  }));

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  useEffect(() => {
    
    axios
      .get(domainUrl + "/masterservicenew/master/supplier?limit=100", genHeaders())
      .then((r) => {
        const res = r.data.data.result;
        if (res && res.length) {
          setSuppliers(res);
          setFilteredSuppliers(res);

         // setAllSelected(res?.length === tenderDetails?.suppliers.length ? true : false);
         // setSelectedSuppliers(tenderDetails?.suppliers ?? []);

          const serviceTypeList = new Set();
          const countryList = new Set();
          const cityList = new Set();

          for (let supplier of res) {
            if (supplier.service_types && supplier.service_types.length) {
              for (let type of supplier.service_types) {
                if (type?.service_type_name) {
                  serviceTypeList.add(type.service_type_name);
                }
              }
            }

            if (supplier?.country_name) countryList.add(supplier.country_name);

            if (supplier?.service_cities.length > 0){
              supplier?.service_cities.map(city => {
                cityList.add(city.city_name);
              })
            } 
          }

          setServiceTypes(Array.from(serviceTypeList));
          setCountries(Array.from(countryList));
          setCities(Array.from(cityList));
        }
      })
      .catch((e) => {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      });
  }, []);

  useEffect(() => {
    
    let supplierIds;
    //const supplierIds = tenderDetails?.suppliers?.map(item => {return item.supplier_id});

    if(tenderDetails?.is_published === 0) {
      supplierIds = tenderDetails?.suppliers?.map(item => {return item.supplier_id});

      setAllSelected(suppliers?.length === tenderDetails?.suppliers.length ? true : false);
    }
    else {
      if(!tenderModifiedDetails?.suppliers && tenderDetails)
        tenderModifiedDetails.suppliers = tenderDetails?.suppliers?.map(item => {return item.supplier_id});
      if(tenderModifiedDetails?.suppliers && tenderModifiedDetails?.suppliers[0]?.supplier_id) {
        supplierIds = tenderDetails?.suppliers?.map(item => {return item.supplier_id});
      }
      else
        supplierIds = tenderModifiedDetails?.suppliers?.map(item => {return item});

      setAllSelected(suppliers?.length === tenderModifiedDetails?.suppliers?.length ? true : false);
    }

    setSelectedSuppliers(supplierIds ?? []); 
  },[suppliers,tenderDetails]);

  const getNameById = (id) => {
    return Object.values(suppliers).find(el => el.supplier_id === id);
  }

  const onFilterChange = () => {
    const s_type = serviceTypeRef.current.value?.toLowerCase() ?? "";
    const ctry = countryRef.current.value?.toLowerCase() ?? "";
    const city = cityRef.current.value?.toLowerCase() ?? "";
    const rate = ratingRef.current.value ?? "";
    setFilteredSuppliers([]);
    const filtered = suppliers?.filter(
      ({ service_types, country_name, service_cities, rating }) => {
        let includesST, includeSC;

        if (s_type === "") includesST = true;
        else {
          includesST =
            service_types.filter(
              (el) => el?.service_type_name?.toLowerCase() === s_type
            ).length >= 1;
        }

        if (city === "") includeSC = true;
        else {
          includeSC =
            service_cities.filter(
              (el) => el?.city_name?.toLowerCase() === city
            ).length >= 1;
        }

       // const includesCtry = country_name?.toLowerCase().includes(ctry);
       // const includesCity = city_name?.toLowerCase().includes(city);
        const includesRating = rate === "" ? true : Math.floor(rating) === parseInt(rate);

        return includesST && includeSC && includesRating;
      }
    );
    setFilteredSuppliers(filtered);
  };

  const onSupplierSelect = ({ target }) => {
    const id = +target.value;
    setSelectedSuppliers((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newSuppliers = selectedSuppliers.slice();
        newSuppliers.push(id);
        return newSuppliers;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onSelectAll = () => {
    let supplierIDs;
    supplierIDs = !allSelected ? filteredSuppliers?.map((el) => +el.supplier_id) ?? [] : [];
    setSelectedSuppliers(supplierIDs);
    setFavSelected(false);
    setAllSelected(!allSelected);
  };

  const onSelectFav = () => {

    const supplierIDs =
      filteredSuppliers
        ?.filter((el) => el.is_favourite === 1)
        ?.map((el) => +el.supplier_id) ?? [];
         if(favSelected) {
    }
    setAllSelected(false);
    
      setSelectedSuppliers(favSelected ? [] : supplierIDs);

    setFavSelected(!favSelected);
  };

  const onEmailAdd = async() => {
    const email = emailRef.current.value;
    if (!email || !emailRef.current.validity.valid) {
      message.error(`${intl.formatMessage({ id:'invalid_email'})}`);
      return;
    }
    if (!emails.includes(email)) {
      setIsUploading(true);
      // await dispatch(sendEmailCommunication('INVITED_SUPPLIER', { "tender_id": tenderId,
      // "email_to": email}));
      axios
        .post(
          `${domainUrl}/tender/v1/tender/${tenderDetails?.tender_id}/supplier/invite`,
          { supplier_email: email },
          genHeaders()
        )
        .then(() => {
          setEmails([...emails, email]);
        })
        .catch((e) => {
          console.error(e);
          message.error("Unable to send invitation to supplier");
        })
        .finally(() => {
          setIsUploading(false);
        });
     }
    emailRef.current.value = "";
  };

  const onEmailRemove = (email, index) => {
    axios
      .delete(
        `${domainUrl}/tender/v1/tender/${
          tenderDetails?.tender_id
        }/supplier/invite?supplier_email=${encodeURIComponent(email)}`,
        genHeaders()
      )
      .then(() => {
        console.log("deleted invite");
        setEmails((c) => {
          const newEmails = c.filter((_, i) => i !== index);
          return newEmails;
        });
      })
      .catch((e) => {
        message.error("Unable to cancel invitation");
      });
  };

  const onBack = () => {
    move(-1, { suppliers: selectedSuppliers, invites: emails });
  };

  const onNext = async () => {
    setIsUploading(true);

    if(tenderDetails.is_published !== 1) {
      await modifySuppliers(selectedSuppliers);
      const status = await updateTenderData(
        {
          state: '4',
        }
      )
    if(status) {
      move(1, { suppliers: selectedSuppliers });
    }
    else {
      message.error("Unable to reach server.");
    }
  }
  else {
    await modifySuppliers(selectedSuppliers);
    const status = await updateTenderData(
      {
        state: '4',
      }
    )
    move(5, {
      suppliers: selectedSuppliers,
    },true);
  }
  setIsUploading(false);
  };

  const publishData = () => {
    const supplierNames = []
    selectedSuppliers?.map(el => {
      supplierNames.push(getNameById(el));
    })
    move(0, {
      suppliers: selectedSuppliers,
      supplierNames
    },true);
    modifyData();
  }

  return (
    <>
      <div className="__TenderCreate_ChooseSupplier__">
        <div className="hedding">
          <h2><FormattedMessage id="choose_suppliers" /> <ToolTip text_id="tender.choose_suppliers.info" placement="top" /></h2>
        </div>
        <div className="flexContainer">
          <div>
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id="filters" /></h3>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="service_type" /></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={serviceTypeRef}
                    name="service_type"
                    onChange={onFilterChange}
                    id="service_type"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {serviceTypes?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="by_country" /></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={countryRef}
                    name="country_name"
                    onChange={onFilterChange}
                    id="country_name"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {countries?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="by_city" /></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={cityRef}
                    name="city_name"
                    onChange={onFilterChange}
                    id="city_name"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {cities?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

             <div className="formGroup">
              <label className="leftCol"><FormattedMessage id="by_rating" /></label>
              <div className="selectDate" style={{backgroundColor: '#e6e9f4', padding: 5}}>
                  <div className="costumSelect">
                  <select
                    ref={ratingRef}
                    name="city_name"
                    onChange={onFilterChange}
                    id="city_name"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {[1,2,3,4,5]?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div className="selectionsection">
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id="supplier_for_project" /></h3>
            </div>
            <div className="cardBlock">
              <table className="tableGrid" width="100%" height="50%" border="0">
                <tbody>
                  <tr>
                    <th align="left" valign="top">
                    <FormattedMessage id="supplier_name" />
                    </th>
                    <th align="left" valign="top">
                    <FormattedMessage id="rating" />
                    </th>
                    <th align="left" valign="top">
                    <FormattedMessage id="select" />
                    </th>
                  </tr>
                  {filteredSuppliers?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                    {(el?.supplier_name!==null)?  <Avatar
                  // src={imageUrl}
                  style={{
                    backgroundColor: '#2B7776', marginRight: 5,
                  }}
                  icon={
                    // console.log("el.branding_logo",el.branding_logo)
                (el.branding_logo && el.branding_logo !==null)?
                  <img alt={"logo"} src={el.branding_logo}/>:<UserOutlined />}
                />:""}  {el?.supplier_name}
               {(el?.supplier_name!==null)?
                 <Popover content={el.company_description} title={intl.formatMessage({id:"Company Description"})} trigger="click">
                <InfoCircleOutlined className="infocircle"/>
                </Popover>
                :""}
                
                      </td>
                      <td align="left" valign="top">
                        <Rate allowHalf style={{fontSize: 14}} disabled value={el?.rating} />
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                        <Checkbox
                        id={`${el.business_id}${el.supplier_name}${index}`}
                        value={el.supplier_id}
                        checked={selectedSuppliers?.includes(+el.supplier_id)}
                        onChange={onSupplierSelect}
                      >
                      </Checkbox>
                          {/* <input
                            type="checkbox"
                            id={`${el.business_id}${el.supplier_name}${index}`}
                            value={el.supplier_id}
                            checked={
                              selectedSuppliers?.includes(+el.supplier_id)
                                ? "checked"
                                : null
                            }
                            onChange={onSupplierSelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.supplier_name}${index}`}
                          ></label> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {
                    filteredSuppliers.length === 0 && 
                    <tr>
                      <td colSpan={3}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <div className="selectionbuttons">
                <div className="btn" onClick={onSelectAll}>
                  {!allSelected ? <FormattedMessage id="select_all" /> : <FormattedMessage id="unselect_all" />}
                </div>
                <div className="btn" onClick={onSelectFav}>
                  {!favSelected ? <FormattedMessage id="select_fav" /> : <FormattedMessage id="unselect_fav" />}
                </div>
              </div>
            </div>
            <div className="invitationsection">
              <div className="formGroup">
                <h3 className="subheading"><FormattedMessage id="invite_supplier" /></h3>
              </div>
              <div className="invite">
                <input
                  ref={emailRef}
                  type="email"
                  placeholder={intl.formatMessage({id:"Enter Email"})}
                  className="inputFiled"
                />
                <div onClick={isUploading ? null : onEmailAdd} className="btn">
                <FormattedMessage id="add" />
                </div>
              </div>
              {emails?.map((email, index) => (
                <div className="invitecontainer" key={`${email}__${index}`}>
                  <p className="invitedsupplier">{email}</p>
                  <MinusCircleOutlined
                    onClick={() =>
                      isUploading ? null : onEmailRemove(email, index)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="buttonBlock">
          <div onClick={isUploading ? null : onBack} className="btn cancelBtn">
          <FormattedMessage id="bakc" />
          </div>
          {
              tenderDetails?.is_published === 1 && 
              <div className="btn" onClick={publishData}>
                <FormattedMessage id="publish" />
              </div>
            }
          <div onClick={isUploading ? null : onNext} className="btn">
          <FormattedMessage id="next" />
          </div>
        </div>
      </div>
    </>
  );
};

// Tender.propTypes = {};

export default ChooseSupplier;
