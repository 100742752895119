import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import {Spin, Card, Form, Input, Button, Radio, Row, Col, Divider, DatePicker, Space, Select } from 'antd';

import InspectionCategory from './InspectionCategory';
import FileUpload from '../FileUpload';
import FileDisplay from '../FileDisplay';

import { fetchMasterInspectionData, updateInspectionReports, createInspectionReportsLine, fetchAllInspectionReports, fetchAllInspectionRemarks } from "../../actions/inspection-report";
import { awsDocumentUrl, domainUrl } from "../../helper";
import { inspectionReportSummary } from "../../utils/constants";
import { FormattedMessage,useIntl } from "react-intl";
import { groupByKey } from "../../utils";
import { changeDateFormat } from '../../utils/common';
var htmlPdfClient = require("html-pdf-client")


const tailLayout = {
	wrapperCol: { offset: 10, span: 24 },
};
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const { Option } = Select;
const InspectionReportView = ({ reportDetails,fetchReport, inspectionReportId, finishReportCreation, isReadOnly, ref }) => {
	console.log(reportDetails,"====reportDetails")
	const history = useHistory();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [summaryAnswerOneOptions, setSummaryAnswerOneOptions] = useState([]);
	const [summaryAnswerTwoOptions, setSummaryAnswerTwoOptions] = useState([]);
	const [summaryAnswerThreeOptions, setSummaryAnswerThreeOptions] = useState([]);
	const [summaryAnswerFourOptions, setSummaryAnswerFourOptions] = useState([]);
	const [summaryAnswerOne, setSummaryAnswerOne] = useState((reportDetails?.summary_answer_one != null) ? [{label:reportDetails?.summary_answer_one}] : []);
	const [summaryAnswerTwo, setSummaryAnswerTwo] = useState((reportDetails?.summary_answer_two != null) ? [{label:reportDetails?.summary_answer_two}] : []);
	const [summaryAnswerThree, setSummaryAnswerThree] = useState((reportDetails?.summary_answer_three != null) ? [{label:reportDetails?.summary_answer_three}] : []);
	const [summaryAnswerFour, setSummaryAnswerFour] = useState((reportDetails?.summary_answer_four != null) ? [{label:reportDetails?.summary_answer_four}] : []);
	const [inspectionMaster, setInspectionMaster] = useState([]);
	const [remarksOptions, setRemarksOptions] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [assetName, setAssetName] = useState(reportDetails?.asset_name);
	const [inspectionReports, setInspectionReports] = useState([]);
    const intl=useIntl()  
	const dispatch = useDispatch();
	const [printFontSize] = useState("fs-small");
	
	const fetchInspectionReports = async (/*values={}*/) => {
		setIsLoading(true);
		let params = {};
		params.limit = 100;
		params.asset_name = assetName;
		const response = await dispatch(fetchAllInspectionReports(params));
		const reportData = Object.values(response.payload.entities?.inspections || []);
		setIsLoading(false);
		setInspectionReports(segKeyData('inspection_name','inspection_id',reportData));
	};
	useEffect(() => {
		const getMasterReport = async() => {
		  const inspection = await dispatch(fetchMasterInspectionData({limit: 1000}));
		  setInspectionMaster( Object.values(inspection?.payload?.entities?.masterinspections || {}));
		}
		if(!isReadOnly){
			getMasterReport();
			fetchInspectionReports();
		}
	}, [dispatch]);
	
	const addFileList = (files) => {
		setFileList({
			...fileList,
			...files
		})
	}

	const onFinish = async (values) => {
		setIsLoading(true)
		console.log(inspectionReportId,"=====inspectionReportId");
		console.log(localStorage.getItem('reportId'));
		// setIsLoading(true);
		let reportData= {
			inspection_name: values.inspection_name,
			admin_description: values.admin_description,
			inspected_by: values.inspected_by,
			inspection_date: moment(values.inspection_date).format('YYYY-MM-DD'),
			answer_one: values.answer_one,
			answer_two: values.answer_two,
			total_year_one: values.total_year_one?.toString(),
			total_year_two: values.total_year_two?.toString(),
			total_year_three: values.total_year_three?.toString(),
			total_year_four: values.total_year_four?.toString(),
			total_year_five: values.total_year_five?.toString(),
			total_year_six: values.total_year_six?.toString(),
			// total_year_one: parseInt(values.total_year_one),
			// total_year_two: parseInt(values.total_year_two),
			// total_year_three: parseInt(values.total_year_three),
			// total_year_four: parseInt(values.total_year_four),
			summary_answer_one: values.summary_answer_one?.toString(),
			summary_answer_two: values.summary_answer_two?.toString(),
			summary_answer_three: values.summary_answer_three?.toString(),
			summary_answer_four: values.summary_answer_four?.toString(),
		}
		// console.log(reportData,"======reportData")
		// return false;

		const reportResponse = await dispatch(updateInspectionReports(reportData, inspectionReportId));
		let reportLines = [];
		values.lineItems.map((item,index) => {
			let obj = {};
			if(item) {
				obj.inspection_item_id = parseInt(Object.keys(values.lineItems)[index]);
				if(item.inspection_amc_cost) {
					obj.inspection_amc_id = parseInt(item.inspection_amc_cost[2]);
					obj.inspection_item_value = parseInt(item.inspection_item_value) || item.inspection_amc_cost[1];
					obj.inspection_remarks = item?.inspection_remarks?.length ? item?.inspection_remarks[0] : '';
					reportLines.push(obj);
				}
			}
		});
		for(let i=0; i<reportLines.length; i++) {
			const reportLinesResponse = await dispatch(createInspectionReportsLine(reportLines[i], inspectionReportId));
		}

		setIsLoading(false);
		finishReportCreation(reportResponse.payload?.result[0] === inspectionReportId || false);

	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const onChange = (date, dateString) => {
	}

	const total_cost = [
		{ 'total_year_one': 0 },
		{ 'total_year_two': 0 },
		{ 'total_year_three': 0 },
		{ 'total_year_four': 0 },
		{ 'total_year_five': 0 },
		{ 'total_year_six': 0 }
	]

	const calculateCost = (arrayValue, lineItemId, updateItemValue) => {
		console.log(arrayValue,'<br>' ,lineItemId,'<br>', updateItemValue,"=====arrayValue, lineItemId, updateItemValue")
		let index = parseInt(arrayValue[0]) - 1;
		if(updateItemValue) {
			const fields = form.getFieldsValue();
			fields.lineItems[lineItemId].inspection_item_value = parseInt(arrayValue[1]);
			form.setFieldsValue(fields);
		}
		if(index < 4) {
			let key = Object.keys(total_cost[index]);
			let cost_key = form.getFieldValue(['lineItems',lineItemId,'inspection_item_value']);
			let value = parseInt(form.getFieldValue(key))+parseInt(cost_key);
			form.setFieldsValue({ [key]: value });
		}
		// console.log(form.isFieldTouched(['lineItems',lineItemId,'inspection_amc_cost']),"======form")
	}

	const setInitValues = () => {
		total_cost.map(cost => {
				let key = Object.keys(cost)[0];
				form.setFieldsValue({ [key]: 0 });
					
			})
	}

	const onFieldsChange = (changedFields, allFields) => {
		console.log(changedFields,"======changedFields");
		console.log(allFields,"====== allFields");
		let fieldEdited = changedFields[0];
		if(changedFields[0].name?.length>1 && ((changedFields[0].name[2] === 'inspection_amc_cost'  && changedFields[0].value?.length === 3) || changedFields[0].name[2] === 'inspection_item_value' )) {
			setInitValues();
			allFields.map(changedFields => {
				if(changedFields.name?.length>1 && changedFields.name[2] === 'inspection_amc_cost' && changedFields.value?.length === 3) {
					calculateCost(changedFields.value, changedFields.name[1], changedFields.name[1] === fieldEdited.name[1] && fieldEdited.name[2] === 'inspection_amc_cost' ? true : false);
				}
			})
		}
		
	}
	const segKeyData = (key,valueKey, data) => {
		let keyArray = data.map(obj => { return obj[key] ? {label: obj[key], value: String(obj[valueKey])}:{label: '', value: ''}});
		
		return keyArray.filter((value, index, self) => self.map(x => x?.value).indexOf(value?.value) == index);
	}
	const handleSearch = async (value, key) => {
		if(value) {
			let params = {};
			params.limit = 100;
			params[key]=value;
    		const response = await dispatch(fetchAllInspectionReports(params));
			const options = Object.values(response.payload.entities?.inspections || []);
			
			let options1 = [...new Map(options.map(item => [item[key], item])).values()];
			if(key == 'summary_answer_one'){
				setSummaryAnswerOneOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key == 'summary_answer_two'){
				setSummaryAnswerTwoOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key == 'summary_answer_three'){
				setSummaryAnswerThreeOptions(segKeyData(key,'inspection_id',options1));
			}
			if(key == 'summary_answer_four'){
				setSummaryAnswerFourOptions(segKeyData(key,'inspection_id',options1));
			}
		}
		else {
			if(key == 'summary_answer_one'){
				setSummaryAnswerOneOptions([]);
			}
			if(key == 'summary_answer_two'){
				setSummaryAnswerTwoOptions([]);
			}
			if(key == 'summary_answer_three'){
				setSummaryAnswerThreeOptions([]);
			}
			if(key == 'summary_answer_four'){
				setSummaryAnswerFourOptions([]);
			}
		}
	}

	const handleRemarksSearch = async (value, key) => {
		if(value) {
			let params = {};
			params.limit = 100;
			params[key]=value;
    		const response = await dispatch(fetchAllInspectionRemarks(params));
			const options = Object.values(response.payload.entities?.inspections || []);
			let options1 = [...new Map(options.map(item => [item[key], item])).values()];
			setRemarksOptions(segKeyData(key,'inspection_remarks',options1))
		}
	}

	const Print = async() =>{
		setIsLoading(true)
		var element = document.getElementById('printablediv');
        var pdf_header = document.getElementsByClassName("pdf_header");
        for (let i = 0; i < pdf_header.length; i++) {
            pdf_header[i].style.display = '';
        }
        var opt = {
            // margin:        0.1,
            margin:        [0.3, 0.1, 0.1, 0.1],
            filename:     'TenderIn '+ reportDetails?.inspection_name.trim() + '.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2,},
            jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait',compressPDF: true },
            pagebreak:    { after: '.page-break' }
        };
        // await htmlPdfClient().set(opt).from(element).toPdf().save();
        await htmlPdfClient().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {

			var totalPages = pdf?.internal.getNumberOfPages();

			//print current pdf width & height to console

			console.log("getHeight:" + pdf.internal.pageSize.getHeight());

			console.log("getWidth:" + pdf.internal.pageSize.getWidth());

			for (var i = 1; i <= totalPages; i++) {

				pdf.setPage(i);

			  //divided by 2 to go center

				pdf.setFont("times", "normal");

				// pdf.setFontSize(8);

				// pdf.text('01680 Vantaa', 1, 0.5, null, null, "left");      // Header Left

				// pdf.text('\n', (pdf.internal.pageSize.getWidth()/2)-1, 0.5, null, null, "center");      //Header Center

				// pdf.text('Y-tunnus 2681713000\n', (pdf.internal.pageSize.getWidth())-1, 0.5, null, null, "right");      //Header Right
				// var img = new Image()
				// img.src = '../../assets/hissitaito-logo-1@3x.png'
				// img.onload = () => {
				// 	// await for the image to be fully loaded
				// 	// doc.addImage(img,'png',...);
				// 	// doc.text("Sample", 20, 15);
				// 	// //...
				// 	// doc.save('order.pdf');
				// 	pdf.addImage(img, 'png', 10, 78, 12, 15)
				// };
			   
			   

				pdf.setFontSize(6);

				pdf.text('Hissitaito Oy\nKalliotie 11\n01680 Vantaa', 1, pdf.internal.pageSize.getHeight() - 0.55, null, null, "left"); //Bottom Left

				pdf.text('Janne Oja | 0400 600 802 | janne@hissitaiti.fi\n', (pdf.internal.pageSize.getWidth()/2), pdf.internal.pageSize.getHeight() - 0.5, null, null, "center"); // Bottom Center

				pdf.text('www.hissitaiti.fi\nY-tunnus 2681713000', (pdf.internal.pageSize.getWidth()-1), pdf.internal.pageSize.getHeight() - 0.5, null, null, "right"); // Bottom Right

			}

		}).save()
        for (let i = 0; i < pdf_header.length; i++) {
            pdf_header[i].style.display = 'none';
        }
        // for (let i = 0; i < pdfFooter.length; i++) {
        //     pdfFooter[i].style.display = 'none';
        // }
		setIsLoading(false)
	}
	useEffect(()=>{
		if(reportDetails?.inspection_name){
			Object.keys(reportDetails)?.map((key)=>{
				if(key == 'inspection_date'){
					delete reportDetails[key];
				}
				form.setFieldsValue({[key]: reportDetails[key]});
			})
		}
		setIsLoading(false)
	},[reportDetails])
	console.log(form.getFieldsValue(),"====form")
	return (
		<>
		<Spin spinning={isLoading}>
			<Card title="" id='printablediv' ref={ref} className="card-layout inpection-report-card" style={{marginBottom: '0px !important'}}>
				<Form
					form={form}
					name="basic"
					labelAlign='left'
					initialValues={reportDetails}
					// initialValuesEqual={() => true}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					onFieldsChange={onFieldsChange}
				>
					<div className={`page-break ${printFontSize}`}>
                        <Row style={{justifyContent: 'end', display: 'none', marginTop: '0px'}} className="pdf_header">
                            <div className="pdf-header"></div>
                        </Row>

						<Row gutter={30}>
							<Col md={24} lg={6} xl={11}  span={8}>
								<h2 style={{fontWeight: 'bolder'}} className={printFontSize}>{reportDetails?.inspection_name}</h2>
							</Col>
						</Row>
						<Row gutter={30} style={{display: 'flex'}}>
							<Col md={6} lg={6} xl={8} style={{border: '1px solid grey', borderRight: 'none'}}>
								<h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="Housing Company"/></h4>
                                <h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="Housing Company To Be Inspected"/>:</h4>
								<p>{reportDetails?.company_name}<br></br>{reportDetails?.address}</p>
							</Col>
							<Col md={6} lg={6} xl={8}  style={{border: '1px solid grey', borderRight: 'none'}}>
								<h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="property_manager"/></h4>
                                <h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="label_name"/>:</h4>
								<p>{reportDetails?.buyer_name}</p>
								<h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="address"/>:</h4>
								<p>{reportDetails?.buyer_address}</p>
							</Col>
							<Col md={6} lg={6} xl={8}  style={{border: '1px solid grey'}}>
								<h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="Evaluator"/>:</h4>
                                <h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="Creator"/>:</h4>
								<p>{reportDetails?.inspected_by}</p>
								<h4 className={` ${printFontSize}`} style={{fontWeight: 'bolder'}}><FormattedMessage id="inspection_date"/>:</h4>
								<p>{changeDateFormat(moment(reportDetails?.inspection_date).format('YYYY-MM-DD'),intl?.locale)}{}</p>
							</Col>
						</Row>
						{/* <Divider /> */}
						<Row gutter={30} style={{marginTop: '10px'}}>
                            <Col span={24}>
								<h3 style={{fontWeight: 'bolder'}} className={printFontSize}>{reportDetails?.asset_name}</h3>
							</Col>
                        </Row>
						<Row gutter={30} style={{border: '1px solid grey', borderBottom: 'none' , borderRight: 'none'}}>
							{
								reportDetails?.asset_properties?.map((prop, index) => (
									<Col md={8} lg={8} xl={8} key={index} style={{borderBottom: '1px solid grey', borderRight: '1px solid grey'}}>
                                        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id={prop.property_name}/>:</h4>
                                        <p>{prop.property_value}</p>
									</Col>
								))
							}

						</Row>
						<Divider />
						<div className={`report_corrections ${printFontSize}`}>

							<h3 style={{fontWeight: 'bolder'}} className={printFontSize}><FormattedMessage id="FUTURE CORRECTIONS"/></h3>
							<h4 style={{fontWeight: 'bolder'}} className={printFontSize}><FormattedMessage id="Fitness rating"/>:</h4>
							<Row>
								<Col md={12} lg={12} xl={12}>
									1. <FormattedMessage id="To be repaired immediately"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									2. <FormattedMessage id="To be repaired within 1-2 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									3. <FormattedMessage id="To be repaired within 2-3 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									4. <FormattedMessage id="To be repaired within 3-4 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									5. <FormattedMessage id="To be repaired within 4-5 years"/>
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} xl={12}>
									OK. <FormattedMessage id="All OK, No cost within 5 years"/>
								</Col>
							</Row>
						</div>
                        {/* <div className="pdf-footer" style={{display: 'none',position:'absolute', marginLeft: '0px'}}></div> */}
					</div>

					{
						isReadOnly && reportDetails?.inspection_lines.map((category,index) => (
							<>
								<div className={`page-break ${printFontSize}`} style={{marginBottom: '25px'}}>
                                    <Row style={{justifyContent: 'end', display: 'none'}} className="pdf_header">
                                        <div className="pdf-header"></div>
                                    </Row>
									{/* {index == 0 &&
										<div className="report_corrections">

											<h3 style={{fontWeight: 'bolder'}}><FormattedMessage id="FUTURE CORRECTIONS"/></h3>
											<h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Fitness rating"/>:</h4>
											<Row>
												<Col span={1}>
													1
												</Col>
												<Col>
													- <FormattedMessage id="To be repaired immediately"/>
												</Col>
											</Row>
											<Row>
												<Col span={1}>
													2
												</Col>
												<Col>
													- <FormattedMessage id="To be repaired within 1-2 years"/>
												</Col>
											</Row>
											<Row>
												<Col span={1}>
													3
												</Col>
												<Col>
													- <FormattedMessage id="To be repaired within 2-3 years"/>
												</Col>
											</Row>
											<Row>
												<Col span={1}>
													4
												</Col>
												<Col>
													- <FormattedMessage id="To be repaired within 3-4 years"/>
												</Col>
											</Row>
											<Row>
												<Col span={1}>
													5
												</Col>
												<Col>
													- <FormattedMessage id="To be repaired within 4-5 years"/>
												</Col>
											</Row>
											<Row>
												<Col span={1}>
													OK
												</Col>
												<Col>
													- <FormattedMessage id="All OK, No cost within 5 years"/>
												</Col>
											</Row>
										</div>
									} */}
									<InspectionCategory categoryData={category} isReadOnly={isReadOnly} indexKey={index}/>
                                    {/* <div className="pdf-footer" style={{display: 'none',position:'absolute', marginLeft: '0px'}}></div> */}
								</div>
							</>
						))
					}
					<div className={`page-break ${printFontSize}`}>
                        <Row style={{justifyContent: 'end', display: 'none'}} className="pdf_header">
                            <div className="pdf-header"></div>
                        </Row>
						<Row  className={printFontSize}>
							<Col span={24}>
							    <h4  style={{fontWeight: 'bolder'}}><FormattedMessage id="FAULT FREQUENCY ACCORDING TO SERVICE JOURNAL"/></h4>
							</Col>
							<Form.Item label=""
									name="answer_one">
								<Radio.Group name='ansGroup1' disabled={isReadOnly} >
								<Space direction="vertical">
									<Radio value="1" className={printFontSize}><FormattedMessage id="Commendable, 0-1 bug last year"/></Radio>
									<Radio value="2" className={printFontSize}><FormattedMessage id="Good, 2-3 failures in the last year"/></Radio>
									<Radio value="3" className={printFontSize}><FormattedMessage id="Satisfactory, 4-5 failures in the last year"/></Radio>
									<Radio value="4" className={printFontSize}><FormattedMessage id="Bad, more than 5 failures in the last year"/></Radio>
									</Space>
							</Radio.Group>
							</Form.Item>
						</Row>

						<Row>
							<Col span={24}>
								<h4 style={{fontWeight: 'bolder'}} className={printFontSize}><FormattedMessage id="SPARE PARTS AVAILABILITY"/></h4>
							</Col>
							<Form.Item label="" name="answer_two">
								<Radio.Group name='ansGroup2' disabled={isReadOnly} >
									<Space direction="vertical">
										<Radio value="1" className={printFontSize}><FormattedMessage id="Spare parts are well available."/></Radio>
										<Radio value="2" className={printFontSize}><FormattedMessage id="The availability of spare parts may decline in 5 years."/>	</Radio>
										<Radio value="3" className={printFontSize}><FormattedMessage id="All parts are no longer available as new, some possible repairs are based on demolition parts."/></Radio>
										<Radio value="4" className={printFontSize}><FormattedMessage id="There are hardly any new spare parts available."/></Radio>
									</Space>
								</Radio.Group>
							</Form.Item>
						</Row>
						{/* <Divider /> */}
                        <div className={printFontSize}>
                            <h4 style={{fontWeight: 'bolder'}} className={printFontSize}><FormattedMessage id="FUTURE COSTS"/></h4>
                            <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Condition rating"/>:</h4>
                            	<Row>			
									<Col  md={8} lg={8} xl={8}>
										<p>1. <FormattedMessage id="To be repaired immediately"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_one}</p>
									</Col>
								</Row>
								<Row>			
									<Col  md={8} lg={8} xl={8}>
										<p>2. <FormattedMessage id="To be repaired within 1-2 years"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_two}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={8} lg={8} xl={8}>
										<p>3. <FormattedMessage id="To be repaired within 2-3 years"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_three}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={8} lg={8} xl={8}>
										<p>4. <FormattedMessage id="To be repaired within 3-4 years"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_four}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={8} lg={8} xl={8}>
										<p>5. <FormattedMessage id="To be repaired within 4-5 years"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_five}</p>
									</Col>
								</Row>
								<Row>
									<Col  md={8} lg={8} xl={8}>
										<p>Ok. <FormattedMessage id="All OK, No cost within 5 years"/></p>
									</Col>
									<Col  md={16} lg={16} xl={16}>
										<p>{reportDetails?.total_year_six}</p>
									</Col>
								</Row>
                        </div>
						{/* <Divider /> */}
						<div  className={printFontSize}>
							<h3 style={{fontWeight: 'bolder'}}  className={printFontSize}><FormattedMessage id="summary"/></h3>
							<Row>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Compliance with regulations and using the elevator"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_one}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Defects and costs according to the maintenance book"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_two}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Availability of spare parts"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_three}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Actions to be taken"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_four}</p>
								</Col>
								<Col span={24} style={{}}>
								</Col>
							</Row>
						</div>
                        {/* <div className="pdf-footer" style={{display: 'none',position:'absolute', marginLeft: '0px'}}></div> */}
					</div>
					{/* <div className={`page-break ${printFontSize}`}>
						<div  className={printFontSize}>
							<h3 style={{fontWeight: 'bolder'}}  className={printFontSize}><FormattedMessage id="summary"/></h3>
							<Row>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Compliance with regulations and using the elevator"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_one}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Defects and costs according to the maintenance book"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_two}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Availability of spare parts"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_three}</p>
								</Col>
								<Col span={24}>
							        <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Actions to be taken"/>:</h4>
									<p style={{marginLeft: '25px'}}>{reportDetails?.summary_answer_four}</p>
								</Col>
								<Col span={24} style={{}}>
								</Col>
							</Row>
						</div>
					</div> */}
					<div className={printFontSize}>
						<FileDisplay hideTitle={true} entityType={'IR'} entityId={inspectionReportId} isReadOnly={isReadOnly}/>
					</div>
					<div className={printFontSize}>
                        <Row style={{justifyContent: 'end', display: 'none'}} className="pdf_header">
                            <div className="pdf-header"></div>
                        </Row>
                        <Row >
                            <Col span={24}>
                                <h4 style={{fontWeight: 'bolder'}}><FormattedMessage id="Hissitaito Oy"/></h4>
                                <p>{reportDetails?.admin_description}</p>
                            </Col>
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Col span={24}>
                                <strong><FormattedMessage id="Respectfully"/></strong><br/>
                                <strong><FormattedMessage id="Janne Oja"/></strong><br/>
                                <strong><FormattedMessage id="Hissitaito Oy"/></strong><br/>
                            </Col>
                        </Row>
                        {/* <div className="pdf-footer" style={{display: 'none'}}></div> */}
					</div>
					
				</Form>

			</Card>
			{isReadOnly &&
				<Row className="" style={{display:'flex','justify-content':'center'}}>
					<Col style={{display:'flex'}}>
						<Button id="pdf-button" htmlType="button" onClick={Print} style={{ background: "#2B7776 ", color: "#ffffff" }}>
							<FormattedMessage id="GENERATE PDF"/>
						</Button>
						<Button type="lightdark" htmlType="button" onClick={()=>{history.push(`/`);}} style={{marginLeft:'5px'}}>
							<FormattedMessage id="close"/>
						</Button>
					</Col>
				</Row>
			}
			</Spin>
		</>
	);
};

InspectionReportView.propTypes = {
	reportDetails: PropTypes.shape({}),
};

export default React.forwardRef(InspectionReportView);