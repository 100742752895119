import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { message } from "antd";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../../components/Header";
import SupplierNewRequestList from '../../components/SupplierNewRequestList';
import SupplierList from "../../components/SupplierManageList";
import SupplierDetails from '../../components/SupplierDetails';
import SupplierRatingList from '../../components/SupplierRatingList';
import MessageToast from '../../components/MessageToast';

import {
    fetchSuppliers,
    createSupplier,
    updateSupplier,
    deleteSupplier,
    deactiveSupplier
} from "../../actions/suppliers";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        margin: '0 auto',
    },

}));

const tabTheme = createMuiTheme({
    overrides: {
        MuiTabs: {
            flexContainer: {
                justifyContent: 'center'
            }
        }
    },
});

const SupplierManagement = () => {
  const intl = useIntl();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false);

    const [openSupplierFormModel, setOpenSupplierFormModel] = useState({
      isOpen: false,
      isReadOnly: false,
      allowRating: false,
    });

    const [supplier, setSupplier] = useState({});

    const [showFeedback, setShowFeedback] = useState({
        open: false,
        success: false,
        error: false,
        info: false,
        message: '',
    });

    const supplierManageListRef = useRef();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const setFeedBack = (data) => {
        setShowFeedback(data);
    }

    const closeFeedback = () => {
        setShowFeedback({
            open: false,
            success: false,
            error: false,
            info: false,
            message: '',
        })
    }

    const openSupplierForm = (data, opts) => {
      setOpenSupplierFormModel({ isOpen: true, isReadOnly: opts?.readOnly, allowRating: opts?.allowRating });
      setSupplier(data);
    };

    const closeSupplierForm = () => {
      setOpenSupplierFormModel({ isOpen: false });
      setSupplier({});
    };

    const addSupplier = async (data, selectedServices) => {
      let supplierId = supplier.supplier_id;
      delete data.supplier_id;
      let supplierResponse;
      if (supplierId)
        supplierResponse = await dispatch(updateSupplier(supplierId, data));
      else supplierResponse = await dispatch(createSupplier(data));
      closeSupplierForm();
      window.scroll(0,0);
      if(supplierResponse?.error)
        message.error("Could not create supplier.");
      else
        message.success(supplierId ? intl.formatMessage({id: 'supplier_updated'}) : intl.formatMessage({id: 'supplier_add_msg'}));
      supplierManageListRef.current.manageSuppliers();
    }

    const deleteSupplier = async (data) => {
      closeSupplierForm();
      supplierManageListRef.current.deleteSupplier(data);
    }
    const deactiveSupplier = async (data) => {
     
      closeSupplierForm();
      supplierManageListRef.current.deactiveSupplier(data);
    }

    return ( 
      <>
        <Header /> 
        <div className = "mainContainer" >
        <div className="innerContainer">
        {
          showFeedback.open &&
           <MessageToast open={showFeedback.open} success={showFeedback.success} error={showFeedback.error} message={showFeedback.message} onClose={closeFeedback}/>
        }
             <div className={classes.root}>
      <AppBar position="static" color="default">
       <MuiThemeProvider theme={tabTheme}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab label={<FormattedMessage id={'new_request'} />} {...a11yProps(0)} />
          <Tab label={<FormattedMessage id={'manage'} />} {...a11yProps(1)} />
          <Tab label={<FormattedMessage id={'supplier_comments'} />} {...a11yProps(2)} />
        </Tabs>
        </MuiThemeProvider>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       <SupplierNewRequestList value={value} index={0} dir={theme.direction} showFeedBack = {setFeedBack} viewSupplierDetails = { openSupplierForm }/>
       <SupplierList loading={loading} setLoading={setLoading} ref={supplierManageListRef} value={value} index={1} dir={theme.direction} showFeedBack = {setFeedBack} viewSupplierDetails = { openSupplierForm }/>
       <SupplierRatingList value={value} index={2} dir={theme.direction}/>
      </SwipeableViews>
    </div>
        </div> 
        </div> 
        {openSupplierFormModel?.isOpen && (
          <SupplierDetails
            readOnly={openSupplierFormModel?.isReadOnly}
            isOpen={openSupplierFormModel?.isOpen}
            data={supplier}
            onClose={closeSupplierForm}
            onSubmit={addSupplier}
            onDelete={deleteSupplier}
            onDeactive={deactiveSupplier}
            allowRating={openSupplierFormModel?.allowRating}
          />
        )}
        </>
    );
};
export default SupplierManagement;
