import { Table, Card, Spin, message,Badge, Typography } from "antd";
import { DownOutlined } from '@ant-design/icons';
import axios from "axios";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { genHeaders } from "../../../components/Tender/Tender";
import { domainUrl } from "../../../helper";
import "./Compare.less";
import { sendEmailCommunication } from "../../../actions/communication";
import { FormattedMessage, useIntl } from "react-intl";


const CompareBids = () => {
  const dispatch = useDispatch();
  const { tenderId } = useParams();
  const [dataSource, setDataSource] = useState();
  const [bidInfo, setBidInfo] = useState();
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersTotal, setSuppliersTotal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const { Text } = Typography;
  const intl = useIntl();

  const userId = sessionStorage.getItem('user_id');
	const role_id = useSelector(state => get(state, `entities.users.${userId}.roles.role_id`));

  const columns = useMemo(() => {
    const supplierCols = [];
    if (suppliers?.length) {
      suppliers?.forEach(async (supplier) => {
        supplierCols.push({
          title: supplier.supplier_name,
          dataIndex: supplier.supplier_id,
          key: supplier.supplier_id,
          width: 150,
          render: (amount) => {
              return (
                amount ? (
                    <div>{amount ? amount : '-'}&nbsp;&euro;</div>
                 ) : ( <div>-</div>)
                );
           },
        });
      });
    }
    return [
      {
        title: <FormattedMessage id={'assets'}/>,
        dataIndex: "assetName",
        key: "assetName",
        fixed: "left",
        width: 150,
      },
      
      ...supplierCols,
    ];
  }, [bidInfo]);

   const expandedRowRender = (rowData) => {
     const supplierCols = [];
    if (suppliers?.length) {
      suppliers?.forEach((supplier) => {
        supplierCols.push({
          title: supplier,
          dataIndex: supplier,
          key: supplier,
          width: 150,
          render: (amount) => {
              return (
                amount ? (
                    <div>{amount ? amount : '-'}&nbsp;&euro;</div>
                 ) : ( <div>-</div>)
                );
           },
        });
      });
    }
    
    const columns = [

      {
        title: "itemName",
        dataIndex: "itemName",
        key: "itemName",
        fixed: "left",
        width: 150,
      },
      
      ...supplierCols,
    ];

    const data = [];
   
    return <Table className="bid-compare-inner" bordered /* scroll={{ x: 'auto', y: '50vh' }} */ showHeader={false} columns={columns} dataSource={rowData.items} pagination={false} locale={{emptyText: intl.formatMessage({ id:'no_data'})}} />;
  };

const createDisplayFormat = (data) => {
    let dataSource = [];
    let bidObj;
    data && data.map(bid => {
      
      bid.lines && bid.lines.map(asset => {
        let assetCreated = dataSource.find(dataAsset => dataAsset.assetId === asset.tender_line_id)
        bidObj= assetCreated ? assetCreated : {};
        bidObj.assetName = asset.line_text;
        bidObj.assetId = asset.tender_line_id;
        bidObj[bid.supplier_id] = asset.line_amount;
        bidObj.items = assetCreated && assetCreated.items ? assetCreated.items : [];
        asset.items.map(item => {
          let obj;
          let itemCreated = bidObj.items.find(dataItem => dataItem.itemId === item.tender_line_item_id)
        
          obj = itemCreated ?  itemCreated : {};
          obj.itemName = item.item_text;
          obj.itemId = item.tender_line_item_id;
          obj[bid.supplier_name] = item.amount;
          !itemCreated && bidObj.items.push(obj);
        })
      !assetCreated && dataSource.push(bidObj);
      })
    });
    setDataSource(dataSource);
  }

    const onRecommend = async() => {
      setIsLoading(true);
    const comments = formRef.current?.elements["comments"]?.value;
    const recommended_supplier =
      formRef.current?.elements["supplier_id"]?.value;

    if (!recommended_supplier){
      setIsLoading(false);
      return;
    } 

    axios
      .put(
        `${domainUrl}/tender/v1/tender/${tenderId}`,
        {
          //comments,
          recommended_supplier: +recommended_supplier,
        },
        genHeaders()
      )
      .then(async() => {
        await dispatch(sendEmailCommunication(+role_id === 1 ? 'ADMIN_SUPPLIER_RECOMMENDATION' : 'PM_SUPPLIER_RECOMMENDATION', {tender_id: +tenderId, comments: (!comments || comments === "") ? 'N/A' : comments}));
        setIsLoading(false);
        message.success(intl.formatMessage({ id:"Sent recommendation."}));
      })
      .catch(() => {
        message.error("Was not able to send recommendation.");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        
        const bidRes = await axios.get(
          `${domainUrl}/bid/v1/tender/${tenderId}/bid`,
          genHeaders()
        );


        const bids = bidRes.data.data.result;
        const supplierTotalData = bids.map(bid => {
          let obj = {
            supplier_name:bid.supplier_name, 
            sub_total:bid.sub_total
          }
          return obj;
        });
        setSuppliersTotal(supplierTotalData);
        const supplierData = bids.map(bid => {
          const obj = {
            supplier_name: bid.supplier_name,
            supplier_id: bid.supplier_id,
          }
          return obj;
        
        });
       
        setSuppliers(supplierData);
        
        createDisplayFormat(bids);
        setBidInfo(bids);
        
      } catch (e) {
        console.error(e);
      }
    };

    getData();
  }, []);

  if (!bidInfo) {
    return <Spin className="__Compare__Spinner" />;
  }

  

  return (
    <>
      {bidInfo && (
        <Spin spinning={isLoading}>
          <h2 className="title"><FormattedMessage id={'title'}/></h2>
          <Table
            className="bid-compare-root"
            columns={columns}
            // scroll={{ x: 'auto', y: '50vh' }}
            dataSource={dataSource}
            pagination={false}
            rowExpandable={record => record.items.length > 0}  
            expandable={{ expandedRowRender }}
            locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            summary={pageData => {
            

              return (
                <>
                  <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell><b><FormattedMessage id={'total'}/></b></Table.Summary.Cell>
                    {
                      suppliersTotal.map(total => (
                        <Table.Summary.Cell>
                          <Text type="danger">{total?.sub_total || '-'}&nbsp;&euro;</Text>
                        </Table.Summary.Cell>
                      ))
                    }
                    
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <Card className="__CompareBids__">
            <form ref={formRef}>
              <div className="formGroup">
                <label className="leftCol"><FormattedMessage id={'recommended_supplier'}/></label>
                <div className="selectDate">
                  <div className="costumSelect">
                    <select id="supplier_id" className="inputFiled">
                      <option value="">{intl.formatMessage({id: 'please_select_a_supplier'})}</option>
                      {bidInfo?.map((el, index) => (
                        <option key={`${el}${index}`} value={el.supplier_id}>
                          {el.supplier_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <label className="leftCol"><FormattedMessage id={'comments'}/></label>
               <textarea className="comments" id="comments"></textarea>
              </div>
            </form>  
          </Card>
           <div className="btn compare_btn" onClick={onRecommend}>
              {+role_id === 1 ? <FormattedMessage id={'recommended_supplier_to_property_manager'}/> : <FormattedMessage id={'recommended_supplier_to_admin'}/>}
            </div>
          </Spin>
        )}
    </>
  );
};

export default CompareBids;
