import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import AdminDashboard from "../../components/AdminDashboard/Dashboard";
import PropertyManagerDashboard from '../../components/PropertyManagerDashboard/Dashboard';

import SupplierDashboard from "../../components/SupplierDashboard/SupplierDashboard";

const Dashboard = () => {
	let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
	return (
		<>
			{
				role_id == 1 && 
					<AdminDashboard />
			}
			{
				role_id == 2 && 
					<PropertyManagerDashboard />
			}
			{
				role_id == 3 && 
					<SupplierDashboard />
			}
		</>
	)
};


export default Dashboard;