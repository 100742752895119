import React from 'react';
import { useDispatch } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../../components/Header";
import OrganizationNewRequestList from '../../components/OrganizationNewRequestList';
import Organization from "../../components/Organization";


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        margin: '0 auto',
    },

}));

const tabTheme = createMuiTheme({
    overrides: {
        MuiTabs: {
            flexContainer: {
                justifyContent: 'center'
            }
        }
    },
});

const OrganizationManagement = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeIndex = (index) => {
      setValue(index);
    };
    return ( 
      <>
        <Header /> 
        <div className = "mainContainer" >
        <div className="innerContainer">
       
             <div className={classes.root}>
      <AppBar position="static" color="default">
       <MuiThemeProvider theme={tabTheme}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab label={<FormattedMessage id={'new_request'} />} {...a11yProps(0)} />
          <Tab label={<FormattedMessage id={'organization'} />} {...a11yProps(1)} />
        </Tabs>
        </MuiThemeProvider>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       <OrganizationNewRequestList value={value} index={0} dir={theme.direction} />
       <Organization value={value} index={1} dir={theme.direction} />
      </SwipeableViews>
    </div>
        </div> 
        </div> 
        </>
    );
};
export default OrganizationManagement;
