import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import { handleResponse, handleError, handleQuery, handleDataSuccessResponse, handleResponseNew } from "../utils";
import { domainUrl } from "../helper";

export const FETCH_ORGS_REQUEST = "FETCH_ORGS_REQUEST";
export const FETCH_ORGS_SUCCESS = "FETCH_ORGS_SUCCESS";
export const FETCH_ORGS_FAILURE = "FETCH_ORGS_FAILURE";

export const CREATE_NEW_ORGS_REQUEST="CREATE_NEW_ORGS_REQUEST"
export const CREATE_NEW_ORGS_SUCCESS="CREATE_NEW_ORGS_SUCCESS"
export const CREATE_NEW_ORGS_FAILURE="CREATE_NEW_ORGS_FAILURE"

export const FETCH_ORG_REQUEST = "FETCH_ORG_REQUEST";
export const FETCH_ORG_SUCCESS = "FETCH_ORG_SUCCESS";
export const FETCH_ORG_FAILURE = "FETCH_ORG_FAILURE";

export const FETCH_NEW_ORGANIZATION_REQUEST = "FETCH_NEW_ORGANIZATION_REQUEST";
export const FETCH_NEW_ORGANIZATION_SUCCESS = "FETCH_NEW_ORGANIZATION_SUCCESS";
export const FETCH_NEW_ORGANIZATION_FAILURE = "FETCH_NEW_ORGANIZATION_FAILURE";

export const CREATE_NEW_ORGANIZATION_REQUEST = "CREATE_NEW_ORGANIZATION_REQUEST";
export const CREATE_NEW_ORGANIZATION_SUCCESS = "CREATE_NEW_ORGANIZATION_SUCCESS";
export const CREATE_NEW_ORGANIZATION_FAILURE = "CREATE_NEW_ORGANIZATION_FAILURE";

export const FETCH_ORGANIZATION_DECISION_REQUEST = "FETCH_ORGANIZATION_DECISION_REQUEST";
export const FETCH_ORGANIZATION_DECISION_SUCCESS = "FETCH_ORGANIZATION_DECISION_SUCCESS";
export const FETCH_ORGANIZATION_DECISION_FAILURE = "FETCH_ORGANIZATION_DECISION_FAILURE";

export const orgSchema = new schema.Entity("organizations", [], {
  idAttribute: "organization_id",
});

export const organizationRequestSchema = new schema.Entity("organizationsRequest", [], {
  idAttribute: "organization_request_id",
});

export const organizationAllRequestSchema = new schema.Entity("organizationsRequest", [], {
  idAttribute: "request_datetime",
});


/**
 * Returns a redux api middleware action that makes a request for a collection of Organizations.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createOrganisationRequest = (data) => 
 createAction({
     endpoint: `${domainUrl}/masterservicenew/master/organization-request`,
     method: "POST",
     body: JSON.stringify(data),
     headers: {
         client_code: "ht_front",
         "Content-Type": "application/json",
     },
     types: [
         { type: CREATE_NEW_ORGS_REQUEST },
         {
             type: CREATE_NEW_ORGS_SUCCESS,
             payload: (action, state, res) =>
                 handleResponse([organizationRequestSchema], action, state, res),
         },
         { type: CREATE_NEW_ORGS_FAILURE, payload: handleError },
     ],
 });


export const fetchOrganizations = (params = {}, check) =>
  createAction({
    endpoint: `${domainUrl}/masterservicenew/master/organization?${handleQuery(params)}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_ORGS_REQUEST },
      {
        type: FETCH_ORGS_SUCCESS,
        payload: (action, state, res) =>
        check ? handleResponseNew([orgSchema], action, state, res) : handleResponse([orgSchema], action, state, res),

      },
      { type: FETCH_ORGS_FAILURE, payload: handleError },
    ],
  });

  /**
 * Returns a redux api middleware action that makes a request for a collection of Organizations.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchOrganization = (organization_id) =>
  createAction({
    endpoint: `${domainUrl}/masterservicenew/master/organization/${organization_id}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_ORG_REQUEST },
      {
        type: FETCH_ORG_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(orgSchema, action, state, res),
      },
      { type: FETCH_ORG_FAILURE, payload: handleError },
    ],
});

/**
 * Returns a redux api middleware action that makes a request for a collection of new organization request.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchNewOrganizationRequest = (params) => 
 createAction({
     endpoint: `${domainUrl}/masterservicenew/master/organization-request?${handleQuery(params)}`,
     method: "GET",
     headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
     },
     types: [
         { type: FETCH_NEW_ORGANIZATION_REQUEST },
         {
             type: FETCH_NEW_ORGANIZATION_SUCCESS,
                 payload: (action, state, res) =>
                 handleResponse([organizationAllRequestSchema], action, state, res),
         },
         { type: FETCH_NEW_ORGANIZATION_FAILURE, payload: handleError },
     ],
 });

/**
* Returns a redux api middleware action that makes a request to create a organization.
*
* @return {object} - Redux api middleware action that triggers & handles the request.
*/
export const createOrganizationRequest = (data, params) => 
 createAction({
     endpoint: `${domainUrl}/masterservicenew/master/organization-request?${handleQuery(params)}`,
     method: "POST",
     body: JSON.stringify(data),
     headers: {
         client_code: "ht_front",
         "Content-Type": "application/json",
     },
     types: [
         { type: CREATE_NEW_ORGANIZATION_REQUEST },
         {
             type: CREATE_NEW_ORGANIZATION_SUCCESS,
             payload: (action, state, res) =>
                 handleResponse([organizationRequestSchema], action, state, res),
         },
         { type: CREATE_NEW_ORGANIZATION_FAILURE, payload: handleError },
     ],
 });

/**
* Returns a redux api middleware action that makes a request to accept/reject a new organization request.
*
* @return {object} - Redux api middleware action that triggers & handles the request.
*/
export const organizationRequestDecision = (request_id, data) =>
 createAction({
     endpoint: `${domainUrl}/masterservicenew/master/organization-request/${request_id}/status`,
     method: "PUT",
     body: JSON.stringify(data),
     headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
     },
     types: [
         { type: FETCH_ORGANIZATION_DECISION_REQUEST },
         {
             type: FETCH_ORGANIZATION_DECISION_SUCCESS,
             payload: (action, state, res) =>
                 handleDataSuccessResponse([organizationRequestSchema], action, state, res),
         },
         { type: FETCH_ORGANIZATION_DECISION_FAILURE, payload: handleError },
     ],
 });
