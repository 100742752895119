import "./BidSummary.less";
import { useState, useEffect } from "react";
import { Card, Spin, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { awsDocumentUrl, domainUrl } from "../../../helper";
import { genHeaders } from "../../../components/Tender/Tender";
import { FormattedMessage } from "react-intl";

const BidSummary = ({
  onBidPricingEdit,
  bidId,
  onFileEdit,
  showEdit = false,
}) => {
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const onFileClick = async (file) => {
    try {
      if (file?.document_id) {
        const documentDetails = await axios.get(
          `${awsDocumentUrl}/Prod/v1/document/${file.document_id}`
        );

        let link;
        if ((link = documentDetails?.data?.data?.signed_link)) {
          const win = window.open(link, "_blank");
          win.focus();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      try {
        setLoading(true);
        setError(null);
        const bidInfo = await axios.get(
          `${domainUrl}/bid/v1/bid/${bidId}`,
          genHeaders()
        );
        const assets = bidInfo.data.data.lines?.map((el) => ({
          name: el?.asset_name ?? el?.line_text,
          price: el?.line_amount,
          items: el?.items?.map((item) => ({
            name: item?.item_text,
            price: item?.amount,
          })),
        }));

        const fileInfo = await axios
          .get(
            `${awsDocumentUrl}/Prod/v1/document?entity_type=B&entity_id=${bidId}`,
            { token: sessionStorage.getItem("token") }
          )
          .catch((err) => {
            console.log(err);
            if (err?.status === 404) {
              console.log("no files...");
              return null;
            }
          });

        const files = fileInfo?.data?.data?.documents;

        setInfo({
          assets,
          files,
          total: bidInfo?.data?.data?.sub_total,
          title: bidInfo?.data?.data?.title,
        });
      } catch (e) {
        console.error(e);
        setError("Unable to retrieve bid information.");
      } finally {
        setLoading(false);
      }
    };

    getInfo();
  }, []);

  let content;
  if (loading) {
    content = (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  } else if (error) {
    content = <p>{error}</p>;
  } else {
    content = (
      <>
        <div className="hedding">
          <h2><FormattedMessage id={'bid_summary'}/></h2>
        </div>

        <Card>
          <div className="d-flex" style={{ marginBottom: 10 }}>
            <h2 style={{ margin: "auto" }}><FormattedMessage id={'price'}/></h2>
            {showEdit && (
              <div style={{ fontSize: 18 }}>
                <EditOutlined onClick={onBidPricingEdit} />
              </div>
            )}
          </div>

          <div>
            {info?.assets?.map((o, key) => (
              <div key={key} className="item item-full">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{o?.name}</h3>
                  <span
                    style={{
                      padding: "6px 10px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: 3,
                    }}
                  >
                    {o?.price}&nbsp;&euro;
                  </span>
                </div>
                <ol>
                  {o?.items?.map((i, index) => (
                    <li
                      key={index}
                      style={{
                        margin: "5px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ color: "#6f6f6f" }}>{i?.name}</h3>
                      <span
                        style={{
                          padding: "6px 10px",
                          backgroundColor: "#e0e0e0",
                          border: "1px solid black",
                          borderRadius: 3,
                        }}
                      >
                        {i?.price}&nbsp;&euro;
                      </span>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
          <hr />
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <Space>
              <span>
                <b><FormattedMessage id={'total'}/></b>
              </span>
              <span
                style={{
                  padding: "6px 10px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: 3,
                }}
              >
                {info?.total}&nbsp;&euro;
              </span>
            </Space>
          </div>
        </Card>
        <Card style={{ marginTop: 15 }}>
          <div className="d-flex" style={{ marginBottom: 10 }}>
            <h2 style={{ margin: "auto" }}><FormattedMessage id={'files'}/></h2>
            {showEdit && (
              <div style={{ fontSize: 18 }}>
                <EditOutlined onClick={onFileEdit} />
              </div>
            )}
          </div>
          {!info?.files?.length && <h3><FormattedMessage id={'no_files_attached'}/>.</h3>}
          {info?.files?.map((file) => {
            return (
              <div
                onClick={() => onFileClick(file)}
                key={file?.document_id}
                style={{ cursor: "pointer" }}
                className="filesNames mt-2"
              >
                <div className="filesDes">
                  <span>File Description</span>
                  <span>{file?.file_description}</span>
                </div>
                <div className="fileFormat" style={{ padding: "0 20px" }}>
                  <div className="fileType">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="51.467"
                      height="68.622"
                      viewBox="0 0 51.467 68.622"
                    >
                      <path
                        id="Icon_awesome-file-image"
                        data-name="Icon awesome-file-image"
                        d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                        fill="#2B7776"
                      />
                    </svg>
                  </div>
                  <div className="fileInfo">
                    <div className="text">{file?.file_name}</div>
                    {/* <div className="fileSize">200kb</div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </Card>
      </>
    );
  }

  return <div className="summary-root">{content}</div>;
};

export default BidSummary;
