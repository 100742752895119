import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import "../../components/TenderDetail/TenderDetail.css";
import profilePic from "../../assets/user-img2.png";
import profilePic2 from "../../assets/user-img3.png";
import profilePic3 from "../../assets/user-img4.png";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  Radio,
  Space,
  Checkbox,
  Upload,
  message,
  Table,
  Tag,
  Spin,
  Form,
  Input,
} from "antd";

import Summary from "./Summary/Summary";
import { FormattedMessage, useIntl } from "react-intl";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const TenderDetail = (props) => {
  const [sendQty, setSendQty] = useState(false);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [bid_id, setBidId] = useState();
  const intl=useIntl()
  const { tenderId } = props.match.params;

  const genHeaders = () => ({
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
    },
  });

  // useEffect(() => {
  //   const getInfo = async () => {
  //     const supplier_id = sessionStorage.getItem("entity_id");
  //   setLoading(true);
  //   let bidResult = await axios.get(
  //     `/bid/v1/tender/${tenderId}/bid?supplier_id=${supplier_id}`,
  //     genHeaders()
  //   );
  //   setLoading(false);
  //   const bidID = bidResult?.data?.data?.result[0]?.bid_id;
  //   setBidId(bidID);
  //   };
  //   getInfo();
  // }, []);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Header />
      {loading ? (
        <div style={{ height: 500 }} className="d-flex j-center">
          <Spin style={{ margin: "auto" }}></Spin>
        </div>
      ) : (
        <div className="container f-col">
          {sendQty ? (
            <Card>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label={intl.formatMessage({id:"send_question"})}
                  name={intl.formatMessage({id:"username"})}
                  // rules={[
                  //   { required: true, message: "Please input your username!" },
                  // ]}
                >
                  <Input.TextArea />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Space>
                    <Button
                      style={{ marginTop: 0 }}
                      type="primary"
                      htmlType="submit"
                    >
                        <FormattedMessage id="submit"/>
                    </Button>
                    <Button
                      style={{ marginTop: 0 }}
                      htmlType="submit"
                      onClick={() => setSendQty(false)}
                    >
                     <FormattedMessage id="cancel"/>
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          ) : (
            <>
              <Summary tenderId={tenderId} />
            </>
          )}
        </div>
      )}
    </>
  );
};

TenderDetail.propTypes = {};

export default TenderDetail;
