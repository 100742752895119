import React, { useState } from "react";
import "./AddModal.css";
import { FormattedMessage, useIntl } from "react-intl";

const AddModal = (props) => {
  const [consultancyPrice, setConsultancyPrice] = React.useState(props.data);
  const [errorMessage, setErrorMessage] = React.useState({});
  function handleInputChange(event) {
    console.log('nam',event.target.name);
    const data = {
      ...consultancyPrice,
      
      [event.target.name]: event.target.value,
    };
    setConsultancyPrice(data);
    setErrorMessage({});
  }
  function handleSubmit() {
    if (!consultancyPrice) {
      setErrorMessage({
        plan_name: (<FormattedMessage id= {'Please Enter Name'}/>),
        price: (<FormattedMessage id= {'Please Enter Price'}/>),
        description: (<FormattedMessage id= {'Please Enter Description'}/>),
      });
    } else if (!consultancyPrice.plan_name || consultancyPrice.plan_name === "") {
      setErrorMessage({
        plan_name: "Please Enter Name",
      });
    } else if (!consultancyPrice.price || consultancyPrice.price === "") {
      setErrorMessage({
        price: "Please Enter Price",
      });
    } else if (!consultancyPrice.description || consultancyPrice.description === "") {
      setErrorMessage({
        price: "Please Enter Description",
      });
    } else if (
      consultancyPrice &&
      consultancyPrice.plan_name !== "" &&
      consultancyPrice.price !== ""
    ) {
      props.onSubmit(consultancyPrice);
    }
  }

  return (
    <div className="pageOverlay">
      <div className="modalOuter">
        <div className="closeBtn" onClick={() => props.onClose()}>
          close
        </div>
        <div className="inputFieldGroup">
        <label><FormattedMessage id="Name"/>
                </label>
          <input
            className="inputFiled"
            type="text"
            name="plan_name"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.plan_name : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.plan_name}</div>
        </div>
        <div className="inputFieldGroup">
          <label><FormattedMessage id="price"/>
                </label>
          <input
            className="inputFiled"
            type="text"
            name="price"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.price : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.price}</div>
        </div>
        <div className="inputFieldGroup">
        <label><FormattedMessage id="desc"/>
                </label>
          <input
            className="inputFiled"
            type="text"
            name="description"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.description : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.description}</div>
        </div>
        <div className="buttonBlock">
          <div className="btn cancelBtn" onClick={() => props.onClose()}>
          <label><FormattedMessage id="cancel"/>
                </label>
          </div>
          <div className="btn" onClick={() => handleSubmit()}>
          <label><FormattedMessage id="save"/>
                </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
