import React, { useState, useEffect } from "react";
import { Spin, Form, Input, Button, Checkbox, Select, InputNumber, Modal, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from "react-intl";
import { apiUrl, domainUrl } from "../../helper";
import get from 'lodash/get';
import axios from "axios";
import Header from "../../components/Header";
import { fetchCompanyTypes } from "../../actions/company";
import { createOrganisationRequest } from '../../actions/organization';
import { fetchCities } from '../../actions/list';
import { fetchServiceTypes } from '../../actions/services';

import { segKeyData } from '../../utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const { Option } = Select;

const SignUp = () => {
    
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory()

   const { invite_type, invite_code } = useParams();

   const [successModal, setSuccessModal] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [countries, setCountries] = useState([]);
   const [cities, setCities] = useState([]);

   const companyTypes = useSelector(state => get(state, `entities.companyTypes`));
//    const cities = useSelector(state => segKeyData('city_name', 'city_id', Object.values(get(state, `entities.cities`) || {})));
//    const services = useSelector(state => segKeyData('service_type_name', 'service_type_id', Object.values(get(state, `entities.services`) || {})));

   const getCities = async() => {
       await dispatch(fetchCities(1));
   }

   const getServiceTypes = async() => {
       await dispatch(fetchServiceTypes());
   }

   useEffect(() => {
    //    getCities();
    //    getServiceTypes();
    getCompanyTypes();
    getAllCountries();
   }, [dispatch]);

    const onFinish = async(values) => {
        window.scrollTo(0, 0)
        setIsSubmitting(true);
        values.contact = values.contact.toString();
        // values.country_id = 1;
        // values.city_id = +values.city_id;
        // delete values.service_cities_values;
        // delete values.service_types_values;
        const response =  await dispatch(createOrganisationRequest(values));
        setIsSubmitting(false);
        if(response.error) {
            message.error(response?.payload?.body?.error?.message)
        }
        else {
            setSuccessModal(true);
        }
    };

    const getAllCountries = () => {
        axios
          .get(
                `${domainUrl}/masterservicenew/master/country?limit=100&offset=0&order_by=created_at&sort_by=ASC`,
                {
                headers: {
                    token: sessionStorage.getItem("token"),
                    client_code: "ht_front",
                },
                }
            )
        .then((res) => {
            let data = res.data.data;
            setCountries(data.result);
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const getAllCities = (countryId) => {
        axios
        .get(
            `${domainUrl}/masterservicenew/master/city?limit=100&country_id=${countryId}`,
            {
              headers: {
                token: sessionStorage.getItem("token"),
                client_code: "ht_front",
              },
            }
        )
        .then((res) => {
            let data = res.data.data;
            setCities(data.result);
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const getCompanyTypes = async () => {
        await dispatch(fetchCompanyTypes({ limit: 1000 }));
    }

  return (
      <Spin spinning={isSubmitting}>
        <Header /> 
        <div className = "mainContainer" >
            <div className="innerContainer">
                <h2 className="title"><FormattedMessage id={'organisation_registration_form'} /></h2>
                <Form
                    {...layout}
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    style={{ width: '85%' }}
                >
                    <Form.Item
                        label={<FormattedMessage id={'Organisation_Name'} />}
                        name="organization_name"
                        rules={[{
                            required:true,
                            message:<FormattedMessage id={'error.organization'} />
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    {/* <Form.Item
                    label={<FormattedMessage id={'business_id'} />}
                    name="business_id"
                    rules={[{ required: true, message: <FormattedMessage id={'error.business_id'} /> }]}
                    >
                        <Input />
                    </Form.Item> */}

                    {/* <Form.Item
                        label={<FormattedMessage id={'full_name'} />}
                        name="full_name"
                        rules={[{required:true,message:<FormattedMessage id={'error.name'} />}]}
                    >
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        label={<FormattedMessage id={'mobile_number'} />}
                        name="contact"
                        rules={[
                            {
                            pattern: new RegExp(/^[0-9]*$/),
                            message: <FormattedMessage id={'error.invalid_mobile_number'} />,
                            },
                            { required: true, message: <FormattedMessage id={'error.mobile_number'} /> }]
                        }
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id={'email'} />}
                        name="email"
                        rules={[
                            {
                            // pattern: new RegExp(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/),
                            type: "email",
                            message: <FormattedMessage id={'error.invalid_email'} />,
                            },
                            { required: true, message: <FormattedMessage id={'error.email'} /> }]
                        }
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id={'company_type'} />}
                        name="company_type_id"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'select_company_type'} /> }]
                        }
                    >
                        <Select 
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            options={
                                Object.values(companyTypes || {})?.map((el, index) => {
                                    return {
                                        label : el?.company_type_name,
                                        value : el.company_type_id
                                    }
                                })
                            }
                        />
                    </Form.Item>
                    {/* <Form.Item
                        label={<FormattedMessage id={'title'} />}
                        name="organization_name"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'Please Enter Name'} /> }]
                        }
                    >
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        label={<FormattedMessage id={'business_id'} />}
                        name="business_id"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'error.business_id'} /> }]
                        }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={'address'} />}
                        name="address"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'error.address'} /> }]
                        }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={'country'} />}
                        name="country_id"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'select_country'} /> }]
                        }
                    >
                        <Select 
                            options={
                                [
                                    {label : <FormattedMessage id={'select_country'} />,value:''},
                                    ...countries?.map((el, index) => {
                                        return {
                                            label : el?.country_name,
                                            value : el.country_id
                                        }
                                    })
                                ]
                            }
                            onChange={
                                (value) => {
                                    getAllCities(value);
                                    form.setFieldsValue({ city: '' })
                                }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={'city'} />}
                        name="city_id"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'select_country'} /> }]
                        }
                    >
                        <Select 
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                            options={
                                cities?.map((el, index) => {
                                    return {
                                        label : el?.city_name,
                                        value : el.city_id
                                    }
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={'postal_code'} />}
                        name="pincode"
                        rules={
                            [{ required: true, message: <FormattedMessage id={'error.pin_code'} /> }]
                        }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id={'website'} />}
                        name="website"
                    >
                        <Input />
                    </Form.Item>
                    {/* --------------------------------- */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'title'} />}
                        name="title_en"
                    >
                        <Input />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'long_description_en'} />}
                        name="long_text_en"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'long_description_fi'} />}
                        name="long_text_fi"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'short_description_en'} />}
                        name="short_text_en"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'short_description_fi'} />}
                        name="short_text_fi"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'social_youtube_link'} />}
                        name="social_youtube_link"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'social_ig_link'} />}
                        name="social_ig_link"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={<FormattedMessage id={'social_fb_link'} />}
                        name="social_fb_link"
                    >
                        <Input.TextArea />
                    </Form.Item> */}
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            {<FormattedMessage id={'submit'} />}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>

        <Modal
          closable={false}
          title=""
          centered
          visible={successModal}
          footer={null}
        >
          <p><FormattedMessage id={'submit_request'} /></p>
          <p><FormattedMessage id={'response_back'} /></p>
          <div className="d-flex j-center f-row">
              <div className="confirmbtn btn" onClick={() => {setSuccessModal(false); history.push('/')}}>
                <FormattedMessage id={'ok'} />
              </div>
            </div>
          
        </Modal>
    </Spin>
  );
};

export default SignUp;