import React, { useState } from "react";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import FileDisplay from "../../components/FileDisplay/FileDisplay";
import FileUpload from "../../components/FileUpload";

function AddDocument({ getFileAvailability }) {

  const FILE_POST_URL = `https://app.tenderin.fi/tender/v1/document`;
  const userId = sessionStorage.getItem("user_id");
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  
  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesStatusChange = (e) => {
    setFileList(e);
    // isUploading(true)
  };
 
  const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  return (
    <>
     
        <div className="innerContainer">
          <h1 className="profile">{<FormattedMessage id={"Document"} />}</h1>
          <div className="Document">
            <h4>{<FormattedMessage id={"AddDocument"} />}</h4>
            {/* <Upload
              name="file"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              // listType="picture-card"
              // className="avatar-uploader"
              showUploadList={true}
              // beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              <Button icon={<UploadOutlined />}><FormattedMessage id="clikupload"/></Button>
            </Upload> */}

<FileUpload
                  fileList={fileList}
                  filePostURL={FILE_POST_URL}
                  entityType={"R"}
                  entityID={userId}
                  onFilesStatusChange={onFilesStatusChange}
                  addFileList={addFileList}
                  onUploadStatusChange={onUploadStatusChange}
                  onFilesUploadStatusChange={onFilesUploadStatusChange}
                />
          </div>

          <div className="filedisplay">
            <FileDisplay
              entityType={"R"}
              fileList={fileList}
              entityId={userId}
              deleteFiles={true}
              hideTitle={true}
              getFileAvailability={(files) =>
                getFileAvailability && getFileAvailability(files)
              }
            />
          </div>
        </div>
     
    </>
  );
}
export default AddDocument;
