import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";

import "./AdditionalInfo.css";
import { genHeaders, useTender } from "../TenderCreationSteps";
import TextEditor from '../../TextEditor';
import { domainUrl } from "../../../helper";
import { message } from "antd";
import { useDispatch } from "react-redux";
import ToolTip from '../../ToolTip';

const AdditionalInfo = ({updateTenderData}) => {
  const dispatch = useDispatch();
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();

  const [info, setInfo] = useState(tenderDetails?.additional_info ?? "");

  useEffect(() => {
    if(tenderDetails?.is_published === 0) {
      setInfo(tenderDetails?.additional_info ?? "")
    }
    else
      setInfo(tenderModifiedDetails?.additional_info ?? "")
  },[tenderDetails])

  const onNext = async() => {
    if(tenderDetails.is_published !== 1) {
    //if (info.trim() !== "") {
      const status = await updateTenderData( {
        state: '8',
        additional_info: (!info || info === '') ? 'N/A' : info,
      });
      if(status) {
        move(1, { additional_info: info });
      }
      else {
        message.error("Unable to reach server.");
      }
   // }
    // else {
    //   move(1, { additional_info: info });
    // }
  }
  else {
    move(1, {
      additional_info: info,
    },true);
  }
  };
  const onBack = () => {
    move(-1, { additional_info: info });
  };

  const saveAdditionalInfo = (data) => {
    setInfo(data);
  }

  const publishData = () => {
    move(0, {
      additional_info: (!info || info === '') ? 'N/A' : info,
    },true);
    modifyData();
  }

  return (
    <div className="__AdditionalInfo__">
      <div className="hedding">
        <h2><FormattedMessage id="additional_info" /><ToolTip text_id="tender.additional_info.info" placement="top" /></h2>
      </div>
      <div className="commentblock">
        <h3 className="left"><FormattedMessage id="additional_info" /></h3>
        <div className="right">
          <TextEditor textData={info} onChangeData={saveAdditionalInfo}/>
          
        </div>
      </div>
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          <FormattedMessage id='back'/> </div>
        {
          tenderDetails?.is_published === 1 && 
          <div className="btn" onClick={publishData}>
            <FormattedMessage id="publish" />
          </div>
        }
        <div onClick={onNext} className="btn">
          <FormattedMessage id='next' /> </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default AdditionalInfo;
