const domainUrl = "https://app.tenderin.fi";
//const domainUrl = "http://localhost:8001";
const domainUrl2 = "http://localhost:8002";
const domainUrl3 = "http://localhost:8001";
const apiUrl = domainUrl + "/master";
const selfUrl = "http://localhost:3000";
// const awsDocumentUrl = "https://jw5fimiyx8.execute-api.eu-central-1.amazonaws.com";
const awsDocumentUrl = "https://lskzd5y9fg.execute-api.eu-central-1.amazonaws.com";
// const awsAPIUrl = "https://rt65fsa848.execute-api.eu-central-1.amazonaws.com";
// const awsAPIUrl = "https://plei6epdxb.execute-api.eu-west-1.amazonaws.com";
const awsAPIUrl = "https://tkqrdi8iqh.execute-api.eu-central-1.amazonaws.com";
export { domainUrl, apiUrl, selfUrl, awsDocumentUrl, awsAPIUrl,domainUrl2,domainUrl3 };
