import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Spin, message, Divider, Row, Col, Upload, Avatar, Pagination, Modal, Table, Space } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import get from 'lodash/get';
import { FormattedMessage, useIntl } from "react-intl";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";


import './UserProfile.css';

import { getUser, updateUser, changePasword, getallUser, addUser } from '../../actions/auth';
import { fetchOrganization } from "../../actions/organization";
import { fetchSupplier } from "../../actions/suppliers";
import { fetchFiles, fetchDocument } from "../../actions/documents";
import moment from "moment";
import { domainUrl } from "../../helper";

function getBase64(img, callback) {

  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const UserProfileForm = () => {

  const dispatch = useDispatch();
  const intl = useIntl();
  const [Users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const userId = sessionStorage.getItem('user_id');
  const user = useSelector(state => get(state, `entities.users.${userId}`));
  const roleId = sessionStorage.getItem('role_id');
  const entity_id = sessionStorage.getItem('entity_id');
  // const organisation = useSelector(state => get(state, `entities.organizations.${entity_id}`));
  // const supplier = useSelector(state => get(state, `entities.suppliers.${entity_id}`));
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [companies, setCompanies] = useState([]);

  const companyRef = useRef();
  const addressRef = useRef();
  const bidRef = useRef();
  const orgRef = useRef();
  // console.log("organisation",organisation)
  const onAddUser = async (values) => {
    setIsLoading(true);
    delete values.confirm;
    values["type"] = "O";
    values["created_by"] = parseInt(userId)
    values["is_verified"] = 1
    values["role_id"] = parseInt(roleId)
    values["entities"] = [

      {
        entity_id: parseInt(entity_id),
        entity_type: "organization",
      },
    ];
    selectedCompanies.map(
      comp => {
        values["entities"].push(
          {
            entity_id: parseInt(comp),
            entity_type: "company",
          }
        )
      }
    )
    const response = await dispatch(addUser(values));
    // console.log("response",response);
    if (response?.error === true) {
      message.error(response?.payload?.body?.error?.message)
    }
    else {
      message.success(intl.formatMessage({ id: "User_success" }));
      setIsLoading(false);
      getuserdetails(1, pageSize)
      form.resetFields()
      setVisible(false)
    }
  };

  useEffect(() => {
    if (roleId === '2') {
      console.log(roleId,"========fetchOrganization")
      dispatch(fetchOrganization(+entity_id));
    }
    else if (roleId === '3') {
      console.log(roleId,"========fetchSupplier")
      dispatch(fetchSupplier(+entity_id));
    }
    getImage();
    getuserdetails(1, pageSize)
  }, []);

  const onPaginationChange = (pageDetails) => {
    setPageSize(pageDetails.pageSize);
    getuserdetails(pageDetails.current, pageDetails.pageSize);
  }
  const getuserdetails = async (page, pageSize) => {
    // setConfirmLoading(true);
    const response = await dispatch(getallUser({ limit: pageSize, offset: pageSize * (page - 1), order_by: 'created_at', sort_by: 'DESC' }));
    const countresponse = await dispatch(getallUser({limit: 100000, offset: 0, order_by: 'created_at', sort_by: 'DESC',entity_id:entity_id }));
    // const count = response?.payload?.data?.meta?.count;
    const count = countresponse?.payload?.data?.meta?.size;
    const result = response?.payload?.data?.result.length == 0 ? [] : response?.payload?.data?.result;
    const filterUserResponse = await result?.filter((user) => user?.entities?.[0]?.entity_id == entity_id)
    result.map(obj => {
      obj['company'] = Array.prototype.map.call(obj['entities'], function (item) {
        console.log(item);
        if (item.Company != null) {
          return item.Company.company_name;
        }
      }).join(',').substring(1);
    })

    // setConfirmLoading(false);
    setTotalCount(count);
    setUsers(result);
    console.log("countresponse===", countresponse)
  }
  const getImage = async () => {
    const result = await dispatch(fetchFiles({
      entity_type: 'TP',
      entity_id: userId,
    }));
    const files = Object.keys(result?.payload?.entities?.files || {});
    const file_id = files[files?.length - 1];
    if (file_id) {
      const res = await dispatch(fetchDocument(file_id));
      setImageUrl(res?.payload?.entities?.files[file_id]?.signed_link || '');
    }
  }


  const handleChange = async (info) => {
    console.log("hello")
    if (info.file.status === 'uploading') {
      setLoading(true);
      const fd = new FormData();
      const file = info?.file?.originFileObj;

      fd.append("entity_type", 'TP');
      fd.append("entity_id", userId);
      fd.append("file_description", '');
      fd.append("file", info?.file?.originFileObj);
      try {
        const res = await axios.post('/tender/v1/document', fd, genHeaders());
        console.log(res,"=======res");
        message.destroy();
        message.success(`Profile Image uploaded successfully.`);
        getBase64(info.file.originFileObj, imageUrl => {
          setImageUrl(imageUrl)
          setLoading(false)
        },
        );
      } catch (e) {
        console.error(e);
        setLoading(false);
        message.error(`Unable to upload ${file.name}`);
      }
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    delete values.email;
    await dispatch(updateUser(userId, values));
    await dispatch(getUser(userId, true));
    message.success(intl.formatMessage({ id: 'profil_update' }));
    setEdit(false);
    setIsLoading(false);

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishPwd = async (values) => {
    setIsLoading(true);
    delete values.confirm;
    const abc = await dispatch(changePasword(values)).catch((e) => {
      return null;
    });

    if (abc?.error) {
      message.success(intl.formatMessage({ id: abc?.payload?.body?.error?.message }));
    } else {
      message.success(intl.formatMessage({ id: 'pwd_change' }));
    }

    setIsLoading(false);

  };

  const onFinishPwdFailed = (errorInfo) => {
    console.log('failedsfass');
    console.log('Failed:', errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}><FormattedMessage id="Upload" /></div>
    </div>
  );
  const onCompanySelect = ({ target }) => {
    const id = +target.value;
    setSelectedCompanies((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };
  const onFilterChange = () => {
    console.log(companyRef);
    // const company = companyRef.current.value?.toLowerCase() ?? "";
    // const addr = addressRef.current.value?.toLowerCase() ?? "";
    // const bid = bidRef.current.value?.toLowerCase() ?? "";
    const org = orgRef.current.value?.toLowerCase() ?? "";

    const filtered = companies?.filter(
      ({ company_name, address, business_id, organization_name }) => {
        // const includesCompany = company_name.toLowerCase().includes(company);
        // const includesAddress = address.toLowerCase().includes(addr);
        // const includesBid = business_id.toLowerCase().includes(bid);
        const includesOrg = organization_name.toLowerCase().includes(org);
        // return true;
        return includesOrg;
      }
    );

    setFilteredCompanies(filtered);
  };
  useEffect(() => {
    const getInfo = async () => {
      try {
        let companies = await axios.get(
          domainUrl + "/masterservicenew/master/company",
          genHeaders()
        );
        companies = companies.data.data.result;

        // const orgInfo = companies?.map((el) =>
        //   axios.get(
        //     `/masterservicenew/master/organization/${el?.organization_id}`,
        //     genHeaders()
        //   )
        // );




        // let company_types = await Promise.all(orgInfo);

        // company_types =
        //   company_types?.map((el) => el.data.data.company_type_id) ?? [];

        // companies =
        //   companies?.filter(
        //     (_, index) => tenderDetails?.company_type === company_types[index]
        //   ) ?? [];

        if (companies?.length) {
          setCompanies(companies);
          setFilteredCompanies(companies);
          // setSelectedCompanies(tenderDetails?.selectedCompanies ?? []);
          // setAllSelected(companies.length === tenderDetails?.selectedCompanies.length ? true : false);

          const orgs = companies?.map((el) => el?.organization_name) ?? [];
          setOrganizations(Array?.from(new Set(orgs)));
        }
      } catch (e) {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      }
    };

    getInfo();
  }, []);
  const Onchange = (event) => {
    if (event.target.name === "contact" && event.target.value.match("^[+]*[0-9]*$") === null) {
      return;
    }
    if (event.target.name === "pincode" && event.target.value.match("^([0-9])*$") === null) {
      return;
    }
  }



  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={intl.formatMessage({ id:"search"}) + `${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="reset" />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            <FormattedMessage id="filter" />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  });
  const columns = [
    {
      title: intl.formatMessage({ id: 'user' }),
      dataIndex: 'name',
      key: 'name', //api response
      sorter: (a, b) => a.name?.length - b.name?.length,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('name'),
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'email' }),
      dataIndex: 'email',
      key: 'email',
      width: 45,
    },
    {
      title: intl.formatMessage({ id: 'contact' }),
      dataIndex: 'contact',
      key: 'button',

      ellipsis: true,
      width: 45,
    },
    {
      title: intl.formatMessage({ id: 'company' }),

      dataIndex: 'company',
      key: 'company',
      width: 45,
    },
    //    {
    //     title: intl.formatMessage({ id:'organisation'}),
    //     render: function render(val) {return (
    //       <>
    //         {val?.entities?.[0]?.Organization?.organization_name}
    //       </>
    // //     


    //   )},
    //     key: 'editicon',
    //     width: 45,
    //    },
  ];
  return (
    <Spin spinning={isLoading} >
      <h1 className="profile">{<FormattedMessage id={'profile'} />}</h1>
      <div className="profile-wrapper">
        <h3>{<FormattedMessage id={'profile_details'} />}</h3>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}

        >

          {imageUrl ? <div alt="avatar" className="image" style={{ backgroundImage: `url(${imageUrl})` }} /> : uploadButton}
        </Upload>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={user}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={<FormattedMessage id={'label_name'} />}
            name="name"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.name'} />,
              },
            ]}
          >
            <Input disabled={!edit} />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={'email'} />}
            name="email"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.email'} />,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={'mobile_number'} />}
            name="contact"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.mobile_number'} />,
              },
            ]}
          >
            <Input disabled={!edit} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            {
              !edit &&
              <>
                <Button
                  className="btn" style={{ borderRadius: "8px" }} onClick={() => setEdit(true)}>
                  <FormattedMessage id={'edit'} />
                </Button>

              </>
            }

            {
              edit &&
              <>
                <Button className="btn" htmlType="submit" style={{ marginRight: 10, borderRadius: "8px" }}>
                  <FormattedMessage id={'save'} />
                </Button>

                <Button className="btn cancelBtn" style={{ borderRadius: "8px" }} type="secondary" onClick={() => setEdit(false)}>
                  <FormattedMessage id={'cancel'} />
                </Button>
              </>
            }

          </Form.Item>
        </Form>
      </div>
      <Divider />

      <div className="profile-wrapper">
        <h3>{<FormattedMessage id={'password'} />}</h3>
        <Form
          name="pwd"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{}}
          onFinish={onFinishPwd}
          onFinishFailed={onFinishPwdFailed}
        >
          <Form.Item
            label={<FormattedMessage id={'current_pwd'} />}
            name="old_password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.current_pwd'} />,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={'new_pwd'} />}
            name="new_password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.new_pwd'} />,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={'confirm_new_pwd'} />}
            name="confirm"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={'error.confirm_new_pwd'} />,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(intl.formatMessage({ id: 'match_pwd' })));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button htmlType="submit" className="btn" style={{ borderRadius: "8px" }}>
              <FormattedMessage id={'change_pwd'} />
            </Button>

          </Form.Item>
        </Form>



      </div>
      {roleId == 5 && <>  <h3>{<FormattedMessage id={'userdetails'} />}</h3>
        <Button
          type="primary"
          style={{ marginLeft: "5px", borderRadius: "8px" }}
          className="btn"
          onClick={() => setVisible(true)}
        >
          {/* <FormattedMessage id={'edit'} /> */}
          <FormattedMessage id="AddUser" />
        </Button>
        <Table
          pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
          //  current:currentPage,
          columns={columns}
          //  scroll={{ y: 240 }} 
          onChange={onPaginationChange}
          dataSource={Users}
          locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
        />
        {/* <div className="dataBlock card msgCard">
      <Button
                   
                    style={{ marginLeft: "5px" }}
                    className="btn"
                    onClick={() => setVisible(true)}
                  >
                    <FormattedMessage id={'edit'} />
                    <FormattedMessage id="AddUser"/>
                  </Button>
            <ul className="dataTitle list2">
              <li className="w-5"><FormattedMessage id="user" /></li>
              <li><FormattedMessage id="email"/></li>
              <li><FormattedMessage id="contact"/></li>
              <li><FormattedMessage id="created_at"/></li>
            </ul>
            {
                Users?.map((msg,index) => (
                    <div key={index} className="list-items">
                         <div className="profilePic">
                            <Avatar className="msgAvatar">{msg?.name.substring(0,1)}</Avatar>
                        </div>
                        <ul className={'dataContent list2 bold'}>
                        
                            <li className="d-flex w-3">
                            <span>{msg?.name}</span>
                            </li>
                            <li>
                            <span>{msg?.email}</span>
                            </li>
                            <li>
                            <span>{msg?.contact}</span>
                            </li>
                            <li>
                            <span>{moment(msg?.created_at).format('dd-MM-yyyy')}</span>
                            </li>
                          
                        </ul>
                        </div>
                ))
            }
            
            <Pagination
                className="msgsPagination"
                size="small"
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={1}
                total={totalCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id: 'of' })} ${total} ${intl.formatMessage({ id: 'items' })}  `}
                />
        </div>  */}
      </>}
      <Divider />
      <Modal
        footer={null}
        visible={visible}
        title={intl.formatMessage({ id: "AddUser" })}
        // onOk={this.handleOk}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onAddUser}
        >
          <Form.Item
            label={<FormattedMessage id={"label_name"} />}
            name="name"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={"error.name"} />,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={"email"} />}
            name="email"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={"error.email"} />,
              },
              {type: 'email',
              message: <FormattedMessage id={"error.invalid_email"} />},
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id={"mobile_number"} />}
            name="contact"
            type="number"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={"error.mobile_number"} />,
              },
              {pattern:/\d+/g, message: <FormattedMessage id={"error.invalid_mobile_number"} />},
            ]}
          >
            <Input
              onChange={Onchange}
              maxLength= "16"
              minLength="6"
            />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id={"password"} />}
            name="password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={"error.password"} />,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id={"cnfrmPassword"} />}
            name="confirm"
            rules={[
              {
                required: true,
                message: <FormattedMessage id={"error.password"} />,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(intl.formatMessage({ id: "match_pwd" }))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          {/* <div className="formGroup">
              <label className="leftCol">Choose an Organization</label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={orgRef}
                    name="country"
                    onChange={onFilterChange}
                    id="organization_name"
                    className="inputFiled"
                  >
                    <option value="">All</option>
                    {organizations?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div> */}
          <div className="cardBlock">
            <table className="tableGrid" width="100%" border="0">
              <tbody>
                <tr>
                  <th align="left" valign="top">
                    <FormattedMessage id="choose_company" />
                  </th>
                  <th align="left" valign="top">
                    <FormattedMessage id="select" />
                  </th>
                </tr>
                {filteredCompanies?.map((el, index) => (
                  <tr key={`${el.created_at}${el.business_id}${index}`}>
                    <td align="left" valign="top">
                      {el.company_name}
                    </td>
                    <td align="left" valign="top">
                      <div className="radioBtn">
                        <input
                          type="checkbox"
                          id={`${el.business_id}${el.company_name}${index}`}
                          value={el.company_id}
                          checked={
                            selectedCompanies?.includes(+el.company_id)
                              ? "checked"
                              : null
                          }
                          onChange={onCompanySelect}
                        />
                        <label
                          htmlFor={`${el.business_id}${el.company_name}${index}`}
                        ></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className="btn housingbtn" onClick={onSelectAll}>
                {!allSelected ? 'Select All' : 'Unselect All'}
              </div> */}
          </div>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button htmlType="submit" className="btn" style={{ borderRadius: "8px" }}>
              {/* <FormattedMessage id={"change_pwd"} /> */}
              <FormattedMessage id="submit" />
            </Button>
          </Form.Item>
        </Form>
      </Modal>

    </Spin>
  );
};

export default UserProfileForm;