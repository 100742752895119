import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { generatePreTenderPdf } from "../../actions/tender";
import { Modal } from "antd";
import { set } from "lodash";



const PdfDisplayPreTender = ({pretender_id, closePreview}) => {

    const dispatch = useDispatch();

    const [pdfFile, setPdfFile] = useState([]);
    useEffect(() => {
		const getDocument = async() => {
		  const document = await dispatch(generatePreTenderPdf(pretender_id));
          const contentType = "application/pdf";
          // const win = window.open(URL.createObjectURL(document?.payload), "_blank");
          //   win.focus();
          //   closePreview();
            const file = new Blob(
              [document?.payload], 
              {type: 'application/pdf'});

              //Build a URL from the file
const fileURL = URL.createObjectURL(file);
setPdfFile(fileURL);
//Open the URL on new Window
//window.open(fileURL);
          // setPdfFile("data:" + contentType + ";base64," + document?.payload)
          // setPdfFile(URL.createObjectURL(document?.payload));
          // setTimeout(() => {
          // closePreview();

          // }, 1000);
		}
		getDocument();
		}, [dispatch]);

    const handleCancel = () => {
        closePreview();
    }

	return (
		<>
        <Modal visible={true} closable={false} footer={null} onCancel={handleCancel}>
        <div style={{height: '75vh'}}>
        <embed
                src={pdfFile}
                id="displayFile"
                alt="tender pdf"
                width="97%"
                height="99%"
                style={{ borderStyle: "solid"}}
                type="application/pdf"
              />
              </div>
        </Modal>
		</>
	);
};

PdfDisplayPreTender.propTypes = {
	pretender_id: PropTypes.number,
};

export default PdfDisplayPreTender;