import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import logo from "../../assets/TILogo.png";
import "../../components/Login/Login.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { domainUrl } from "../../helper";
import { getUser, login, resetPasword } from '../../actions/auth';
import { useDispatch, useSelector } from "react-redux";

import ForgotPassword from './ForgotPassword';
import { FormattedMessage } from "react-intl";

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
};

const Login = (props) => {
  const [message, setMessage] = useState("");
  
  const [inviteCode, setInviteCode] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openResetPwdFormModel, setOpenResetPwdFormModel] = useState({
    isOpen: false,
  });
  const [isUploading, setIsUploading] = useState(false);
  let auth = useSelector(state => state.authReducer.auth);

  const setUserData = async() => {
    sessionStorage.setItem('token',auth?.token);
    sessionStorage.setItem('user_id',auth?.user_id);
    sessionStorage.setItem('entity_id',auth?.entity_id);
    sessionStorage.setItem('role_id',auth?.role_id);
    sessionStorage.setItem('type_admin',auth?.type_admin);
    await dispatch(getUser(auth?.user_id));
    history.push("/");
  }

  useEffect(() => {
    if(auth) {
      if(auth?.token) {
        setUserData();
      }
      else if(!auth?.success) {
        setMessage(auth?.error?.message);
        setIsUploading(false);
      }
    }
    
  },[auth]);
  useEffect(() => {
    const length = 9;
    const ts = Math.round((new Date()).getTime() / 1000);
    // the below line generates 11 chars random string.
    let code = ts.toString(12).substring(0, 8);

    if (length <= code.length) {
        return code.substring(code.length - length);
    }

    const extraLength = length - code.length;
    // generating random two chars.
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < extraLength; i++) { code += possible.charAt(Math.floor(Math.random() * possible.length)); }

    code = `IS${code}`;
    setInviteCode(code);
  },[]);

  const performLogin = async (e) => {
    setIsUploading(true);
    const loginData = e;
    setMessage("Please Wait...");
    await dispatch(login(loginData));
  };

  return (
    <Spin spinning={isUploading} >
      <div className="login__container">
        <div className="loginOuter">
          <div className="ht-logo2">
            <img src={logo} alt="logo" height="100px"/>
          </div>
            {message && <span className={message == "Please Wait..." ? "messageLogin" : "errorMessageLogin"}>{message}</span>}
      <Form
        {...layout}
        name="basic"
        initialValues={{
        }}
        onFinish={performLogin}
      >
        <Form.Item
          label=""
          name="email"
          className="loginItem"
          rules={[
            {
              required: true,
              message: 'Please enter user name',
            },
          ]}
        >
          <Input className="form-control" placeholder="Username"/>
        </Form.Item>

        <Form.Item
          label=""
          name="password"
          className="loginItem"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password className="form-control" placeholder="Password"/>
        </Form.Item>
        <Form.Item {...tailLayout} className="btnFormItem">
          <Button  htmlType="submit" className="loginBtn">
          <FormattedMessage id="login"/>
          </Button>
        </Form.Item>
        <div className="labels" onClick={() =>  setOpenResetPwdFormModel({ isOpen: true })}>Forgot Your Password?</div>
      </Form>
          {/* <div className="formGroup">
            {message && <span>{message}</span>}
            {emailError && (
              <span className="errorMessageLogin">{emailError}</span>
            )}
            <input
              className="form-control"
              type="text"
              placeholder="Username"
              name="email"
              value={email}
              onChange={(e) => (
                setEmail(e.target.value), setEmailError(""), setMessage("")
              )}
            />
            {passwordError && (
              <span className="errorMessageLogin">{passwordError}</span>
            )}
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => (
                setPassword(e.target.value), setPasswordError(""), setMessage("")
              )}
            />
            <div className="loginBtn" onClick={() => performLogin()}>
              Login
            </div>
            <div className="labels">Forgot Your Password?</div>
          </div> */}
        <Link to="/signup" className="SignUpclass">Sign up as an organization</Link>
        <Link to={`/register/IS/${inviteCode}`} className="SignUpclass">Sign up as a supplier</Link>
        </div>
        {openResetPwdFormModel?.isOpen && (
          <ForgotPassword
            isOpen={openResetPwdFormModel?.isOpen}
            onClose={() => setOpenResetPwdFormModel({ isOpen: false })}
          />
        
        )}
      
      </div>
    </Spin>
  );
};

Login.propTypes = {};

export default Login;
