import "./Tender.less";

import React, { useCallback, useContext, useState, useEffect } from "react";
import { Spin, Modal, message, Row, Col } from "antd";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import AdditionalInfo from "./AdditionalInfo";
import EnterTenderDetails from "./EnterTenderDetails";
import EnterAssetDetails from "./EnterAssetDetails";
import AttachFile from "./AttachFile";
import ChooseTenderType from "./ChooseTenderType";
import ChooseHousingCompany from "./ChooseHousingCompany";
import Summary from "./Summary";
import ConfirmTender from "./ConfirmTender";
import ChooseSupplier from "./ChooseSupplier";
import ChooseClosingDate from "./ChooseClosingDate";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const TenderContext = React.createContext({
  onStepChange: null,
  tenderDetails: null,
  tenderModifiedDetails: null,
  modifyData: null,
  setConfirmTenderPage: null,
});

export const useTender = () => {
  const ctx = useContext(TenderContext);
  return ctx;
};

const TenderCreationSteps = ({steps, createTenderData, tenderId, getDetails, getTenderAssets, modifyAssets, modifyCompanies, modifySuppliers, updateTenderData}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [confirmTenderPage, setConfirmTenderPage] = useState(false);
  
  const [tenderModifiedDetails, setTenderModifiedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModificationModal, setShowModificationModal] = useState(false);
  const [reason, setReason] = useState('');
  const [organizationData, setOrganizationData] = useState({});

  const tenderDetails = useSelector(state => get(state, `entities.tenders.${tenderId}`) || {});
  const currentCompanyDetails = useSelector(state => Object.values(get(state, `entities.tenderCompanies`) || {}));
  let lastStep = useSelector(state => get(state, `entities.tenders.${tenderId}.state`) || 0);
  const organizations = useSelector(state => get(state, `entities.organizations`));
  const history = useHistory();

  const modifyData = () => {
    setShowModificationModal(true);
  }

  useEffect(() => {
    if(organizations && currentCompanyDetails && currentCompanyDetails.length > 0) {
      
      setOrganizationData(organizations[currentCompanyDetails[0].organization_id]);
    }
  }, [organizations, currentCompanyDetails])

  const onStepChange = useCallback((moveBy, tenderDetails,isModified) => {
    if (tenderDetails) {
      if(isModified)  {
        setTenderModifiedDetails((current) => {
          return {...current, ...tenderDetails};
        })
      }
    }
    setStep((currStep) => {
      const finalStep = currStep + moveBy;
      if (finalStep < 1 || finalStep > 10) {
        history.push('/');
        return;
      }
      return finalStep;
    });
  }, []);

  const onNavClick = (step) => {
    if(parseInt(lastStep)+1 >= step)
      setStep(step);
  };
  const modificationReason = (e) => {
    setReason(e.target.value)
  }
  const confirmModify = async() => {
    setShowModificationModal(false);
    setLoading(true);
    if(tenderModifiedDetails.hasOwnProperty('assetsFormValues')) {
      await modifyAssets(tenderModifiedDetails.assetsFormValues, tenderModifiedDetails.selectedAssetDb);
    }
    if(tenderModifiedDetails.hasOwnProperty('selectedCompanies')) {
      await modifyCompanies(tenderModifiedDetails.selectedCompanies);
    }
    let data = Object.assign({}, tenderModifiedDetails);
    delete data.assetsFormValues;
    delete data.selectedCompanies;
    delete data.selectedAssetDb;
    let dataToPass = {}
    Object.keys(data).map(key => {
      if(data[key] != null){
          dataToPass[key]= data[key];
      }
    });
    dataToPass.tender_id = String(dataToPass.tender_id);
    const status = await updateTenderData(dataToPass, reason, tenderDetails);
      
    setLoading(false);
    if(status)
     setStep(10);
    else 
      message.error("Unable to reach servers.");
  }

  useEffect(() => {
    if(isNaN(parseInt(lastStep))){
      lastStep = 0;
    }
    if(lastStep && step === 0)
      setStep(parseInt(lastStep)+1);
  },[lastStep])

  useEffect(() => {
    if(tenderDetails.is_published === 1) {
      setTenderModifiedDetails(tenderDetails);
    }
  },[tenderDetails])
  let content = (
    <TenderContext.Provider value={{ onStepChange, tenderDetails, modifyData, tenderModifiedDetails, setConfirmTenderPage }}>
      {(step === 0 || steps[step-1]?.key === 1) && <ChooseTenderType createTenderData={createTenderData} updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 2 && <ChooseHousingCompany modifyCompanies={modifyCompanies} updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 3 && <EnterTenderDetails updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 4 && <ChooseSupplier modifySuppliers={modifySuppliers} updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 5 && <EnterAssetDetails modifyAssets={modifyAssets} getTenderAssets={getTenderAssets} updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 6 && <AttachFile updateTenderData={updateTenderData} />}
      {steps[step-1]?.key === 7 && <ChooseClosingDate updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 8 && <AdditionalInfo updateTenderData={updateTenderData}/>}
      {steps[step-1]?.key === 9 && <Summary getDetails={getDetails} updateTenderData={updateTenderData}/>}
      {steps.length+1 === step && <ConfirmTender/>}
    </TenderContext.Provider>
  );

  if (loading) {
    content = (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
    {
      !confirmTenderPage &&
    
        <ul className="tenderHeader">
          {steps?.map((s, i) => (
            <li
              key={i}
              className={parseInt(lastStep)+1 >= i + 1 ? "active" : null}
              onClick={() => onNavClick(s.key)}
            >
              <FormattedMessage id={s.label} />
            </li>
          ))}
        </ul>
        }
        <div className="innerContainer">
        {
              (step != 10 && (tenderDetails?.title || tenderDetails?.organization_id)) && 
            <Row className="header_summary" align="top" justify="space-between">
            
                <Col>
                  {/* <Row>
                    <h4>
                      Title:
                    </h4>
                    </Row> */}
                    <Row>
                    <h4>
                  {tenderDetails?.title}
                  </h4>
                      </Row></Col>
              
             {
              (organizations && organizationData) && (
                <Col className="org_names">
                  <h4>
                    {organizationData?.organization_name}
                  </h4>

                    {
                      currentCompanyDetails.map(company => (
                        
                          <h4>
                          
                          {company.company_name}{company?.address && ', '+company.address}{company?.city_name && ', '+company.city_name} - {organizationData?.pincode}
                          </h4>
                        
                      ))
                    }
                    {/* {organizationData?.address && ', '+organizationData.address}
                    {organizationData?.city_name && ', '+organizationData.city_name}
                    {organizationData?.country_name && ', '+organizationData.country_name} - {organizationData?.pincode} */}
                    

                </Col>
              )
            }
            </Row>
}
          {content}
        </div>
        <Modal visible={showModificationModal} footer={null}>
        <div className="hedding">
          <h2><FormattedMessage id={'Reason_for_modifying'} /></h2>
        </div>

        <div className="d-flex f-col">
          <textarea className="comments" onChange={modificationReason}></textarea>

          <div className="btn" onClick={confirmModify}>
          <FormattedMessage id={'continue'} />
          </div>
        </div>
      </Modal>
      </>
  );
};

TenderCreationSteps.propTypes = {};

export default TenderCreationSteps;
