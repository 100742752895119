import React, { useEffect, useState } from "react";
import get from 'lodash/get';
import axios from "axios";
import { Button, Col, Input, Row, Spin, message } from "antd";
import { useHistory } from "react-router";

import "./TenderCard.less";
import { domainUrl } from '../../helper';
import { genHeaders } from "../Tender/Tender";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreTenders } from "../../actions/pre-tender";
import { getUser } from "../../actions/auth";
import { fetchSupplier } from '../../actions/suppliers';
import { fetchTenderByParams, deleteTender } from '../../actions/tender';
import { getBidForTender } from '../../actions/bids';
import { getUniqueValues } from '../../utils';
import { FormattedMessage,useIntl } from "react-intl";
import ConfirmationBox from '../ConfirmationBox';
import { SearchOutlined } from "@ant-design/icons";


const TOGGLE_LIMIT = 4;

const TenderCard = ({ status, titleSlot, children }) => {
  const dispatch = useDispatch();
  const [tenders, setTenders] = useState();
  const [loading, setLoading] = useState(false);
  const [toggleMore, setToggleMore] = useState(true);
  const role = sessionStorage.getItem('role_id')
  const [error, setError] = useState();
  const intl=useIntl()
  const [title] = useState(() => {
    if (status === "completed") return ["completed_tenders", "completed"];
    if (status === "published") return ["published_tenders", "published"];
    if (status === "draft") return ["draft_tenders", "draft"];
    if (status === "pre") return ["pre_tenders", null];
    if (status === "awarded") return ["awarded_tenders", null];
  });
  const history = useHistory();
  const stateUser = useSelector(state => get(state, `entities.users`));

  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteTenderId, setDeleteTender] = useState('');
  const [searchText, setSearchText] = useState('');

  const getTendersInfo = async () => {
    try {
      setLoading(true);
      setError(false);

      let queryParam;
      let searchParam = '';
      switch (status) {
        case "completed":
          queryParam = role === '2' ? `?is_completed=1&created_by=${sessionStorage.getItem('user_id')}&order_by=completion_date&sort_by=DESC` : "?is_completed=1&order_by=completion_date&sort_by=DESC";
          break;
        case "published":
          queryParam = "?is_published=1&is_awarded=0&order_by=updated_at&sort_by=DESC";
          break;
        case "draft":
          queryParam = `?is_published=0&created_by=${sessionStorage.getItem('user_id')}&order_by=updated_at&sort_by=DESC`;
          break;
        case "awarded":
          queryParam = "?is_published=1&is_awarded=1&&order_by=award_datetime&sort_by=DESC"; //&is_completed=0
          break;
        default:
          queryParam = null;
      }
      if(searchText){
        searchParam = `&title=${searchText}`
      }
      //remove axios
      let tenderInfo = await axios.get(
        `${domainUrl}/tender/v1/tender${queryParam}${searchParam}`,
        genHeaders()
      );

      tenderInfo = tenderInfo.data.data.result;
      tenderInfo = [...new Map(tenderInfo.map(item => [item['tender_id'], item])).values()];
      const uniqueCreatorIds = getUniqueValues('created_by', tenderInfo);
      await Promise.all(
        uniqueCreatorIds?.map(async id => {
          await dispatch(getUser(id));
        })
      )

      let suppliers = [];
      if (status === "awarded") {
        const uniqueSupplierIds = getUniqueValues('winner_id', tenderInfo);
        await Promise.all(
        uniqueSupplierIds?.map(async id => {
          await dispatch(fetchSupplier(id));
        })
      )
      }

      tenderInfo = tenderInfo?.map((tender, index) => {
        return {
          name: tender?.title,
          created_by: tender?.created_by,
          stat: title[1],
          id: tender?.tender_id,
          company_name: tender?.company_name,
          organization_id: tender?.organization_id,
          organization_name: tender?.organization_name,
          bids: tender?.bid_count,
          winner_id: tender?.winner_id,
          closing_date: tender?.closing_date,
          updated_at: tender?.updated_at,
          no_of_suppliers: tender?.no_of_suppliers,
          award_datetime: tender?.award_datetime,
          published_date: tender?.published_date,
          completion_date: tender?.completion_date
          
        };
      });

      setTenders(tenderInfo);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      setTenders(null);
    }
  };
  const getPreTenderInfo = async() => {
    setLoading(true);
    setError(false);
    let params = {limit: 100, order_by: 'updated_at', sort_by: 'DESC', is_submitted: 1, status: 'pending'};
    if(searchText){
      params.title = searchText
    }
    const response = await dispatch(fetchPreTenders(params));
    let preTendersPayload = response?.payload.entities?.preTender;    
    
    const uniqueCreatorIds = getUniqueValues('created_by', Object.values(preTendersPayload || {}));
      await Promise.all(
        uniqueCreatorIds?.map(async id => {
          await dispatch(getUser(id));
        })
    )

    let preTenders = response?.payload?.result?.map(id => {
     return {
        name: preTendersPayload[id]?.title,
        created_by: preTendersPayload[id]?.created_by,
        stat: 'new',
        supplier_id: preTendersPayload[id]?.created_by,
        id: id,
        company_name: preTendersPayload[id]?.company_name,
        submitted_date: preTendersPayload[id]?.submitted_date,
      }
    });
    setTenders(preTenders);
    setLoading(false);
  }
  useEffect(() => {
    if(status !== 'pre')
      getTendersInfo();
    else
      getPreTenderInfo();
  }, []);
  const handleSearch = () => {
    if(status !== 'pre')
      getTendersInfo();
    else
      getPreTenderInfo();
  }
  
  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteTender('');
  }

  const confirmDelete = async () => {
    setShowConfirmationBox(false);
    await dispatch(deleteTender(deleteTenderId));
    const queryParam = `?is_published=0&created_by=${sessionStorage.getItem('user_id')}&order_by=created_at&sort_by=DESC`;
    let tenderInfo = await axios.get(
      `${domainUrl}/tender/v1/tender${queryParam}`,
      genHeaders()
    );
    tenderInfo = tenderInfo.data.data.result;
    tenderInfo = tenderInfo?.map((tender, index) => {
      return {
        name: tender?.title,
        created_by: tender?.created_by,
        stat: title[1],
        id: tender?.tender_id,
        company_name: tender?.company_name,
        organization_name: tender?.organization_name,
        bids: tender?.bid_count,
        winner_id: tender?.winner_id,
        closing_date: tender?.closing_date,
        updated_at: tender?.updated_at,
        no_of_suppliers: tender?.no_of_suppliers,
        award_datetime: tender?.award_datetime,
        published_date: tender?.published_date,
      };
    });
    setTenders(tenderInfo);
  };

  const deleteDraftTender = (tender_id) => {
    setDeleteTender(tender_id); 
    setShowConfirmationBox(true);
  }

  const onTenderClick = (e, tender) => {
    if(e.target.nodeName.toLowerCase() !== 'div') {
      e.stopPropagation();
      return;
    }
    if (tender?.id) {
      if (status === "completed") {
        history.push(`/tender/awarded/${tender.id}`);
      }
      if (status === "published") {
        history.push(`/tender/detail/${tender.id}`);
      }
      if (status === "draft") {
        history.push(`/tender/${tender.id}`);
      }
      if (status === "awarded") {
        history.push(`/tender/awarded/${tender.id}`);
      }
      if (status === "pre") {
        history.push(`/pre-tender/${tender.id}/supplier/${tender.supplier_id}`);
      }
    }
  };

  let content;

  if (loading) {
    content = <Spin style={{ display: "flex", justifyContent: "center" }} />;
  } else if (error) {
    content = (
      <p style={{ width: "100%", textAlign: "center" }}>
        {/* Error while loading tenders */}
      </p>
    );
  } else {
    content = (
      <>
        <div className="cardcontainer">
          {/* <Row align="right" className="card-row" justify="space-between" gutter={[15, 15]}> */}
            {/* <Col md={12} lg={12} xl={12}> */}
            <div style={{display: 'flex',  width: "100%",alignItems:"center" , justifyContent: "end", marginBottom: "2%" }}>
              <Input
                placeholder={intl.formatMessage({ id:"search_tender_name"})}
                value={searchText}
                onChange={e => setSearchText(e.target.value ? e.target.value : '')}
                onPressEnter={() =>  handleSearch()}
                style={{ marginBottom: 0,display:"block", marginRight: "1%", width: "30%" }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, margin: 0 }}
              >
                <FormattedMessage id="search" />
              </Button>
            </div>
            {/* </Col> */}
          {/* </Row> */}
          <ul className="title">{titleSlot}</ul>
          {children &&
            tenders
              ?.filter(
                (_, i) => i < (toggleMore ? TOGGLE_LIMIT : tenders.length)
              )
              ?.map((tender, index) => (
                <div
                  key={index}
                  className="content"
                  style={{ cursor: tender?.id ? "pointer" : "auto" }}
                  onClick={(e) => onTenderClick(e, tender)}
                >
                  {children(tender, deleteDraftTender, index)}
                </div>
              ))}
        </div>
        {tenders?.length > TOGGLE_LIMIT && (
          <div className="viewAll" onClick={() => setToggleMore(!toggleMore)}>
            {toggleMore && <FormattedMessage id={'show_more'}/>}
            {!toggleMore && <FormattedMessage id={'show_less'}/>}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="__TenderCard__">
      <div className="card">
        <h2><FormattedMessage id={title[0]}/></h2>
        {content}
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={intl.formatMessage({id:'are_you_sure_you_want_to_delete_this_tender'}) }  confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </div>
  );
};

export default TenderCard;
