import React from "react";
import "./Setting.css";
import SettingNavigationLabel from "../Common/SettingNavigationLabel";
import { useIntl } from "react-intl";

const Setting = (props) => {
  const intl=useIntl()
  return (
    <ul className="tabsBlock">
      {/* <SettingNavigationLabel label={intl.formatMessage({ id:"sms_settings"})} url="sms-setting" active={props.page === "SMSSetting" ? true : false} /> */}
      {/* <SettingNavigationLabel label={intl.formatMessage({ id:"email_settings"})} url="email-setting" active={props.page === "EmailSetting" ? true : false} /> */}
      {/* <SettingNavigationLabel label={intl.formatMessage({ id:"payment_gateway"})} url="payment-gateway" active={props.page === "PaymentGateway" ? true : false} /> */}
      <SettingNavigationLabel label={intl.formatMessage({ id:"service_type"})} url="service-type" active={props.page === "ServiceType" ? true : false} />
      {/* <SettingNavigationLabel label={intl.formatMessage({ id:"consultancy_price"})} url="consultancy-prices" active={props.page === "ConsultancyPrices" ? true : false} /> */}
      <SettingNavigationLabel label={intl.formatMessage({ id:"company_type"})} url="company-type" active={props.page === "CompanyType" ? true : false} />
    </ul>
  );
};

export default Setting;
