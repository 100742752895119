import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import Header from "../../components/Header/Header";
import "../../components/AdminDashboard/Dashboard.css";
import MessagesCard from "../../components/MessagesCard";

const TenderMessages = (props) => {
console.log(props,"=====tender")
  return (
    <>
      <Header />
      <div className="container">
        <Row gutter={[20, 3]} style={{width: '100%'}} justify="center">
          <Col  md={24} xl={24} className="rightSidePanel">
            <MessagesCard tenderId={props?.match?.params?.tenderId}/>
          </Col>
        </Row>
      </div>
    </>
  );
};

TenderMessages.propTypes = {};

export default TenderMessages;
