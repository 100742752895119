import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
	handleResponse,
	handleError,
    handleQuery,
} from "../utils";
import { awsAPIUrl } from "../helper";

export const FETCH_MIR_REQUEST = "FETCH_MIR_REQUEST";
export const FETCH_MIR_SUCCESS = "FETCH_MIR_SUCCESS";
export const FETCH_MIR_FAILURE = "FETCH_MIR_FAILURE";

export const FETCH_IRS_REQUEST = "FETCH_IRS_REQUEST";
export const FETCH_IRS_SUCCESS = "FETCH_IRS_SUCCESS";
export const FETCH_IRS_FAILURE = "FETCH_IRS_FAILURE";

export const FETCH_IR_REQUEST = "FETCH_IR_REQUEST";
export const FETCH_IR_SUCCESS = "FETCH_IR_SUCCESS";
export const FETCH_IR_FAILURE = "FETCH_IR_FAILURE";

export const CREATE_IR_REQUEST = "CREATE_IR_REQUEST";
export const CREATE_IR_SUCCESS = "CREATE_IR_SUCCESS";
export const CREATE_IR_FAILURE = "CREATE_IR_FAILURE";

export const CREATE_IRL_REQUEST = "CREATE_IRL_REQUEST";
export const CREATE_IRL_SUCCESS = "CREATE_IRL_SUCCESS";
export const CREATE_IRL_FAILURE = "CREATE_IRL_FAILURE";

export const UPDATE_IR_REQUEST = "UPDATE_IR_REQUEST";
export const UPDATE_IR_SUCCESS = "UPDATE_IR_SUCCESS";
export const UPDATE_IR_FAILURE = "UPDATE_IR_FAILURE";

export const DELETE_IR_REQUEST = "DELETE_IR_REQUEST";
export const DELETE_IR_SUCCESS = "DELETE_IR_SUCCESS";
export const DELETE_IR_FAILURE = "DELETE_IR_FAILURE";

export const inspectionSchema = new schema.Entity("inspections", [], {
	idAttribute: "inspection_id",
});

export const masterInspectionSchema = new schema.Entity("masterinspections", [], {
	idAttribute: "inspection_category_id",
});

/**
 * Returns a redux api middleware action that makes a request for master of inspection reports.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchMasterInspectionData = (params = {}) =>
 createAction({
	 endpoint: `${awsAPIUrl}/Prod/v1/master/inspection?${handleQuery(params)}`,
	 method: "GET",
	 types: [
		 { type: FETCH_MIR_REQUEST },
		 {
			 type: FETCH_MIR_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse([masterInspectionSchema], action, state, res),
		 },
		 { type: FETCH_MIR_FAILURE, payload: handleError },
	 ],
 }); 

/**
 * Returns a redux api middleware action that makes a request for a collection of inspection reports.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchAllInspectionReports = (params = {}) =>
	createAction({
		endpoint: `${awsAPIUrl}/Prod/v1/inspection_report?${handleQuery(params)}`,
		method: "GET",
		types: [
			{ type: FETCH_IRS_REQUEST },
			{
				type: FETCH_IRS_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([inspectionSchema], action, state, res),
			},
			{ type: FETCH_IRS_FAILURE, payload: handleError },
		],
	}); 

/**
 * Returns a redux api middleware action that makes a request to get inspection report with id.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchInspectionReport = (inspection_id) =>
createAction({
	endpoint: `${awsAPIUrl}/Prod/v1/inspection/${inspection_id}`,
	method: "GET",
	types: [
		{ type: FETCH_IR_REQUEST },
		{
			type: FETCH_IR_SUCCESS,
			payload: (action, state, res) =>
				handleResponse(inspectionSchema, action, state, res),
		},
		{ type: FETCH_IR_FAILURE, payload: handleError },
	],
}); 

/**
 * Returns a redux api middleware action that makes a request to create of inspection reports.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createInspectionReports = (data, asset_id) =>
 createAction({
	 endpoint: `${awsAPIUrl}/Prod/v1/inspection/asset/${asset_id}`,
	 method: "POST",
	 body: JSON.stringify(data),
	 types: [
		 { type: CREATE_IR_REQUEST },
		 {
			 type: CREATE_IR_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse([inspectionSchema], action, state, res),
		 },
		 { type: CREATE_IR_FAILURE, payload: handleError },
	 ],
 }); 

/**
 * Returns a redux api middleware action that makes a request to create of inspection reports line items.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createInspectionReportsLine = (data, inspection_id) =>
 createAction({
	 endpoint: `${awsAPIUrl}/Prod/v2/inspection/${inspection_id}/lines`,
	 method: "POST",
	 body: JSON.stringify(data),
	 types: [
		 { type: CREATE_IRL_REQUEST },
		 {
			 type: CREATE_IRL_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse(inspectionSchema, action, state, res),
		 },
		 { type: CREATE_IRL_FAILURE, payload: handleError },
	 ],
 }); 

 export const fetchAllInspectionRemarks = (params = {}) =>
	createAction({
		endpoint: `${awsAPIUrl}/Prod/v2/inspection_remarks?${handleQuery(params)}`,
		method: "GET",
		types: [
			{ type: FETCH_IRS_REQUEST },
			{
				type: FETCH_IRS_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([inspectionSchema], action, state, res),
			},
			{ type: FETCH_IRS_FAILURE, payload: handleError },
		],
	}); 

/**
 * Returns a redux api middleware action that makes a request to update inspection reports.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const updateInspectionReports = (data, inspection_id) =>
 createAction({
	 endpoint: `${awsAPIUrl}/Prod/v1/inspection/${inspection_id}`,
	 method: "PATCH",
	 body: JSON.stringify(data),
	 types: [
		 { type: UPDATE_IR_REQUEST },
		 {
			 type: UPDATE_IR_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse([inspectionSchema], action, state, res),
		 },
		 { type: UPDATE_IR_FAILURE, payload: handleError },
	 ],
}); 

/**
 * Returns a redux api middleware action that makes a request to delete a inspection report.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteInspectionReports = (inspection_id) =>
 createAction({
	 endpoint: `${awsAPIUrl}/Prod/v1/inspection/${inspection_id}`,
	 method: "DELETE",
	 types: [
		 { type: DELETE_IR_REQUEST },
		 {
			 type: DELETE_IR_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse([inspectionSchema], action, state, res),
		 },
		 { type: DELETE_IR_FAILURE, payload: handleError },
	 ],
}); 